/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-05-24 10:28:22
 * @LastEditTime: 2023-07-10 10:06:45
 * @FilePath: \awx-ui\src\router\config.js
 */
import TabsView from '@/layouts/tabs/TabsView'
import BlankView from '@/layouts/BlankView'
import PageView from '@/layouts/PageView'


// 路由配置
const options = {
  routes: [{
    path: '/login',
    name: '登录页',
    component: () => import('@/pages/login')
  },
  {
    path: '/formEdit',
    name: '表单引擎',
    component: () => import('@/pages/formEditDemo')
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/pages/exception/403'),
  },
  {
    path: '/',
    name: '首页',
    component: () => import('@/layouts/tabs/TabsView'),
    redirect: '/login',
    children: []
    // children: [{
    //   path: 'dashboard',
    //   name: '仪表盘',
    //   meta: {
    //     notAlive: true,
    //     icon: 'menu-dashboard',
    //   },
    //   component: () => import('@/pages/overview/index'),
    //   children: [
    //   ]
    // },
    // {
    //   path: '/baidufanyi',
    //   name: '外部页面',
    //   meta: {
    //     externalUrl: 'https://juejin.cn/',
    //     notAlive: true,
    //     icon: 'menu-dashboard',
    //   },
    //   component: () => import('@/layouts/ThirdPartyPage/index'),
    //   children: [
    //   ]
    // },
    // {
    //   path: '/resource',
    //   name: '资源管理',
    //   meta: {
    //     icon: 'menu-host',
    //   },
    //   redirect: '/resource/host-manage',
    //   component:  () => import('@/layouts/PageView'),
    //   children: [
    //     {
    //       path: 'host-manage',
    //       name: '主机管理',
    //       meta: {
    //         notAlive: true,
    //         icon: 'home',
    //       },
    //       component: () => import('@/pages/newHostManage/index'),
    //     },
    //     {
    //       path: 'file-management',
    //       name: '文件管理',
    //       meta: {
    //         notAlive: true,
    //         icon: 'menu-file',
    //       },
    //       component: () => import('@/pages/fileManage'),
    //     }
    //   ]
    // },
    // {
    //   path: 'quick-execution',
    //   name: '快速执行',
    //   redirect: '/quick-execution/operation-console',
    //   meta: {
    //     icon: 'menu-quick',
    //   },
    //   component:  () => import('@/layouts/PageView'),
    //   children: [
    //     {
    //       path: 'operation-console',
    //       name: '操作台',
    //       meta: {
    //         notAlive: true,
    //         icon: 'commond-distribute',
    //       },
    //       component: () => import('@/pages/operationConsole/index.vue'),
    //     }
    //   ]
    // },
    // {
    //   path: 'task-manage',
    //   name: '任务管理',
    //   meta: {
    //     icon: 'calendar',
    //   },
    //   redirect: '/task-manage/pipeline',
    //   component:  () => import('@/layouts/PageView'),
    //   children: [
    //     {
    //       path: 'pipeline',
    //       name: '流水线',
    //       meta: {
    //         notAlive: true,
    //         icon: 'calendar',
    //       },
    //       component: () => import('@/pages/pipeline/index'),
    //     },
    //     {
    //     path: 'deployman',
    //     name: '任务中心',
    //     meta: {
    //       notAlive: true,
    //       icon: 'calendar',
    //     },
    //     component: () => import('@/pages/taskManage/index'),
    //   },
    //   {
    //     path: 'dispatch-manage',
    //     name: '调度中心',
    //     meta: {
    //       notAlive: true,
    //       icon: 'dispatch',
    //     },
    //     component: () => import('@/pages/dispatch/index'),
    //   }, 
    //   ]
    // },
    // {
    //   path: '/app-manage',
    //   name: '应用管理',
    //   meta: {
    //     notAlive: true,
    //     icon: 'menu-dashboard',
    //   },
    //   component: () => import('@/pages/appManage/index'),
    //   children: [

    //   ]
    // },
    // {
    //   path: 'template-manage',
    //   name: '模板管理',
    //   redirect: '/template-manage/template-shop',
    //   meta: {
    //     icon: 'menu-shop',
    //     notAlive: true,
    //   },
    //   component:  () => import('@/layouts/PageView'),
    //   children: [
    //     {
    //       path: 'template-step',
    //       name: '步骤管理',
    //       meta: {
    //         notAlive: true,
    //         icon: 'table-step',
    //       },
    //       component: () => import('@/pages/templateStep/index'),
    //     },
    //     {
    //       path: 'template-step-new',
    //       name: '新建步骤',
    //       meta: {
    //         invisible: true,
    //         notAlive: false,
    //         icon: 'table-step',
    //       },
    //       component: () => import('@/pages/templateStep/modifyStep'),
    //     },
    //     {
    //       path: 'template-step-edit',
    //       name: '编辑步骤',
    //       meta: {
    //         invisible: true,
    //         notAlive: false,
    //         icon: 'table-step',
    //       },
    //       component: () => import('@/pages/templateStep/modifyStep'),
    //     },
    //     {
    //       path: 'template-shop',
    //       name: '模板市场',
    //       meta: {
    //         notAlive: true,
    //         icon: 'table-market',
    //       },
    //       component: () => import('@/pages/templateShop/index'),
    //     },
    //     {
    //       path: 'my-template',
    //       name: '我的模板',
    //       meta: {
    //         notAlive: true,
    //         icon: 'table',
    //       },
    //       component: () => import('@/pages/templateShop/index'),
    //     }
    //   ]
    // },
    // {
    //   path: 'quota-manage',
    //   name: '配额管理',
    //   meta: {
    //     icon: 'menu-quota',
    //     notAlive: true,
    //   },
    //   redirect: '/quota-manage/programme',
    //   component:  () => import('@/layouts/PageView'),
    //   children: [{
    //     path: 'programme',
    //     name: '配额方案',
    //     meta: {
    //       notAlive: true,
    //       icon: 'audit',
    //     },
    //     component: () => import('@/pages/management/index'),
    //   },
    //   {
    //     path: 'my-quota',
    //     name: '我的配额',
    //     meta: {
    //       notAlive: true,
    //       icon: 'my-quota',
    //     },
    //     component: () => import('@/pages/management/myquota'),
    //   }]
    // },
    // {
    //   path: 'access-control',
    //   name: '访问控制',
    //   redirect: '/access-control/user',
    //   meta: {
    //     icon: 'menu-safety',
    //   },
    //   component:  () => import('@/layouts/PageView'),
    //   children: [{
    //     path: 'user',
    //     name: '用户管理',
    //     meta: {
    //       icon: 'user-management',
    //     },
    //     component: () => import('@/pages/securityCenter/user'),
    //   },
    //   {
    //     path: 'master',
    //     name: '节点管理',
    //     meta: {
    //       icon: 'safety-certificate',
    //     },
    //     component: () => import('@/pages/securityCenter/master'),
    //   }
    //   ]
    // },
    // ]
  },
  {
    path: '*',
    name: '404',
    redirect: '/login',
    component: () => import('@/pages/exception/404'),
  },
  ]
}

export default options
