<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-06-13 16:35:02
 * @LastEditTime: 2023-12-08 17:45:33
 * @FilePath: \awx-ui\src\components\createTask\components\chooesItem.vue
-->
<template>
  <div :class="['template-item', selectedTemplateId === templateItem.id ? 'template-item-selected' : '']" @click="selectedTemplate">
    <span :class="{'template-logo': true, 'template-blank': templateItem.id === '_blank'}">
      <img v-if="templateItem.id !== '_blank'" :src="templateItem.icon" alt="" />
      <!-- <svg-icon v-else icon-class="task-blank" style="font-size: 48px"></svg-icon> -->
      <a-icon v-else type="plus" style="font-size: 38px;margin: auto;display: inline-block;color: #3974F4" />
    </span>
    <div class="template-text-info">
      <div class="template-title over-flow-ellipsis" :style="{'max-width': templateType === 'app' ? '116px': '125px', 'font-size': templateType === 'app' ? '14px': '16px'}" :title="templateItem.name">{{templateItem.name}}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    templateItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectedTemplateId: String,
    templateType: {
      type: String,
      default: 'template'
    }
  },
  data() {
    return {};
  },
  methods: {
    selectedTemplate() {
      this.$emit("selectedTemplate", this.templateItem);
    },
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="less" scoped>
.template-item {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  cursor: pointer;
  padding: 24px 20px;
  color: #adb0b8;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #d5daf0;
  box-shadow: 0px 2px 8px 0px rgba(188, 194, 206, .3);
  &:hover {
    background: #F4FAFF;
    border: 1px solid rgba(58,116,244,0.8);
    box-shadow: 0px 2px 8px 0px rgba(59, 108, 214, .4);
  }
  .template-logo {
    width: 54px;
    height: 54px;
    min-height: 54px;
    margin-bottom: 6px;
    background: #FFF; // 这个是图标的颜色定义
    align-items: center;
    justify-content: center;
    // border: 1px solid rgba(207,213,222,1);
    box-shadow: 0px 3px 10px 0px rgba(66, 82, 108, 0.2);
    border-radius: 8px;
    display: flex;
    img {
      width: 54px;
      border-radius: 8px;
    }
  }
  .template-blank {
    border: none;
  }
  .template-title {
    line-height: 24px;
    font-weight: 600;
    font-size: 15px;
    color: #1d202d;
    max-width: 125px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .template-description {
    font-size: 14px;
    color: #6f737d;
    letter-spacing: 0;
    font-weight: 400;
    margin-top: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 240px;
  }
  .circle-border {
    top: 10px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid var(--devui-line, #adb0b8);
    position: absolute;
    right: 10px;
    display: none;
  }
  &:hover {
    background: #fff;
    box-shadow: 0px 1px 5px 0px rgba(229, 233, 238, 1);
    // border-left-color: #adb0b8;
    // border-left: 1px solid var(--devui-line, #adb0b8);
    // border-right-color: #adb0b8;
    // border-right: 1px solid var(--devui-line, #adb0b8);
    .circle-border {
      // display: block;
    }
  }
  // 选中的这个模板
  &-selected {
    background: #F4FAFF;
    border: 1px solid rgba(58,116,244,0.8);
    box-shadow: 0px 2px 8px 0px rgba(59, 108, 214, .4);
    // border: 1px solid var(--devui-brand, #5e7ce0);
    // box-shadow: 0 0 7px 0 var(--devui-shadow, rgba(37, 43, 58, 0.2));
    .circle-border {
      // display: block;
      // border: 1px solid var(--devui-brand, #5e7ce0);
      .circle-select {
        width: 10px;
        height: 10px;
        margin: 2px auto;
        border-radius: 50%;
        background: var(--devui-brand, #5e7ce0);
      }
    }
  }
}
</style>