/**
 * 操作台
 */
let timer = null

const state = {
  runHistoryList: [],
  runHistoryTotal: 0,
  showHistory: false,
  runtab: 'file',
  runningFlag: false,
  taskLoading: false,
  fileCurrentPage: 1,
  fileIsEmpty: false,
  cmdCurrentPage: 1,
  cmdIsEmpty: false,
  taskCurrentPage: 1,
  taskIsEmpty: false,
  collapseKey: 'open' // 折叠面板是否打开
}
const getters = {}
const mutations = {
  PUSH_CANCEL(state, payload) {
    state.cancelTokenArr.push(payload.cancelToken);
  },

  CLAER_CANCEL({
    cancelTokenArr
  }) {
    cancelTokenArr.forEach(item => {
      item('路由跳转取消请求');
    });
    cancelTokenArr = [];
  }
}

Object.keys(state).forEach((item) => {
  mutations[item] = (state, value) => {
    state[item] = value
  }
})

const actions = {
  changeCollapseKey({
    commit
  }, obj) {
    commit('collapseKey', obj)
  },
  changeShowHistory({
    commit
  }, obj) {
    commit('showHistory', obj)
    commit('collapseKey', 'open')
  },
  setNumAdd({
    commit,
    state,
    dispatch
  }, init) {
    let currentPage = state.runtab === 'file' ? state.fileCurrentPage : state.runtab === 'task' ? state.taskCurrentPage : state.cmdCurrentPage
    if (init) {
      currentPage = 1
    } else {
      currentPage++
    }
    if (state.runtab === 'file') commit('fileCurrentPage', currentPage)
    if (state.runtab === 'cmd') {
      commit('cmdCurrentPage', currentPage)
    }
    if (state.runtab === 'task') commit('taskCurrentPage', currentPage)
    commit('collapseKey', 'open')
    dispatch('getHistoryList', {
      type: state.runtab,
      init: false
    })
  },
  setRunHistoryTotal({
    commit
  }, obj) {
    commit('runHistoryTotal', obj)
  },
  setRunTab({
    commit
  }, obj) {
    commit('runtab', obj)
  },
  setrunHistoryList({
    commit
  }, obj) {
    commit('runHistoryList', obj)
  },
  setRunningFlag({
    commit
  }, obj) {
    commit('runningFlag', obj)
  },
  _axios({ // 接口请求示例
    commit
  }, form) {
    // this.$http
    // return request(api.areaFocusList, form).then(response => {
    //   if (response.code === 0) {
    //     commit('focusNum', response.content.peopleCount)
    //   }
    // })
  },
  getHistoryList({
    dispatch,
    commit,
    state
  }, {
    type,
    init,
    notLoading
  }) {
    // todo：现在点击的时候都是用于刷新到第一页去追加，后面如果需要记录每个列表需要改造方法，做三个列表字段去记录
    const page = type === 'file' ? state.fileCurrentPage : type === 'task' ? state.taskCurrentPage : state.cmdCurrentPage
    let data = {
      page: page,
      page_size: 22,
      excludes: 'operations,detail,configs,notify',
      type: type === 'file' ? 2 : type === 'task' ? '' : 1,
    };
    if (!notLoading) commit('taskLoading', true)
    return this._vm.$axiosGet(global.API.quickTasks, data).then((res) => {
      commit('taskLoading', false)
      if ([200, 201, 204].includes(res.status)) {
        const isLoad = page !== 1
        let historyList = [...state.runHistoryList]
        // 这个地方是用于
        if (isLoad) {
          historyList = [...historyList, ...res.data.results]
        } else {
          historyList = res.data.results
        }
        if (type === 'file') {
          commit('fileIsEmpty', res.data.total === historyList.length)
        }
        if (type === 'task') {
          commit('taskIsEmpty', res.data.total === historyList.length)
        }
        if (type === 'cmd') {
          commit('cmdIsEmpty', res.data.total === historyList.length)
        }
        commit('runHistoryList', historyList)
        commit('runHistoryTotal', res.data.total)
        commit('showHistory', !init)
        let arr = res.data.results.filter(
          (item) => item.job_status === "running"
        );
        if (arr.length > 0) {
          timer = setTimeout(() => {
            if (state.runtab === 'file') commit('fileCurrentPage', 1)
            if (state.runtab === 'cmd') commit('cmdCurrentPage', 1)
            if (state.runtab === 'task') commit('taskCurrentPage', 1)
            dispatch('getHistoryList', {
              type,
              init,
              notLoading: true
            })
          }, 2000);
        } else {
          commit('runningFlag', false)
          // 如果运行结束  这时候如果选中的这一条 那么就弹窗里面的内容就是刷新的出来的数据
          clearTimeout(timer);
        }
      } else {
        commit('runHistoryList', [])
        commit('runHistoryTotal', 0)
      }
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}