/*
 * @Description: 
 * @Date: 2024-05-30 09:37:54
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-06-07 09:58:51
 */
import axios from 'axios';
import { guid } from './axios-peer'
import store from '@/store'
import { message } from 'ant-design-vue';
// import { Sema } from 'async-sema';
class Semaphore {
  constructor(max) {
    this.max = max;
    this.active = 0;
  }

  async acquire() {
    while (this.active >= this.max) {
      await new Promise(resolve => setTimeout(resolve, 100)); // 微秒级等待，防止忙等待
    }
    this.active++;
  }

  release() {
    this.active--;
  }
}
// 定义最大并发数
const MAX_CONCURRENT_UPLOADS = 3;
const semaphore = new Semaphore(MAX_CONCURRENT_UPLOADS);
let uploadQueue = [];
let uploadPromises = [];

const controllerObj = {}
let isUpload = true
function cancelUpload () {
  isUpload = false
  for (var key in controllerObj) {
    controllerObj[key].abort()
    delete controllerObj[key]
  }
}
async function uploadPeerFile(url, file, callback) {
  console.warn(`Starting upload for: ${file.name}`);
  const formData = new FormData();
  formData.append('file', file);
  // 为每个文件创建一个新的 AbortController 实例
  const controller = new AbortController();
  controllerObj[file.lastModified] = controller; // 将控制器实例存储起来
  // _group_${currentScopeGroup}_${fileId}
  // 动态设置rtcscope，确保每个文件有唯一的标识
  const rtcscope = `file`;
  try {
    const response = await axios.put(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        token: localStorage.getItem('token')
      },
      rtcscope,
      signal: controller.signal, // 将信号传递给请求
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.log(`文件 ${file.name} 上传进度: ${percentCompleted}%`);
        const uploadist = store.state.fileManage.uploadFileList
        console.log(file, '当前上传的文件')
        const index = uploadist.findIndex(item => item.fileId === file.fileId)
        if (index === -1) {
          uploadist.unshift({
            name: file.name,
            size: file.size,
            type: file.type,
            progress: percentCompleted,
            lastModified: file.lastModified,
            fileId: file.fileId
          })
        } else {
          uploadist.splice(index, 1, {
            ...uploadist[index],
            progress: percentCompleted,
          })
        }
        store.dispatch('fileManage/changeUploadFileList', uploadist)
      },
    });
    delete controllerObj[file.lastModified]; // 成功后删除控制器实例
    if (response.status != 200 && response.status != 201 && response.status != 204 && response.status != 202) {
      const uploadist = store.state.fileManage.uploadFileList
      const index = uploadist.findIndex(item => item.fileId === file.fileId)
      if (index === -1) {
        uploadist.unshift({
          name: file.name,
          size: file.size,
          type: file.type,
          progress: 100,
          lastModified: file.lastModified,
          status: 'exception',
          fileId: file.fileId
        })
      } else {
        uploadist.splice(index, 1, {
          ...uploadist[index],
          status: 'exception',
          progress: 100,
        })
      }
      store.dispatch('fileManage/changeUploadFileList', uploadist)
      return message.error(response?.data?.msg || '上传失败')
    }
    if (callback && isUpload) callback()
  } catch (error) {
    console.error(`文件 ${file.name} 上传失败`, error);
  } finally {
    delete controllerObj[file.lastModified];
  }
}
async function uploadFilesConcurrently(files, url, callback) {
  const uploadQueue = [...files];
  isUpload = true
  while (uploadQueue.length > 0 && isUpload) {
    const batch = uploadQueue.splice(0, Math.min(uploadQueue.length, MAX_CONCURRENT_UPLOADS));
    await Promise.race(batch.map(async file => {
      await semaphore.acquire(); // 等待信号量可用
      try {
        await uploadPeerFile(url, file, callback);
      } finally {
        semaphore.release(); // 无论成功或失败，释放信号量
      }
    }));
  }

  // 清理控制器实例
  for (var key in controllerObj) {
    controllerObj[key].abort();
    delete controllerObj[key];
  }
}
export {
  uploadFilesConcurrently,
  uploadPeerFile,
  cancelUpload
}