/*
 * @Description: 
 * @Date: 2022-12-14 16:13:57
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-01-19 17:41:25
 */
/**
 * 操作台
 */
const state = {
  sysType:'group',
  realEditList:[],
  showItem:{
    index:0,
  },
  targetItem:'',
  spinning:true,
  dbList:[],
  tableList:[],
  dataSourceList:[],
  refTree:{},
  editorHint:[],
  clusterId: ''
}
const getters = {}
const mutations = {
  PUSH_CANCEL(state, payload){
    state.cancelTokenArr.push(payload.cancelToken);
  },

  CLAER_CANCEL({cancelTokenArr}){
    cancelTokenArr.forEach(item => {
      item('路由跳转取消请求');
    });
    cancelTokenArr = [];
  }
}

Object.keys(state).forEach((item) => {
  mutations[item] = (state, value) => {
    state[item] = value
  }
})

const actions = {
  changeRefTree({ commit }, obj) {
    commit('refTree', obj)
  },
  setClusterId({ commit }, obj) {
    commit('clusterId', obj)
  },
  setSysType({ commit }, obj) {
    commit('sysType', obj)
  },
  changeRealEditList({ commit }, obj) {
    commit('realEditList', obj)
  },
  changeShowItem({ commit }, obj) {
    commit('showItem', obj)
  },
  changeTargetItem({ commit }, obj) {
    commit('targetItem', obj)
  },
  changeSpinning({ commit }, obj) {
    commit('spinning', obj)
  },
  changeDbList({ commit }, obj) {
    commit('dbList', obj)
  },
  changeTableList({ commit }, obj) {
    commit('tableList', obj)
  },
  _axios({// 接口请求示例
    commit
  }, form) {
    // this.$http
    // return request(api.areaFocusList, form).then(response => {
    //   if (response.code === 0) {
    //     commit('focusNum', response.content.peopleCount)
    //   }
    // })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
