/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-09-28 10:08:43
 * @LastEditTime: 2024-06-06 15:03:48
 * @FilePath: \awx-ui\src\store\modules\masterMd.js
 */

const state = {
  fileRequestLoading: false,
  sysType:'group',
  realEditList:[],
  showItem:{
    index:0,
  },
  clusterId: '',
  selectTreeFlag: false,
  searchValue: '',
  drawerVisibleList: [],
  logDrawerVisibleList: [],
  showFileProgress: false,
  uploadFileList: [],
  downloadFileList: [],
  uploadTab: 'upload'
}

const getters = {
}

const mutations = {}

Object.keys(state).forEach((item) => {
  mutations[item] = (state, value) => {
    state[item] = value
  }
})

const actions = {
  setClusterId({ commit }, obj) {
    localStorage.setItem('CLUSTER_ID', obj)
    commit('clusterId', obj)
  },
  setSelectTreeFlag({ commit }, obj) {
    commit('selectTreeFlag', obj)
  },
  setSearchValue({ commit }, obj) {
    commit('searchValue', obj)
  },
  setFileRequestLoading({
    commit
  }, obj) {
    commit('fileRequestLoading', obj)
  },
  setSysType({ commit }, obj) {
    commit('sysType', obj)
  },
  changeRealEditList({ commit }, obj) {
    commit('realEditList', obj)
  },
  changeShowItem({ commit }, obj) {
    commit('showItem', obj)
  },
  changeShowFileProgress({ commit }, obj) {
    commit('showFileProgress', obj)
  },
  changeUploadFileList({ commit }, obj) {
    commit('uploadFileList', obj)
  },
  changeUpload({ commit }, obj) {
    commit('uploadTab', obj)
  },
  changeDownloadFileList({ commit }, obj) {
    commit('downloadFileList', obj)
  },
  setDrawerVisibleList ({ commit }, obj) {
    commit('drawerVisibleList', obj)
  },
  setLogDrawerVisibleList ({ commit }, obj) {
    commit('logDrawerVisibleList', obj)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}