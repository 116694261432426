import { render, staticRenderFns } from "./commonForm.vue?vue&type=template&id=b6dc359e&scoped=true"
import script from "./commonForm.vue?vue&type=script&lang=js"
export * from "./commonForm.vue?vue&type=script&lang=js"
import style0 from "./commonForm.vue?vue&type=style&index=0&id=b6dc359e&prod&lang=less&scoped=true"
import style1 from "./commonForm.vue?vue&type=style&index=1&id=b6dc359e&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6dc359e",
  null
  
)

export default component.exports