/*
 * @Author: zhang·xiao
 * @Date: 2022-12-10 15:20:40
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2022-12-10 15:42:19
 * @Description: 描述文件功能
 */
export function isObject(obj) {
  return (Object.prototype.toString.call(obj) === '[object Object]');
}

export function isEmptyObject(obj) {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false;
    }
  }
  return true;
}

export function deepFreeze(obj) {
  // 取回定义在obj上的属性名
  const propNames = Object.getOwnPropertyNames(obj);

  // 在冻结自身之前冻结属性
  propNames.forEach((name) => {
    const prop = obj[name];

    // 如果prop是个对象，冻结它
    if (typeof prop === 'object' && prop !== null) deepFreeze(prop);
  });

  // 冻结自身(no-op if already frozen)
  return Object.freeze(obj);
}
