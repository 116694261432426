<template>
  <div class="master-dialog">
    <div v-if="step == 0">
      <a-result status="500" title="未检测到Master,无法将主机接入门户">
        <template #extra>
          <a-button @click="setMaster()" type="primary"
            >立即部署Master</a-button
          >
          <span @click="refreshList" style="cursor: pointer"
            >已经安装，立即刷新</span
          >
        </template>
      </a-result>
    </div>
    <div class="master-config" v-else>
      <div class="master-config-left">
        <div>安装Master</div>
        <div>在局域网中安装或部署多个Master</div>
        <div
          style="margin: 12px 0"
          v-for="(item, index) in stepList"
          :key="index"
        >
          {{ index + 1 }}.{{ item }}
          <a-icon type="check" v-if="index < step - 1" />
        </div>
      </div>
      <div class="master-config-right">
        <div class="title">{{ stepList[step - 1] }}</div>
        <!-- 标题 -->
        <div v-show="step == 1">
          <div>
            需要在当前网络内选择一台主机作为宿主机来安装Master,无需在每台主机上安装，宿主机的选择请遵循一下原则：
          </div>
          <div style="display: flex; margin-top: 24px">
            <div style="margin-right: 12px">
              <div style="margin-bottom: 6px">
                <a-icon
                  style="font-size: 37px"
                  type="pie-chart"
                  theme="twoTone"
                />
              </div>
              <p>Master</p>
            </div>
            <div>
              <div v-for="(item, index) in configList" :key="index">
                {{ item }}
              </div>
            </div>
          </div>
        </div>
        <!-- 第一步 -->
        <div v-show="step == 2">
          <span>主机类型</span> ：
          <a-radio-group
            @change="geMasterShell"
            :options="plainOptions"
            v-model="value1"
          />
          <br />
          <br />
          <span>安装方式</span> ：
          <a-radio-group :options="installType" v-model="value2" />
          <div v-if="value2 == '手动'">
            <div style="margin-top: 24px">
              <div>请在您的主机中以root身份执行以下脚本</div>
              <a-textarea v-model="shellDes" placeholder="执行脚本" :rows="4" />
            </div>
            <div
              style="display: flex; justify-content: flex-end; margin-top: 20px"
            >
              <a-button @click="copyShell" type="primary">复制</a-button>
            </div>
          </div>
          <div v-if="value2 == '自动'">
            <a-form :form="form">
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                label="主机"
                :rows="4"
              >
                <a-input
                  v-decorator="[
                    'host',
                    {
                      rules: [{ required: true, message: '请输入主机名' }],
                    },
                  ]"
                  placeholder="请输入主机ip"
                />
              </a-form-item>
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="{ span: 6 }"
                label="端口"
              >
                <a-input
                  v-decorator="[
                    'port',
                    {
                      rules: [{ required: true, message: '请输入端口' }],
                    },
                  ]"
                  placeholder="端口"
                />
              </a-form-item>
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                label="用户名"
              >
                <a-input
                  v-decorator="[
                    'username',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请输入用户名',
                        },
                      ],
                    },
                  ]"
                  placeholder="请输入用户名"
                />
              </a-form-item>
              <a-form-item
                label="认证方式"
                has-feedback
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
              >
                <a-select
                  v-decorator="[
                    'auth_type',
                    {
                      rules: [
                        {
                          required: true,
                          message: '选择认证方式',
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option value="password"> 密码 </a-select-option>
                  <a-select-option value="private_key"> 密钥 </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                label="凭证"
              >
                <a-textarea
                  v-decorator="[
                    'password',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请输入凭证',
                        },
                      ],
                    },
                  ]"
                  placeholder="请输入凭证"
                />
              </a-form-item>
            </a-form>
          </div>
        </div>
        <!-- 第二步-->
        <div v-show="step == 3">
          <div style="margin-top: 20%">
            <a-empty
              image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
              :image-style="{
                height: '60px',
              }"
            >
              <span slot="description">
                <div>正在等待Master的安装</div>
                <div>
                  Master尚未安装完成，您可以稍后继续通过脚本完成安装部署
                </div>
              </span>
            </a-empty>
          </div>
        </div>
        <!-- 第三步 -->
        <div class="master-right-bottom">
          <a-button
            style="margin-right: 6px"
            @click="upStep()"
            type="primary"
            :disabled="step === 1"
            >上一步</a-button
          >
          <a-button @click="nexStep()" type="primary">{{
            step == "3" ? "关闭" : "下一步"
          }}</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};
export default {
  name: "configMaster",
  data() {
    return {
      checkNick: false,
      formItemLayout,
      formTailLayout,
      form: this.$form.createForm(this, { name: "dynamic_rule" }),
      step: 0,
      stepList: ["了解安装要求", "设置安装信息", "安装Master"],
      configList: [
        "宿主机和其他主机处于同一个局域网内，且能够和其他主机沟通局域网互通",
        "宿主机无需具备公网IP，但必须要能够访问管理服务",
        "宿主机拥有大于100M的可用磁盘空间,以及64M以上的可用内存",
      ],
      plainOptions: ["linux", "windows"],
      value1: "linux",
      value2: "手动",
      installType: ["手动", "自动"],
      shellDes: "",
    };
  },
  mounted() {
    this.geMasterShell();
  },
  methods: {
    geMasterShell() {
      let obj = {
        type: this.value1,
      };
      this.$axiosGet(`${global.API.geMasterShell}`, obj).then((res) => {
        this.shellDes = res.data;
      });
    },
    check() {
      this.form.validateFields((err) => {
        if (!err) {
          console.info("success");
        }
      });
    },
    handleChange(e) {
      this.checkNick = e.target.checked;
      this.$nextTick(() => {
        this.form.validateFields(["nickname"], { force: true });
      });
    },
    copyShell() {
      var oInput = document.createElement("input"); //创建一个input标签
      oInput.value = this.shellDes;
      document.body.appendChild(oInput); //
      oInput.select(); // 模拟鼠标选中
      document.execCommand("Copy"); // 执行浏览器复制命令（相当于ctrl+c）
      oInput.style.display = "none"; //
      this.$message.success(`复制成功`);
    },
    refreshList() {
      this.$emit("refreshList", {});
    },
    setMaster() {
      this.step++;
    },
    upStep() {
      this.step--;
    },
    nexStep() {
      if (this.step == 3) {
        this.$emit("creatMaster", {
          type: "close",
        });
        return;
      }
      if (this.step == 2 && this.value2 == "自动") {
        this.form.validateFields((err, value) => {
          if (!err) {
            let obj = {
              host: value.host,
              port: value.port,
              username: value.username,
              auth_type: value.auth_type,
            };
            let key = obj.auth_type;
            obj[key] = value["password"];
            this.$emit("creatMaster", {
              type: "creat",
              params: obj,
            });
            this.step++;
          } else {
            console.log(err, "走了没");
            return false;
          }
        });
      } else {
        this.step++;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.master-dialog {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .master-config {
    display: flex;
    height: 539px;
    .master-config-left {
      &:first-child {
        background: #3974F4;
        padding: 12px;
        color: white;
        font-size: 14px;
        div {
          &:first-child {
            font-size: 24px;
          }
          &:nth-child(2) {
            font-size: 12px;
          }
        }
      }
    }
    .master-config-right {
      background: white;
      padding: 12px;
      color: black;
      font-size: 14px;
      position: relative;
      width: 500px;
      .master-right-bottom {
        position: absolute;
        bottom: 12px;
        right: 12px;
      }
      .title {
        color: #3974F4;
        font-size: 24px;
        border-bottom: 1px solid #3974F4;
        padding-bottom: 12px;
        margin-bottom: 12px;
      }
      div {
        &:nth-child(2) {
          font-size: 12px;
        }
      }
    }
  }
}
/deep/.ant-form {
  margin-top: 16px;
}
/deep/.ant-form-item {
  margin-bottom: 0;
}
/deep/.ant-form-item-label {
  margin-right: 8px;
}
</style>