

import genSchema from '../genSchema.js';

const viewSchema = {
  title: '单选类型',
  type: 'string'
};

const selectOptionsSchema = {
  enum: {
    title: '配置选项值',
    type: 'array',
    minItems: 1,
    'ui:showIndexNumber': true,
    default: ['1'],
    items: {
      title: '选项值',
      type: 'string'
    }
  },
  enumNames: {
    title: '配置选项名称',
    type: 'array',
    minItems: 1,
    'ui:showIndexNumber': true,
    default: ['一'],
    items: {
      title: '选项名称',
      type: 'string'
    }
  }
};
const fetchOptionsSchema = {
  title: 'fetch配置',
  type: 'object',
  properties: {
    schemaOptions: {
      type: 'object',
      properties: {
        api: {
          title: '接口地址',
          type: 'string',
          'ui:options': {
            placeholder: '请输入API地址，支持函数return 接口地址',
            type: 'textarea',
            rows: 3,
          }
        },
        type: {
          title: '请求类型',
          type: 'string',
          'ui:placeholder': 'get、post、put、delete、patch等类型'
        },
        needInit: {
          title: '即刻发送请求',
          type: 'boolean',
          "ui:options": {
            labelWidth: "150px"
          },
          default: false
        },
        needEmptyValue: {
          title: '自动清除无效值',
          type: 'boolean',
          "ui:options": {
            labelWidth: "150px"
          },
          default: false
        },
        params: {
          title: "接口联动参数",
          type: "array",
          items: {
            type: "object",
            required: [],
            properties: {
              paramKey: {
                title: "参数健",
                type: "string",
                "ui:placeholder": '接口入参的健值'
              },
              paramValue: {
                title: "参数值",
                type: "string",
                "ui:placeholder": '对应表单属性字段'
              }
            },
            "ui:order": [
              "paramKey",
              "paramValue"
            ]
          },
          uniqueItems: false
        },
        transfromParams: {
          title: '入参转换函数',
          type: 'string',
          'ui:options': {
            labelWidth: "136px",
            placeholder: '请输入API请求参数转换函数，请根据说明使用，有疑问可咨询管理员',
            type: 'textarea',
            rows: 3,
          }
        },
        transfromData: {
          title: '结果转换函数',
          type: 'string',
          'ui:options': {
            labelWidth: "136px",
            placeholder: '请输入API结果转换函数，请根据说明使用，有疑问可咨询管理员',
            type: 'textarea',
            rows: 3,
          }
        },
      }
    },
  }
};

export { selectOptionsSchema, fetchOptionsSchema };

export default {
  viewSchema,
  propsSchema: genSchema({
    fetch: fetchOptionsSchema,
    options: {
      title: '选项',
      type: 'object',
      required: [],
      properties: {
        schemaOptions: {
          type: 'object',
          properties: selectOptionsSchema
        }
      },
    },
  })
};
