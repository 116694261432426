<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-08-03 17:14:47
 * @LastEditTime: 2023-09-28 11:27:11
 * @FilePath: \awx-ui\src\components\menu\appDrawer\appComponents.vue
-->
<template>
  <div class="app-list">
    <div class="system-app">
      <div v-if="appList.length >0" class="flex-warp">
        <div v-for="(item, index) in appList" :key="index" class="app-container">
          <AppItem :app="item" :selectAppId="selectAppId" @selectApp="selectApp" />
        </div>
      </div>
      <div v-else>
        <NoData :showImg="false" />
      </div>
    </div>
  </div>
</template>
<script>
import NoData from "@/components/noData";
import AppItem from "./appItem.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: { NoData, AppItem },
  props: {
    appList: Array,
  },
  data() {
    return {
      selectAppId: "",
    };
  },
  computed: {
  },
  methods: {
    selectApp (app) {
      // this.selectAppId = app.id;
    },
  },
};
</script>
<style lang="less" scoped>
.app-list {
  height: calc(100vh - 180px);
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  .system-app {
    &-title {
      padding-bottom: 16px;
      font-size: 18px;
      color: #1d202d;
      letter-spacing: 0;
      font-weight: 600;
    }
  }
  .flex-warp {
    display: flex;
    flex-wrap: wrap;
  }
  .app-container {
    margin: 0 14px 14px 0;
    width: calc(33.3% - 12px);
    height: 120px;
    &:nth-child(3n) {
      margin: 0 0 14px 0;
    }
  }
}
/deep/ .siteScroll::-webkit-scrollbar-track,
.siteHeader_currencyList::-webkit-scrollbar-track,
*::-webkit-scrollbar-track {
  background: rgb(41, 55, 84);
  background-color: rgb(41, 55, 84);
}
::-webkit-scrollbar {
  background-color: rgb(41, 55, 84);
}
</style>