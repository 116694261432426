/*
 * @Author: zhang·xiao
 * @Date: 2022-12-10 16:43:17
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-01-04 14:00:11
 * @Description: 描述文件功能
 */

import {
  Icon,
  Button,
  ButtonGroup,
  Tabs,
  TabPane,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Select,
  Option,
  OptionGroup,
  Input,
  InputNumber,
  Cascader,
  Switch,
  Slider,
  TimePicker,
  DatePicker,
  Dialog,
  TimeSelect,
  FormItem,
  Upload,
  Rate,
  ColorPicker,
  Transfer,
  Form,
  Popover,
  Loading,
  InfiniteScroll,
  MessageBox,
  Message,
  Image,
  Carousel
} from 'element-ui';
const components = [
  Image,
  Carousel,
  Icon,
  Button,
  ButtonGroup,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Select,
  Option,
  OptionGroup,
  Input,
  InputNumber,
  Cascader,
  Switch,
  Slider,
  TimePicker,
  DatePicker,
  Dialog,
  TimeSelect,
  Form,
  FormItem,
  Upload,
  Tabs,
  TabPane,
  Rate,
  ColorPicker,
  Transfer,
  InfiniteScroll,
  Popover
]
function install(Vue, opts = {}) {
  components.forEach(component => {
    Vue.component(component.name, component);
  });

  Vue.use(InfiniteScroll);
  Vue.use(Loading.directive);

  Vue.prototype.$ELEMENT = {
    size: opts.size || '',
    zIndex: opts.zIndex || 2000
  };

  // 原型方法
  // Vue.prototype.$loading = Loading.service;
  // Vue.prototype.$alert = MessageBox.alert;
  // Vue.prototype.$confirm = MessageBox.confirm;
  // Vue.prototype.$prompt = MessageBox.prompt;
  // Vue.prototype.$msgbox = MessageBox;
  // Vue.prototype.$message = Message;
}
export default {
  install
};
