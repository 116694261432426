<!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2023-02-10 16:22:44
 * @FilePath: \awx-ui\src\components\runTaskHistory\result.vue
-->
<template>
    <div class="run-task-history-page" style="position: relative;">
      <div class="file-center-footer">
        <div class="center-footer-options">
          <span style="margin-right: 24px;font-size: 18px;color: #1D202D;font-weight: 600;">{{ currentHistory.name }} / 执行结果</span>
          <div class="center-footer-option full-screen-option">
            <div class="file-full" @click="onClose" style="margin-right: 0px">
              <svg-icon class="file-full-icon" icon-class="file-close" style="font-size: 24px"></svg-icon>
            </div>
          </div>
        </div>
        <div class="center-footer-options mgb0 bd-none">
          <div class="center-footer-option">
            <svg-icon icon-class="file-result"></svg-icon>
            <span style="margin: 0 8px;">总数</span>
            <span v-if="currentHistory.stat">{{currentHistory.stat.total_count}}</span>
          </div>
          <div class="center-footer-option">
            <svg-icon icon-class="file-success"></svg-icon>
            <span style="margin: 0 8px;">成功</span>
            <span v-if="currentHistory.stat">{{currentHistory.stat.success_count}}</span>
          </div>
          <div class="center-footer-option">
            <svg-icon icon-class="file-error"></svg-icon>
            <span style="margin: 0 8px;">失败</span>
            <span v-if="currentHistory.stat">{{currentHistory.stat.fail_count}}</span>
          </div>
        </div>
        <div class="center-footer-log">
          <a-table class="run-task-table" :columns="resultColumns" :loading="resultLoading" :data-source="resultData" :pagination="false" />
        </div>
      </div>
    </div>
  </template>
    <script>
  export default {
    components: {},
    props: {
      currentHistory: Object,
      resultLoading: Boolean,
      resultData: Array,
      resultColumns: Array,
      isFullScreen:{
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
      };
    },
    mounted() {},
    methods: {
      fullScreen() {
        this.$emit('fullScreen')
      },
      onClose () {
        this.$destroyAll();
      },
      closeFullScreen() {
        this.$emit('closeFullScreen')
      },
    },
  };
  </script>
    <style lang="less" scoped>
    .hasfullScreen {
      .run-task-history-page {
        .run-task-table {
        /deep/ .ant-table-content {
          max-height: calc(100vh - 64px)!important;
       }
      }
    }
  }
  .run-task-history-page {
    padding: 6px 0 16px;
    .file-center-footer {
      // height: 258px;
      .run-task-table {
        /deep/ .ant-table-content {
          height: 200px;
        }
        /deep/ .ant-empty-normal {
          margin: 0;
        }
      }
      .center-footer-options {
        height: 40px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #D3DAE4;
        margin-bottom: 0px;
        padding: 0 16px;
        .center-footer-option {
          position: relative;
          & + .center-footer-option {
            margin-left: 40px;
  
            &::before {
              content: "";
              position: absolute;
              left: -20px;
              width: 1px;
              height: 18px;
              background: #e2e4e5;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
        .full-screen-option {
          flex: 1;
          .option {
            width: 24px;
            height: 24px;
            border: 1px solid rgba(209, 210, 215, 1);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            float: right;
            margin-right: 24px;
          }
          .file-full {
            width: 24px;
            height: 24px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            float: right;
            margin-right: 24px;
          }
          .file-full-icon:hover {
            color: red;
          }
        }
      }
      .mgb0 {
        margin-bottom: 0;
      }
      .bd-none {
        border-block: none;
      }
      .center-footer-log {
        height: calc(100% - 50px);
        overflow: auto;
        word-break: break-all;
        padding: 0 16px;
        // border: 1px dotted rgba(209, 210, 215, 1);
        // border-radius: 4px;
        background: #fff;
        .log-option {
          width: 24px;
          height: 24px;
          border: 1px solid rgba(209, 210, 215, 1);
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        &.fullScreen {
          position: fixed;
          width: 100vw;
          height: 100vh;
          top: 0;
          left: 0;
          z-index: 9999;
          padding-top: 48px;
          /deep/ .jv-code {
            padding-top: 0;
          }
        }
      }
    }
  }
  </style>