<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-12-04 11:36:35
 * @LastEditTime: 2023-12-19 18:32:04
 * @FilePath: \awx-ui\src\pages\formRenderDemo\Render.vue
-->
<template>
  <div>
      <schema-form
        ref="schemaForm"
        :schema="schema"
        :ui-schema="uiSchema"
        :error-schema="errorSchema"
        :custom-formats="customFormats"
        :form-footer="formFooter"
        :form-props="formProps"
        :fallback-label="true"
        v-model="formData"
        paramsProps="{}"
        @keyup.native.enter="handleSearch"
        @on-form-mounted="handleFormMounted"
        @on-change="handleDataChange"
        @on-cancel="handleCancel"
        @on-submit="handleSubmit"
      />
  </div>
</template>

<script>
import SchemaForm  from "@formEngine/index"
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "Login",
  props: {
    selectedTemplateObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    model: {
      type: Object,
      default: () => {
        return {}
      }
    },
    designerType: {
      type: String,
      default: 'task'
    }
  },
  components: { SchemaForm },
  data() {
    return {
      schema: {},
      formData: {},
      uiSchema: {},
      errorSchema:{},
      customFormats: null,
      formFooter: {
        show: false
      },
      formProps: {
        layoutColumn: 1,
        labelWidth: "20%",
        labelPosition: 'left',
        labelSuffix: null,
        isMiniDes: true
      }
    }
  },
  watch: {
    selectedTemplateObj: {
      handler(val) {
        this.schema = val.schema
      },
      deep: true,
      immediate: true
    },
    model: {
      handler(val) {
        this.formData = val
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
  },
  methods: {
    handleFormMounted() {
      console.log('表单加载完成------', this.formData)
    },
    handleDataChange() {
      console.log('表单数据改变------', this.formData)
      this.$emit('getFormData', this.formData)
    },
    handleCancel() {
      console.log('表单提交取消------', this.formData)
    },
    handleSubmit() {
      console.log('表单确认提交------', this.formData)
    }
  },
};
</script>

<style lang="less" scoped>
</style>
