/*
 * @Author: mjzhu
 * @Date: 2021-11-22 10:26:37
 * @LastEditTime: 2024-01-18 14:27:03
 * @FilePath: \awx-ui\src\icons\index.js
 */
import Vue from 'vue'
import SvgIcon from './SvgIcon'// svg component

// register globally
Vue.component('svg-icon', SvgIcon)
const common = require.context('./common', false, /\.svg$/)
const task = require.context('./task', false, /\.svg$/)
const fileDistribution = require.context('./fileDistribution', false, /\.svg$/)
const templateModule = require.context('./templateModule', false, /\.svg$/)
const menuModule = require.context('./menuModule', false, /\.svg$/)
const dashBoard = require.context('./dashBoard', false, /\.svg$/)
const flow = require.context('./flow', false, /\.svg$/)
const operationConsole = require.context('./operationConsole', false, /\.svg$/)
const newMenu = require.context('./newMenu', false, /\.svg$/)
const configMaster = require.context('./configMaster', false, /\.svg$/)
const fileManage = require.context('./fileManage', false, /\.svg$/)
const app = require.context('./app', false, /\.svg$/)
const schedule = require.context('./schedule', false, /\.svg$/)
const fileTree = require.context('./fileTree', false, /\.svg$/)
const contextMenuIcon = require.context('./contextMenu', false, /\.svg$/)
const bottomTabs = require.context('./bottomTabs', false, /\.svg$/)
const account = require.context('./account', false, /\.svg$/)






const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(common)
requireAll(task)
requireAll(fileDistribution)
requireAll(templateModule)
requireAll(menuModule)
requireAll(dashBoard)
requireAll(flow)
requireAll(operationConsole)
requireAll(newMenu)
requireAll(configMaster)
requireAll(fileManage)
requireAll(app)
requireAll(schedule)
requireAll(fileTree)
requireAll(contextMenuIcon)
requireAll(bottomTabs)
requireAll(account)


