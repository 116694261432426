import genSchema from '../genSchema.js';
const codeEditSchema = {
  theme: {
    title: '编辑器主题',
    type: 'string',
    'ui:widget': 'SelectWidget',
    default: 'vs-dark',
    enum: [
      'vs-light',
      'vs-dark',
    ],
    enumNames: [
      'vs-light',
      'vs-dark',
    ]
  },
  editorHeight: {
    title: '编辑器高度',
    type: 'string',
    default: '240px',
    "ui:options": {
      "placeholder": "请输入高度px"
    }
  },
  readOnly : {
    title: '只读',
    type: 'boolean',
    default: false,
  }
};
const codeEditDependSchema = {
  language : {
    title: '编程语言',
    type: 'string',
    'ui:placeholder': '关联表单语言选项属性'
  }
}
const viewSchema = {
  title: '代码编辑器',
  type: 'string',
  'ui:widget': 'ElCodeEdit',
};
export default {
  viewSchema,
  propsSchema: genSchema({
    options: {
      type: 'object',
      title: '选项',
      required: [],
      properties: {
        schemaOptions: {
          type: 'object',
          properties: {
            "ui:options": {
              type: 'object',
              properties: codeEditSchema
            },
            dependOnProps: {
              type: 'object',
              properties: codeEditDependSchema
            },
          }
        }
      },
    }
  })
};
