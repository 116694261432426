<!--
 * @Author: mjzhu
 * @Date: 2022-05-24 10:28:22
 * @LastEditTime: 2024-06-06 15:00:40
 * @FilePath: \awx-ui\src\layouts\header\firstLevelHeader.vue
-->
<template>
  <a-layout-header :class="[headerTheme, 'admin-header']">
    <div
      :class="['admin-header-wide', layout, pageWidth]"
      @click="hideSet"
    >
      <div
        v-if="isMobile || layout === 'head'"
        :class="['logo', isMobile ? null : 'pc', headerTheme]"
      >
        <img
          width="30"
          src="@/assets/img/logo3.svg"
        />
        <h1 v-if="!isMobile">{{systemName}}</h1>
      </div>
      <a-divider
        v-if="isMobile"
        type="vertical"
      />
      <div
        v-if="layout !== 'side' && !isMobile"
        class="admin-header-menu"
        :style="`text-align:center`"
      >
        <TriggerHeader :menuData="menuData" />
      </div>
      <div :class="['admin-header-right', headerTheme]">
        <a-select
          v-model="cluster_id"
          @change="changeClusterId"
        >
          <a-select-option
            v-for="(item) in masterOptions"
            :disabled="item.status !== 'online'"
            :key="item.id"
            :value="item.id"
          >
            <span class="" :title="item.name">
              <svg-icon
                class="mgr8"
                :icon-class="item.status === 'online' ? 'task-successful' : 'task-init'"
                :style="{color: item.status === 'online' ? '#5CB863' : '#ED4F50'}"
              ></svg-icon>
              <span class="mgr12">{{ item.name }}</span>
            </span>
          </a-select-option>
        </a-select>
        <span
          class="run-history"
          @click="() => openDrawer()"
        >
          <svg-icon
            style="font-size: 16px"
            icon-class="notice"
          ></svg-icon>
        </span>
        <span
          class="run-history"
          @click="toSet"
        >
          <svg-icon
            style="font-size: 18px"
            icon-class="setting"
          ></svg-icon>
        </span>
        <span
          class="run-history"
          @click="toUser"
        >
          <svg-icon
            style="font-size: 16px"
            icon-class="user-photo"
          ></svg-icon>
        </span>
      </div>
    </div>

  </a-layout-header>
</template>
    
<script>
// import runTaskHistory from "@/components/runTaskHistory";
import TriggerHeader from "./levelHeader.vue";
// import HeaderAvatar from "./HeaderAvatar";
// import accountSetting from "./accountSetting.vue";
// import IMenu from "@/components/menu/menu";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import ApplyQuotaVue from "@/components/quota/index.vue";

export default {
  name: "AdminHeader",
  components: { TriggerHeader },
  props: ["collapsed", "menuData"],
  data () {
    return {
      cluster_id: undefined,
      langList: [
        { key: "CN", name: "简体中文", alias: "简体" },
        { key: "US", name: "English", alias: "English" },
      ],
      showSetting: false,
      searchActive: false,
      masterOptions: []
    };
  },
  watch: {
    masterOptionsVuex: {
      handler (val) {
        console.log(val, 'ssssss')
        this.masterOptions = val
        this.initClusterid()
      },
      deep: true,
      // immediate: true
    }
  },
  computed: {
    ...mapState({
      runHistory: (state) => state.runHistory, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
      drawer: (state) => state.drawer, // 用于文件管理里面的抽屉
      account: (state) => state.account, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
      fileManage: (state) => state.fileManage, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    ...mapGetters("setting", ["isCluster", "runningCluster", "clusterId"]),
    ...mapState("setting", [
      "theme",
      "isMobile",
      "layout",
      "systemName",
      "lang",
      "pageWidth",
      "masterOptionsVuex"
    ]),
    headerTheme () {
      if (
        this.layout == "side" &&
        this.theme.mode == "dark" &&
        !this.isMobile
      ) {
        return "light";
      }
      return this.theme.mode;
    },
    langAlias () {
      let lang = this.langList.find((item) => item.key == this.lang);
      return lang.alias;
    },
    menuWidth () {
      const { layout, searchActive } = this;
      const headWidth = "100%";
      return `calc(${headWidth} - 430px)`;
    },
    currentCluster () {
      let arr =
        this.runningCluster.filter(
          (item) => item.value === Number(this.clusterId)
        ) || [];
      return {
        name: arr.length > 0 ? arr[0].label : "",
        clusterId: this.clusterId,
      };
    },
    // masterOptions () {
    //   const options = localStorage.getItem('masterOptions')
    //   const masterOptions = (options && options.length) ? JSON.parse(options) : []
    //   return masterOptions
    // }
  },
  created () {
    this.getMaster()
  },
  mounted () {
  },
  methods: {
    ...mapActions("runHistory", ["getHistoryList", "changeShowHistory"]),
    ...mapActions("setting", ["setMasterOptions"]),
    ...mapMutations("account", ["setAccountSetting"]),
    ...mapActions("masterMd", ["setHasMaster"]),
    ...mapActions("fileManage", [
      "changeRealEditList",
      "changeShowItem",
      "setClusterId",
    ]),
    toggleCollapse () {
      this.$emit("toggleCollapse");
    },
    getMaster () {
      this.$axiosGet(global.API.getMasterInfo).then((res) => {
        this.spinning = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          if (res.data.results.length > 0) {
            const onlineCluster = res.data.results.filter(item => item.status === 'online')
            let DefaultMaster = {}
            if (onlineCluster.length > 0) {
              this.setHasMaster(true)
              const arr = onlineCluster.filter(item => item.is_default)
              if (arr.length > 0) {
                DefaultMaster = arr[0]
              } else {
                DefaultMaster = onlineCluster[0]
              }
            } else {
              this.setHasMaster(false)
            }
            localStorage.setItem('masterOptions', JSON.stringify(res.data.results))
            localStorage.setItem('FILE_SYSTEM_URL', DefaultMaster?.public_addr || DefaultMaster?.private_addr || '')
            window.config.FILE_SYSTEM_URL = DefaultMaster.public_addr || DefaultMaster.private_addr || ''
            // this.masterOptions = res.data.results
            this.setMasterOptions(res.data.results)
          } else {
            localStorage.setItem('FILE_SYSTEM_URL', '')
            window.config.FILE_SYSTEM_URL = ''
          }
        }
      });
    },
    initClusterid () {
      if (!this.masterOptions) {
        this.cluster_id = undefined
        this.setClusterId(this.cluster_id)
      }
      // if (this.cluster_id) return false 
      const onLlineCluster = this.masterOptions.filter(item => item.status === 'online')
      const index = onLlineCluster.findIndex(item => item.id === this.cluster_id)
      if (index > -1) return false
      if (onLlineCluster.length > 0) {
        const DefaultMaster = onLlineCluster.find(item => (item.is_default))
        this.cluster_id = DefaultMaster?.id || onLlineCluster[0]?.id || undefined
        this.setClusterId(this.cluster_id)
      }
    },
    changeClusterId (val) {
      const onlineCluster = this.masterOptions.filter(item => item.id === val)
      let DefaultMaster = {}
      if (onlineCluster.length > 0) {
        DefaultMaster = onlineCluster[0]
        this.cluster_id = DefaultMaster?.id || undefined
        this.setClusterId(this.cluster_id)
      }
      localStorage.setItem('FILE_SYSTEM_URL', DefaultMaster?.public_addr || DefaultMaster?.private_addr || '')
      window.config.FILE_SYSTEM_URL = DefaultMaster.public_addr || DefaultMaster.private_addr || ''
      this.changeRealEditList([])
      this.changeShowItem(null)
    },
    hideSet () {
      if (this.account.accountSetting) this.setAccountSetting(false)
      this.changeShowHistory(false)
    },
    toUser (e) {
      if (e) e.stopPropagation();
      this.setAccountSetting(!this.account.accountSetting)
      this.changeShowHistory(false)
    },
    // 设置的时候打开申请配额
    openApplyModal () {
      const self = this;
      let width = 902;
      let title = '申请配额'
      let content = (
        <ApplyQuotaVue quotaType={'apply'} callBack={() => {
          self.getProjectList()
        }} />
      );
      this.$confirm({
        width: width,
        title: title,
        class: 'quota-apply-modal',
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    toSet (e) {
      if (e) e.stopPropagation();
      this.setAccountSetting(false)
      this.changeShowHistory(false)
      this.openApplyModal()

    },
    onSelect (obj) {
      this.$emit("menuSelect", obj);
    },
    ...mapMutations("setting", ["setLang"]),
    openDrawer () {
      const type = this.runHistory.runtab
      this.getHistoryList({ type: type, init: false })
      this.changeShowHistory(true)
    }
  },
};
</script>
<style lang="less" scoped>
@import 'index';
.system-name {
  font-size: 14px;
  color: #b5b8bf;
  letter-spacing: 0.39px;
  font-weight: 500;
  margin-right: 5px;
}
.run-history {
  cursor: pointer;
  padding: 0 12px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.24) !important;
  }
}

.ope-btn {
  border: none;
  color: #fff;
  padding: 0;
  margin: 0;
}
.run-total {
  position: relative;
  left: -3px;
  padding: 2px 7px;
  background: #ff7152;
  border-radius: 7px;
  font-size: 12px;
  color: #ffffff;
}
/deep/ .ant-select-selection {
  border: 1px solid rgba(207, 213, 222, 0.4) !important;
  // border: none;
  color: #fff;
  background: rgb(48, 63, 93);
  border-radius: 4px;
  svg {
    color: #fff;
  }
}
</style>
    