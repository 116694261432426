/*
 * @Author: zhang·xiao
 * @Date: 2022-12-31 20:15:27
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2023-06-04 22:16:00
 * @Description: 描述文件功能
 */
import Vue from 'vue';
import CodeEditWidget from './CodeEditWidget';
CodeEditWidget.install = (Vue) => {
  Vue.component(CodeEditWidget.name, CodeEditWidget)
}
Vue.use(CodeEditWidget)
export default CodeEditWidget;