/*
 * @Author: zhang·xiao
 * @Date: 2022-11-29 13:37:16
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2023-06-04 22:17:09
 * @Description: 配置一些自定义组件
 */

import CheckboxesWidget from './CheckboxesWidget';
import RadioWidget from './RadioWidget';
import SelectWidget from './SelectWidget';
import CodeEditWidget from './CodeEditWidget';
import DatePickerWidget from './DatePickerWidget';
import DateTimePickerWidget from './DateTimePickerWidget';
import TimePickerWidget from './TimePickerWidget';
import UploadWidget from './UploadWidget';

const widgetComponents = {
  CheckboxesWidget,
  RadioWidget,
  SelectWidget,
  TimePickerWidget,
  DatePickerWidget,
  DateTimePickerWidget,
  CodeEditWidget,
  UploadWidget
};

export default widgetComponents;
