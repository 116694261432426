<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-09-05 11:27:20
 * @LastEditTime: 2023-09-25 14:40:43
 * @FilePath: \awx-ui\src\components\createTask\deploystep\templateModal\index.vue
-->
<template>
  <a-form-model class="template-modal" ref="templeatModalRef" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
    <a-form-model-item label="模板名称" prop="name">
      <a-input v-model="form.name" placeholder="请输入模板名称" />
    </a-form-model-item>
    <a-form-model-item label="模板图标" prop="icon">
      <a-input v-model="form.icon" placeholder="请定义模板图标" />
    </a-form-model-item>
    <a-form-model-item label="场景分类">
      <a-select v-model="form.categories" mode="multiple" placeholder="请选择">
        <a-select-option v-for="(item, index) in templateCate" :key="index" :value="item.catagory">{{ item.name }}</a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="行业分类">
      <a-select v-model="form.contents" mode="multiple" placeholder="请选择">
        <a-select-option v-for="(item, index) in templateTag" :key="index" :value="item.tag">{{ item.name }}</a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="模板描述">
      <a-input v-model="form.description" type="textarea" placeholder="请输入模板描述" />
    </a-form-model-item>
    <div class="ant-modal-confirm-btns-footer">
      <a-button @click.stop="formCancel">取消</a-button>
      <a-button
        style="margin-left: 10px"
        type="primary"
        :loading="loading"
        @click.stop="handleSubmit"
      >保存</a-button>
    </div>
  </a-form-model>
</template>
<script>
export default {
  props: {
    callBack: Function,
    templateName: String
  },
  watch: {
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      templateTag: [],
      templateCate: [],
      rules: {
        name: [
          { required: true, message: '请输入模板名称', trigger: 'blur' },
        ],
        icon: [
          { required: true, message: '请定义模板图标', trigger: 'blur' },
        ]
      },
      loading: false,
      form: {
        name: '',
        description: '',
        "categories": [],
        "tags": [],
        icon: '/awx/target/icon/once.svg'
      }
    };
  },
  methods: {
    formCancel () {
      this.$destroyAll();
    },
    handleSubmit () {
      this.$refs.templeatModalRef.validate(async (valid) => {
        if (valid) {
          this.loading = true
          this.callBack(this.form)
        }
      });
    },
    getCatagories() {
      this.$axiosGet(global.API.getCatagories, {
        page: 1,
        pageSize: 1000,
      }).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.templateCate = res.data.results;
        }
      });
    },
    getTagsList() {
      this.$axiosGet(global.API.getTagsList, {}).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.templateTag = res.data || [];
        }
      });
    },
  },
  mounted () {
    this.getCatagories();
    this.getTagsList();
    this.form.name = this.templateName
  }
};
</script>
<style lang="less" scoped>
.template-modal {
  padding: 12px 16px;
  .ant-modal-confirm-btns-footer {
    margin: 0px 0 0 0;
    border: 1px solid rgba(223, 232, 243, 0.3);
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
