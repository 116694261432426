/*
 * @Author: mjzhu
 * @Date: 2022-05-24 10:22:10
 * @LastEditTime: 2024-05-29 15:50:40
 * @FilePath: \awx-ui\src\api\request.js
 */
import Vue from 'vue'
import axios from 'axios'
// import '@/api/interceptors'
axios.defaults.withCredentials= true
// post数据处理
const handleParams = function (data) {
  const params = new FormData()
  for (var key in data) {
    params.append(key, data[key])
  }
  return params
}

const axiosGet = function (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: url,
      params: params,
      token: localStorage.getItem('token'),
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}

const axiosPost = function (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: url,
      data: handleParams(params),
      ContentType: "application/json;charset=UTF-8",
      token: localStorage.getItem('token'),
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}
const axiosJsonPost = function (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: url,
      data: params,
      token: localStorage.getItem('token'),
    }).then(res => {
      resolve(res)
    }).catch(error => {
      resolve(error)
    })
  })
}

// 文件上传，params为form-data
const axiosPostUpload = function (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: url,
      data: params,
      token: localStorage.getItem('token'),
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}
const axiosPut = function (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'put',
      url: url,
      data: params,
      token: localStorage.getItem('token'),
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}
const axiosDelete = function (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'delete',
      url: url,
      data: params,
      token: localStorage.getItem('token'),
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}
const axiosProxyDelete = function (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'delete',
      url: url,
      data: params,
      token: localStorage.getItem('token')
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}
// 文件上传，params为form-data
const axiosFormData = function (url, params = {}) {
  let config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
  }
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: url,
      data: params,
      token: localStorage.getItem('token'),
      config,
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}
const axiosDownGet = function (url, params = {}, config = {}) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: url,
      params: params,
      ...config,
      token: localStorage.getItem('token'),
      responseType: 'blob'
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}
// 文件上传，params为form-data
const axiosPutFormData = function (url, params = {}, config) {
   let newConfig = {
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    // },
    ...config
  }
  return new Promise((resolve, reject) => {
    axios({
      method: 'put',
      url: url,
      data: params,
      ...config,
      ContentType: 'multipart/form-data',
      token: localStorage.getItem('token')
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}
const axiosProxyGet = function (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: url,
      params: params,
      token: localStorage.getItem('token')
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}
const axiosProxyPut = function (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'put',
      url: url,
      data: params,
      token: localStorage.getItem('token')
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}
const axiosJsonProxyPost = function (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: url,
      data: params,
      token: localStorage.getItem('token')
    }).then(res => {
      resolve(res)
    }).catch(error => {
      resolve(error)
    })
  })
}
const axiosPatch = function (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'patch',
      url: url,
      data: params,
      token: localStorage.getItem('token')
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}
const axiosProxyDown = function (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: url,
      params: params,
      token: localStorage.getItem('token'),
      responseType: "blob",
    }).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}
Vue.prototype.$axiosGet = axiosGet// get请求
Vue.prototype.$axiosDownGet = axiosDownGet// get请求
Vue.prototype.$axiosPost = axiosPost// post请求
Vue.prototype.$axiosPostUpload = axiosPostUpload// 文件上传-post请求
Vue.prototype.$axiosJsonPost = axiosJsonPost// jsonpost请求
Vue.prototype.$axiosFormData = axiosFormData// jsonpost请求
Vue.prototype.$axiosPut = axiosPut// jsonpost请求
Vue.prototype.$axiosDelete = axiosDelete// jsonpost请求
Vue.prototype.$axiosPatch = axiosPatch  //patch请求
Vue.prototype.$axiosPutFormData = axiosPutFormData  //patch请求
Vue.prototype.$axiosProxyGet = axiosProxyGet  //patch请求
Vue.prototype.$axiosProxyPut = axiosProxyPut  //patch请求
Vue.prototype.$axiosJsonProxyPost = axiosJsonProxyPost  //patch请求
Vue.prototype.$axiosProxyDelete = axiosProxyDelete  //patch请求
Vue.prototype.$axiosProxyDown = axiosProxyDown  //patch请求


