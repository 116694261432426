/* eslint-disable */
/*
 * @Author: mjzhu
 * @Date: 2022-05-24 14:48:45
 * @LastEditTime: 2023-10-27 17:15:37
 * @FilePath: \awx-ui\src\components\createTask\deploystep\validate.js
 */
import moment from 'moment';
import cronParser from 'cron-parser';
import { message } from "ant-design-vue";
// 校验调度里面的必填项
function validateTask(taskData) {
  let flag = true
  for (let i = 0; i < taskData.operations.length; i++) {
    if (!taskData.operations[i].application_id) {
      flag = false
      let msg = `第${i+1}个步骤${taskData.operations[i].application_name}请选择应用`
      if (taskData.operations[i].application_name === '请选择一个应用') msg = `第${i+1}个步骤请选择应用`
      message.error(msg)
      break
    }
    if (taskData.operations[i].application_id && !taskData.operations[i].id) {
      flag = false
      let msg = `第${i+1}个步骤${taskData.operations[i].application_name}请选择操作`
      if (taskData.operations[i].application_name === '请选择一个应用') msg = `第${i+1}个步骤请选择操作`
      message.error(msg)
      break
    }
    if (taskData.operations[i].application_id && taskData.operations[i].id && taskData.operations[i].model) {
      let sum = 0
      const requireParam = taskData.operations[i].application_id === 'schedule' && taskData.operations[i].form_component === 'schedule' && taskData.operations[i].id !== "manual" ? taskData.operations[i]?.rules[`${taskData.operations[i]?.id}`] : taskData.operations[i]?.schema?.schema?.required || []
      if (!requireParam || (requireParam && requireParam.length === 0)) flag = true
      for (var key in taskData.operations[i].model) {
        // 必填
        if (requireParam && requireParam.includes(key)) {
          if (!taskData.operations[i].model[key]) {
            let msg = `第${i+1}个步骤${taskData.operations[i].application_name}的参数请配置完整`
            message.error(msg)
            sum++ 
            break
          }
          if (Array.isArray(taskData.operations[i].model[key]) && taskData.operations[i].model[key].length === 0) {
            let msg = `第${i+1}个步骤${taskData.operations[i].application_name}的参数请配置完整`
            message.error(msg)
            sum++ 
            break
          }
        }
      }
      if (sum > 0) {
        flag = false
        break
      } 
    } else {
      flag = false
      break
    }
  }
  if (flag) {
    // 校验cron表达式
    try {
      const scheduleObj = taskData.operations.find(item => item.application_id === 'schedule')
      if (scheduleObj) {
        const currentDate = scheduleObj.model.start_time
        const endDate = scheduleObj.model.forever && scheduleObj.model.start_time ? moment(scheduleObj.model.start_time).add(100, "years").format('YYYY-MM-DD HH:mm:ss') : scheduleObj.model.end_time
        const interval = cronParser.parseExpression(scheduleObj.model.cron, {
          currentDate,
          endDate,
          tz: 'Asia/Shanghai',
        });
      } else {
        return true
      }
    } catch (e) {
      // 捕获异常，并设置错误信息
      message.error(e.message)
      return false
    }
    if (taskData.operations.length === 1 && taskData.operations[0].application_id === 'schedule') {
      message.error('除调度外，请至少再配置一个步骤')
      flag = false
    }
  }
  return flag
}
function validateStep(taskData) {
  let flag = true
  if (taskData.application_id && taskData.id && taskData.model) {
    let sum = 0
    const requireParam = taskData?.schema?.schema?.required || []
    if (requireParam.length === 0) flag = true
    for (var key in taskData.model) {
      // 必填
      if (requireParam.includes(key)) {
        if (!taskData.model[key]) {
          sum++ 
          break
        }
        // 空数组
        if (Array.isArray(taskData.model[key]) && taskData.model[key].length === 0) {
          sum++ 
          break
        }
        // 空对象 todo
        // if (Array.isArray(taskData.model[key]) && taskData.model[key].length === 0) {
        //   sum++ 
        //   break
        // }
      }
    }
    if (sum > 0) {
      flag = false
    } 
  } else {
    flag = false
  }
  return flag
}
export {
  validateTask,
  validateStep
}