<!--
 * @Author: mjzhu
 * @describe: 左边的已经选择过的步骤
 * @Date: 2022-06-13 16:35:02
 * @LastEditTime: 2024-03-29 14:33:13
 * @FilePath: \awx-ui\src\components\createTask\components\dragTemplate.vue
-->
<template>
  <div>
    <div :class="['drag-template-item', task.selectTemplateIndex === templateItemIndex ? 'drag-template-item-selected' : '', templateItemIndex === 0 && 'schedule-item']" :style="{marginTop: templateItemIndex === 0 ? '16px': '0px', marginBottom: templateItemIndex === operationList.length -1 ? '0px': '0px'}" @click="selectedTemplate(templateItemIndex)">
       <div  :class="['small-point', task.selectTemplateIndex === templateItemIndex && 'small-point-selected']"></div>
       <div v-if="templateItemIndex > 0" :class="['small-circle', task.selectTemplateIndex === templateItemIndex && 'small-circle-selected']">
      </div>
      <div class="img-warp">
        <!-- 系统图标 -->
        <img v-if="templateItem.icon && !IsColorIcon(templateItem.icon) && !IsNetWorkIcon(templateItem.icon)" :src="templateItem.icon " alt />
         <!-- 纯色图标 -->
        <div v-if="templateItem.icon && IsColorIcon(templateItem.icon) && !IsNetWorkIcon(templateItem.icon)"  class='icon-item' :style="{
          'background': templateItem.icon,
          'text-align': 'center',
          'width': '44px',
          'height': '44px',
          'border-radius': '8px',
          'margin': 'auto'
        }">
        <span :style="{
          'color': '#fff',
          'font-size': iconColorName(templateItem.name).length === 1 ? '20px' : '14px',
          'display': 'block',
          'width': '100%',
          'height': '100%',
          'line-height': '44px'
        }">{{ iconColorName(templateItem.name) }}</span>  
      </div>
        <!-- 网络图标 -->
        <iconifyIcon v-if="templateItem.icon && !IsColorIcon(templateItem.icon) && IsNetWorkIcon(templateItem.icon)" :icon="iconNetWorkName(templateItem.icon)" style="font-size: 32px;" />
        <svg-icon v-if="!templateItem.icon" icon-class="flow-app" style="font-size: 24px;position: relative;left: 3px;color:#3974f4;margin: auto;display: inline-block"></svg-icon>
      </div>
      <div class="action-content" :style="{'width': templateItemIndex === 0 ? 'calc(100% - 16px)' : 'calc(100% - 100px)'}">
        <div class="action-text-wrap">
          <p class="action-label over-flow-ellipsis action-name" :style="{'max-width': templateItemIndex === 0 ? '100%' : '72%'}" :title="templateItem.application_name">{{templateItem.application_name}}</p>
        </div>
        <div>
          <p v-if="templateItem.application_id && !templateItem.id" class="action-desc over-flow-ellipsis">请选择操作</p>
          <p v-if="templateItem.application_id && templateItem.id" class="action-desc over-flow-ellipsis" :title="templateItem.name">操作： {{templateItem.name}}</p>
          <p v-if="templateItemIndex === 0 && templateItem.application_name === '请配置一个触发条件'" class="action-desc over-flow-ellipsis" title="当满足条件时，则流程自动触发">当满足条件时，则流程自动触发</p>
        </div> 
      </div>
      <div v-if="templateItem.application_id && templateItem.id && templateItemIndex !== 0" :class="['switch-btn', templateItem.enabled ? 'switch-on': 'switch-off']" @click="turnSwitch">
        {{ templateItem.enabled ? 'ON' : 'OFF' }}
      </div>
      <div class="more-action" v-if="templateItemIndex !== 0">
        <a-popover placement="rightTop"  overlayClassName="pop-confrim-modal">
          <template slot="content">
            <div v-for="item in topButtonTree" :key="item.key">
              <a @click="addMenu(item.key)" :class="[templateItem.application_name === '请选择一个应用'? '' : '']" style="color: #666;padding: 5px 0; display: block">{{item.name}}</a>
            </div>
          </template>
          <span class="sider-header-btn">
            <svg-icon icon-class="task-more" style="font-size: 18px"></svg-icon>
          </span>
        </a-popover>
      </div>
    </div>
    <!-- 一个箭头线的开始 -->
    <div class="ArrowDown__Wrapper-finQpt fJoHGo">
      <div :class="['node-arrow-line', 'node-arrow-down', task.selectTemplateIndex === templateItemIndex ? 'drag-template-line-selected' : '']" :style="{height: '50px'}">
        <svg style="margin-left: 50%;" width="2px" :height="templateItemIndex === operationList.length -1 ? '22' : '48px'" version="1.1">
          <path :stroke="task.selectTemplateIndex === templateItemIndex  ? '#42526C' : '#42526C'" stroke-width="2" fill="none" :d="templateItemIndex === operationList.length -1 ? 'M 1 0 v 24' : 'M 1 0 v 50'" />
        </svg>
        <div class="add-line">
          <div class="AddLine__Wrapper-NdCB fBFgIu">
            <div class="addline-wrap-container">
              <div :class="['addline-wrap', 'flex', 'ai-center', 'jc-center',]" @click.stop="add('after', templateItem)">
                <svg-icon icon-class="task-add" style="font-size: 22px"></svg-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 一个箭头线的结束 -->
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { validateStep } from '../deploystep/validate'
export default {
  props: {
    templateItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    operationList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    templateItemIndex: Number,
    selectedTemplateId: String,
  },
  inject: ["selectTool"],
  data() {
    return {
      topButtonTree: [
        { name: "复制", key: "copy" },
        { name: "删除", key: "del" },
      ],
      selectedTemplateObj: {},
    };
  },
  watch: {
    "task.selectTemplateIndex": {
      handler(val) {
        if (
          this.task &&
          this.task.taskInfo &&
          this.task.taskInfo.taskTemplateDetail
        )
          this.selectedTemplateObj =
            this.task.taskInfo.taskTemplateDetail.operations[val];
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
  },
  methods: {
    ...mapActions("task", [
      "setAddTemplateIndex",
      "setShowStepsList",
      "setSelectTemplateIndex",
      "setTaskInfo",
      "setCurrentOptIndex",
      "setRefreshFlag",
      "setCurrentPageIndex"
    ]),
    IsColorIcon(icon) {
      return global.utils.IsColorIcon(icon);
    },
    iconColorName (name){
      return global.utils.iconColorName(name);
    },
    IsNetWorkIcon(icon) {
      return global.utils.IsNetWorkIcon(icon);
    },
    iconNetWorkName (name){
      return global.utils.iconNetWorkName(name);
    },
    // 打开开关
    turnSwitch (e) {
      e.stopPropagation();
      let operationList = _.cloneDeep(
        this.task.taskInfo?.taskTemplateDetail?.operations
      );
      operationList[this.templateItemIndex].enabled = !operationList[this.templateItemIndex].enabled
      let taskInfo = _.cloneDeep(this.task.taskInfo);
      taskInfo.taskTemplateDetail.operations = _.cloneDeep(operationList);
      this.setTaskInfo(taskInfo);
    },
    addMenu(key) {
      let operationList = [];
      if (
        this.task &&
        this.task.taskInfo &&
        this.task.taskInfo.taskTemplateDetail &&
        this.task.taskInfo.taskTemplateDetail.operations
      )
        operationList = _.cloneDeep(
          this.task.taskInfo.taskTemplateDetail.operations
        );
      if (key === "del") {
        operationList.splice(this.templateItemIndex, 1);
        if (this.templateItemIndex > - 1 && operationList.length > 0) {
          // 首先判断是不是选中的这个节点
          if (this.templateItemIndex === this.task.selectTemplateIndex) {
            const delIndex = this.task.selectTemplateIndex === 0 ? this.task.selectTemplateIndex : this.task.selectTemplateIndex - 1
            // 如果选中是第一个删除的第一个 这时候 index 还是0 没有变化无法触发右侧刷新 这里定义一个递增的变量 只要删除了第一个就增加 强制去刷新右边的页面
            const sum = this.task.refreshFlag + 1
            if (this.task.selectTemplateIndex === 0) this.setRefreshFlag(sum);
            // 如果不是最后一个 就往前导一个 在获取到前一个的信息 去控制右边到哪一步了！如果就一个那么变为空状态，
            const taskDetail = operationList[delIndex]
            this.setSelectTemplateIndex(delIndex);
             // 如果有应用id 那就到选择操作的界面
            if (taskDetail.application_id) {
              this.setCurrentOptIndex(1)
            }
            // 如果有操作也就是有步骤id 那么就展示配置参数的页面
            // TODO 这个地方需要判断是不是需要跳转到授权页面还是
            if (taskDetail.id) {
              this.setCurrentOptIndex(3)
            }
            // // 如果有操作也就是有授权展示ID 那么就展示配置参数的页面
            // if (taskDetail.conn.conn_id) {
            //   this.setCurrentOptIndex(2)
            // }
            // 如果操作配置完成了，那么配置参数tab为完成态
            const flag = validateStep(taskDetail)
            if (taskDetail['currentStepsIndex'] > 1) {
              if (flag) {
                this.setCurrentOptIndex(4)
                this.setCurrentPageIndex(4)
              } else {
                this.setCurrentOptIndex(3)
                this.setCurrentPageIndex(3)
              } 
            }
          }
          // 要是选中的小于删除的 不动 如果大于删除的选中的index-1
          if (this.templateItemIndex < this.task.selectTemplateIndex) {
            this.setSelectTemplateIndex(this.task.selectTemplateIndex -1);
          }
          this.setShowStepsList(false);
        } else {
          // 删除完了 展示步骤列表
          this.setCurrentOptIndex(0)
          this.setShowStepsList(true);
          this.setSelectTemplateIndex(null);
        }
        if (operationList.length === 0) {
          operationList.push({application_name: '请选择一个应用'})
          this.setSelectTemplateIndex(0)
        }
      }
      if (key === "copy") {
        const templateItem = operationList[this.templateItemIndex];
        operationList.splice(this.templateItemIndex + 1, 0, {
          ...templateItem,
        });
        this.setSelectTemplateIndex(this.templateItemIndex + 1);
        this.setShowStepsList(false);
      }
      let taskInfo = _.cloneDeep(this.task.taskInfo);
      taskInfo.taskTemplateDetail.operations = _.cloneDeep(operationList);
      this.setTaskInfo(taskInfo);
    },
    addItem() {
      const obj = {application_name: '请选择一个应用',isEmpty: true}
      let operationList = []
      if (
        this.task &&
        this.task.taskInfo &&
        this.task.taskInfo.taskTemplateDetail &&
        this.task.taskInfo.taskTemplateDetail.operations
      )
        operationList = _.cloneDeep(
          this.task.taskInfo.taskTemplateDetail.operations
        );
      let index = this.task.addTemplateIndex;
      operationList.splice(index, 0, obj);
      let taskInfo = _.cloneDeep(this.task.taskInfo);
      taskInfo.taskTemplateDetail.operations = operationList;
      this.setTaskInfo(taskInfo);
      this.setSelectTemplateIndex(index);
      this.setCurrentOptIndex(0)
      this.setCurrentPageIndex(0)
    },
    add(type, template) {
      this.setShowStepsList(true); // 点击加号展示可选泽的步骤
      if (type === "before") {
        this.setAddTemplateIndex(this.templateItemIndex);
      } else {
        this.setAddTemplateIndex(this.templateItemIndex + 1);
      }
      this.$EventBus.$emit('clearTemplateId')
      this.addItem()
    },
    selectedTemplate(index) {
      let operationList = [];
      if (
        this.task &&
        this.task.taskInfo &&
        this.task.taskInfo.taskTemplateDetail &&
        this.task.taskInfo.taskTemplateDetail.operations
      )
        this.selectTool('steps')
        operationList = this.task.taskInfo.taskTemplateDetail.operations;
        // 这个地方需要去判断右边打开的是第几步
        this.setSelectTemplateIndex(index);
        // 这个讲需要追加的index清掉 在选择+号按钮操作的时候去赋值
        this.setAddTemplateIndex(-1)
        this.setShowStepsList(false);
        // 如果有应用id 那就到选择操作的界面
        if (operationList[index].application_id) {
          this.setCurrentOptIndex(1)
        }
        // 如果有操作也就是有步骤id 那么就展示配置参数的页面
        if (operationList[index].id) {
          this.setCurrentOptIndex(3)
        }
        const flag = validateStep(operationList[index])
        if (operationList[index]['currentStepsIndex'] > 1) {
          if (flag) {
            this.setCurrentOptIndex(4)
            this.setCurrentPageIndex(4)
          } else {
            this.setCurrentOptIndex(3)
            this.setCurrentPageIndex(3)
          } 
        }
        // 如果都没有那么就跳转到选择应用页面
        if (!operationList[index].application_id && !operationList[index].id) {
          this.setCurrentOptIndex(0)
          this.setCurrentPageIndex(0)
        }
    },
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="less" scoped>
.drag-template-item {
  cursor: pointer;
  height: 64px;
  padding: 0 13px;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
  background: #FFFFFF;
  border: 1px solid rgba(151,151,151,1);
  border-radius: 8px;
  .small-point {
    position: absolute;
    left: 50%;
    margin-left: -3px;
    bottom: -4px;
    width: 8px;
    height: 8px;
    background: #42526C;
    border-radius: 50%;    
    &-selected {
      background: #3974F4;
    }
  }

  .small-circle {
    position: absolute;
    left: 50%;
    margin-left: -7px;
    top: -8px;
    width: 16px;
    height: 16px;
    background: #FFFFFF;
    border: 2px solid rgba(66,82,108,1);
    border-radius: 50%;
    &-selected {
      border-color: #3974F4;
    }
  }
  .after-add,
  .before-add {
    color: #42526C;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    height: 20px;
    left: 50%;
    margin-left: -10px;
    line-height: 20px;
    position: absolute;
    text-align: center;
    width: 20px;
    font-size: 20px;
    background: #fff;
  }
  .before-add-icon {
    color: #3974F4;
  }
  .before-add {
    top: -12px;
  }
  .after-add {
    bottom: -8px !important;
  }
  .more-action {
    position: absolute;
    right: 10px;
    display: block;
    .sider-header-btn {
      color: #b9c0c9;
    }
  }
  &:hover {
    border: 2px solid rgba(57,116,244,1);
    box-shadow: 0px 2px 10px 0px rgba(66,82,108,0.2);
    border-radius: 8px;
    color: #1d202d;
    .after-add,
    .before-add {
      display: block;
    }
    .more-action {
      display: block;
      .sider-header-btn {
        // color: #3974F4;
      }
    }
  }
  .img-warp {
    height: 44px;
    margin: 0px 14px 0 0;
    width: 44px;
    min-height: 44px;
    text-align: center;
    line-height: 40px;
    background: #FFF; // 这个是图标的颜色定义
    align-items: center;
    justify-content: center;
    // border: 1px solid rgba(207,213,222,1);
    box-shadow: 0px 3px 10px 0px rgba(66, 82, 108, 0.2);
    border-radius: 8px;
    display: flex;
    img {
      width: 38px;
      border-radius: 8px;
    }
  }
  .switch-btn {
    width: 28px;
    height: 18px;
    border-radius: 4px;
    font-size: 12px;
    color: #FFFFFF;
    text-align: center;
  }
  .action-content {
    display: inline-block;
    width: calc(100% - 100px);
    padding-top: 4px;
    .action-desc {
      color: #7D838E;
    }
  }
  .switch-on {
    background: #57A850;
  }
  .switch-off {
    background: #ED4F50;
  }
  .action-text-wrap {
    width: 100%;
    white-space: nowrap;
    line-height: 20px;
    .action-label {
      display: inline-block;
      font-weight: 700;
      max-width: 72%;
      font-size: 14px;
      color: #1d202d;
      letter-spacing: 0;
      font-weight: 600;
      margin-right: 10px;
      min-width: 10px;
    }
  }
}

.drag-template-item-selected {
  border: 2px solid rgba(57,116,244,1);
  box-shadow: 0px 2px 10px 0px rgba(66,82,108,0.2);
  border-radius: 8px;
  // .img-warp {
  //   box-shadow: inset 0px 0px 6px 0px rgba(136, 185, 255, 0.65);
  // }
  .more-action {
    display: block;
    color: #fff;
    .sider-header-btn {
      // color: #3974F4;
    }
  }
}
.schedule-item {
  height: 64px;
  .action-text-wrap {
    line-height: 1;
  }
  .img-warp {
    height: 42px;
    min-width: 42px;
    vertical-align: top;
    width: 42px;
    padding: 0;
    background: #FFF; // 这个是图标的颜色定义
    border-radius: 6px;
    // box-shadow: none!important;;
    img {
      width: 100%;
    }
  }
}
// 线
.fJoHGo {
  .node-arrow-line {
    position: relative;

    .add-line {
      position: absolute;
      top: 0px;
      bottom: 0px;
      width: 100%;

      .fBFgIu {
        height: 100%;

        .addline-wrap-container {
          position: relative;
          height: 100%;
          opacity: 1;

          &:hover {
            opacity: 1;
          }
        }

        .trigger-add-line {
          top: 45px;
        }

        .addline-wrap {
          position: absolute;
          margin-left: -10px;
          left: 50%;
          top: calc(50% - 14px);
          color: #42526C;
          border-radius: 20px;
          height: 20px;
          font-size: 16px;
          cursor: pointer;
          line-height: 23px;
          background: #fff;
        }
        
        .trigger-add-line {
          top: 45px;
        }
      }
    }

    .text {
      width: max-content;
      position: absolute;
      left: 12px;
      top: 17px;
      font-size: 12px;
      background-color: rgb(240, 242, 244);
      height: 23px;
      line-height: 23px;
    }
  }

  .node-arrow-down::before {
    // content: "";
    // position: absolute;
    // top: calc(100% - 10px);
    // left: 50%;
    // margin-left: -4px;
    // border-width: 10px 5px 0px;
    // border-color: #42526C transparent transparent;
    // border-style: solid;
    // height: 0px;
    // opacity: 1;
    // width: 0px;
  }
  .drag-template-line-selected {
    // &::before { border-color:#3974F4 transparent transparent!important; }
    // .addline-wrap {
    //   color: #3974F4!important;
    // }
  }
}
</style>