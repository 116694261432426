/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-05-24 10:22:10
 * @LastEditTime: 2023-09-06 16:28:04
 * @FilePath: \awx-ui\src\api\index.js
 */
import api from '@/api/httpApi/index.js'
import '@/api/request'


global.API = api// 挂在全局接口
global.intervalTime = 10000
