/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-09-28 10:08:43
 * @LastEditTime: 2023-01-10 15:25:43
 * @FilePath: \awx-ui\src\store\modules\base.js
 */

const state = {
 scheduleDict: []
}

const getters = {}

const mutations = {
}

Object.keys(state).forEach((item) => {
  mutations[item] = (state, value) => {
    state[item] = value
  }
})

const actions = {
  setScheduleDict({ commit }, arr) {
    commit('scheduleDict', arr)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
