/*
 * @Author: mjzhu
 * @Date: 2022-05-24 10:22:10
 * @LastEditTime: 2023-09-06 16:20:51
 * @FilePath: \awx-ui\src\api\httpApi\templeate.js
 */
import paths from '@/api/baseUrl'// 后台服务地址

let path = paths.path().path

export default {
  getTemplates: path + '/templates/task-template/',
}
