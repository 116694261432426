var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"form-engine-container"},[_c('div',{staticClass:"container"},[_c('div',{class:{
                ['contentWrap']: true,
                ['closeToolbar']: _vm.closeToolbar
            }},[_c('div',{class:'toolBarWrap'},[_c('div',{class:'toolsBar'},[_c('EditorToolBar',{attrs:{"drag-group":_vm.dragOptions.group,"config-tools":_vm.configTools},on:{"onFilter":function($event){return _vm.$message.error('该组件添加数目已达上限！')}}})],1),_c('span',{class:'leftCaret',on:{"click":function($event){_vm.closeToolbar = !_vm.closeToolbar}}},[_c('i',{staticClass:"el-icon-caret-right"})])]),_c('div',{staticClass:"contentBox"},[_c('EditorHeader',{attrs:{"default-active":"4"}},[_c('el-button',{attrs:{"plain":""},on:{"click":_vm.handleSaveSchema}},[_vm._v("保存")]),_c('el-button-group',[_c('el-button',{attrs:{"plain":""},on:{"click":_vm.handleImportSchema}},[_vm._v("导入Schema")]),_c('el-button',{attrs:{"plain":""},on:{"click":_vm.handleExportSchema}},[_vm._v("编辑schema")]),_c('el-button',{attrs:{"plain":""},on:{"click":_vm.handlePreview}},[_vm._v("预览展示")])],1)],1),_c('el-form',_vm._b({staticClass:"genFromComponent",class:{
                        layoutColumn: !_vm.formProps.inline,
                        [`layoutColumn-${_vm.formProps.layoutColumn}`]: !_vm.formProps.inline,
                        formInlineFooter: _vm.formProps.inlineFooter,
                        formInline: _vm.formProps.inline,
                    },attrs:{"model":_vm.rootFormData}},'el-form',_vm.formProps,false),[_c('NestedEditor',{attrs:{"child-component-list":_vm.componentList,"drag-options":_vm.dragOptions,"form-data":_vm.rootFormData,"form-props":_vm.formProps}},[(_vm.componentList.length > 0 && _vm.formFooter.show)?_c('el-form-item',{staticClass:"formFooter_item w100 formFooter_item-editor",style:({
                                display: _vm.formProps.inline && _vm.formProps.inlineFooter ? 'inline-block' : 'block'
                            })},[_c('el-button',{on:{"click":function($event){return _vm.$emit('onCancel')}}},[_vm._v(_vm._s(_vm.formFooter.cancelBtn))]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$emit('onSubmit')}}},[_vm._v(" "+_vm._s(_vm.formFooter.okBtn)+" ")])],1):_vm._e()],1)],1),(_vm.componentList.length === 0)?_c('div',{staticClass:"tipBox"},[_c('p',[_vm._v("拖拽左侧栏的组件进行添加")])]):_vm._e()],1),_c('div',{class:'rightForm'},[_c('el-tabs',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[(_vm.curEditorItem)?_c('el-tab-pane',{attrs:{"label":"组件配置","name":"compConfig"}},[_c('VueJsonFrom',{class:'configForm',attrs:{"schema":_vm.curEditorItem.componentPack.propsSchema,"form-props":{ labelPosition: 'top', labelWidth: '120px' },"form-footer":{ show: false }},model:{value:(_vm.curEditorItem.componentValue),callback:function ($$v) {_vm.$set(_vm.curEditorItem, "componentValue", $$v)},expression:"curEditorItem.componentValue"}})],1):_vm._e(),_c('el-tab-pane',{attrs:{"label":"表单配置","name":"formConfig"}},[_c('VueJsonFrom',{class:'configForm',attrs:{"schema":_vm.FormConfSchema,"form-footer":{ show: false },"form-props":{ labelPosition: 'top', labelWidth: '120px' }},model:{value:(_vm.formConfig),callback:function ($$v) {_vm.formConfig=$$v},expression:"formConfig"}})],1)],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }