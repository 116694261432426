/*
 * @Author: zhang·xiao
 * @Date: 2022-12-10 15:20:40
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2023-01-11 23:46:09
 * @Description: 描述文件功能
 */

import genSchema from '../genSchema.js';
import { selectOptionsSchema, fetchOptionsSchema } from '../SingleSelect';

const viewSchema = {
  // title: '多选类型',
  type: 'array',
  uniqueItems: true,
  items: {
    type: 'string'
  }
};

export default {
  viewSchema,
  propsSchema: genSchema({
    fetch: fetchOptionsSchema,
    options: {
      type: 'object',
      title: '选项',
      required: [],
      properties: {
        schemaOptions: {
          type: 'object',
          properties: {
            uniqueItems: {
              type: 'boolean',
              'ui:widget': 'hidden',
              default: true
            },
            items: {
              type: 'object',
              properties: {
                type: {
                  type: 'string',
                  default: 'string',
                  'ui:hidden': true,
                },
                ...selectOptionsSchema,
              }
            },
          }
        }
      }
    }
  }, 'array', true)
};
