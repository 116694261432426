<!--
 * @Author: mjzhu
 * @Date: 2022-06-08 17:37:26
 * @LastEditTime: 2024-01-22 17:20:57
 * @FilePath: \awx-ui\src\components\createTask\confirmName\index.vue
-->
<template>
  <div>
    <a-form-model ref="ruleForm" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules" :model="form" class="p12-32-10-32">
      <a-form-model-item v-if="detail && !detail.no_password"  prop="oldPassword"  label="输入旧密码">
        <a-input v-model="form.oldPassword" :type="isOldShow ? 'text' : 'password'"  placeholder="请输入旧密码" />
        <span style="position: absolute; cursor: pointer; right: 14px; top: -6px" @click.stop="isOldShow = !isOldShow">
          <svg-icon :icon-class="!isShow ? 'eye-invisible' : 'eye'" style="font-size: 18px"></svg-icon>
        </span>
      </a-form-model-item>
      <a-form-model-item  prop="password"  label="输入新密码">
        <a-input v-model="form.password" :type="isShow ? 'text' : 'password'" placeholder="请输入新密码" />
        <span style="position: absolute; cursor: pointer; right: 14px; top: -6px" @click.stop="isShow = !isShow">
          <svg-icon :icon-class="!isShow ? 'eye-invisible' : 'eye'" style="font-size: 18px"></svg-icon>
        </span>
      </a-form-model-item>
      <a-form-model-item  prop="confirmPassword"  label="确认新密码">
        <a-input :type="isConfirmShow ? 'text' : 'password'" v-model="form.confirmPassword"  placeholder="确认新密码"  />
        <span  style="position: absolute; cursor: pointer; right: 14px; top: -6px" @click.stop="isConfirmShow = !isConfirmShow">
          <svg-icon :icon-class="!isConfirmShow ? 'eye-invisible' : 'eye'" style="font-size: 18px"></svg-icon>
        </span>
      </a-form-model-item>
    </a-form-model>
    <div class="ant-modal-confirm-btns-new">
      <a-button style="margin-right: 10px" type="primary" :loading="loading" @click.stop="handleSubmit">确定</a-button>
      <a-button @click.stop="formCancel">取消</a-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    detail: Object,
    callBack: Function
  },
  data() {
    return {
      loading: false,
      isConfirmShow: false,
      isShow: false,
      isOldShow: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
      },
      rules: {
        oldPassword: [
          {
            required: false,
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            trigger: ["blur", "change"],
          },
        ],
        confirmPassword: [
          {
            required: true,
            trigger: ["blur", "change"],
          },
        ]
      },
      form: {
        taskName: "",
      },
    };
  },
  methods: {
    handleSubmit(e) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.form.password !== this.form.confirmPassword) return this.$message.error('两次密码输入不一致')
          this.loading = true
          setTimeout(() => {
            this.loading = false;
          }, 1000)
          this.$axiosPut(global.API.editPassword(this.detail?.id), {password: this.form.oldPassword, new_password: this.form.password})
          .then((res) => {
            this.loading = false;
            if ([200, 201, 204].includes(res.status)) {
              this.$message.success("更新成功", 2);
              this.callBack()
              setTimeout(() => {
                this.$destroyAll();
              }, 1000)
            } else {
              this.$message.error(rs.msg);
            }
          })
        }
      });
    },
    formCancel() {
      this.$destroyAll();
    },
  },
  mounted() {
  },
};
</script>
<style lang="less" scoped>
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}
</style>
