<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-08-03 17:14:47
 * @LastEditTime: 2023-09-11 20:45:40
 * @FilePath: \awx-ui\src\components\createTask\chooseTemplete\rightIndex.vue
-->
<template>
  <div class="template-list">
    <div class="system-template">
      <div v-if="systemTemplateList.length >0" class="flex-warp">
        <div v-for="(item, index) in systemTemplateList" :key="index" class="template-container">
          <TemplateItem :templateItem="item" :selectedTemplateId="selectedTemplateId" :templateType="'app'" @selectedTemplate="selectedTemplate" />
        </div>
      </div>
      <div v-else>
        <NoData />
      </div>
    </div>
  </div>
</template>
  <script>
import NoData from "../components/noData.vue";
import TemplateItem from "../components/templeteItem.vue";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  components: { NoData, TemplateItem },
  props: {
    systemTemplateList: Array,
    customTemplateList: Array,
  },
  data() {
    return {
      selectedTemplateId: "",
    };
  },
  watch: {
    'task.selectTemplateIndex': {
      handler (val) {
        if ((val > 0 && val) || val === 0) this.selectedTemplateId = this.task?.taskInfo?.taskTemplateDetail?.operations[val].application_id
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
  },
  beforeDestroy() {
    this.$EventBus.$off("clearTemplateId");
  },
  mounted () {
    this.$EventBus.$on("clearTemplateId", () => {
      this.selectedTemplateId = '';
    });
  },
  methods: {
    ...mapActions("task", [
      "setTaskInfo",
      "setShowStepsList",
      "setSelectTemplateIndex",
      "setCurrentOptIndex",
      "setCurrentPageIndex"
    ]),
    // 选择应用
    selectedTemplate(templateItem) {
      const obj = _.cloneDeep(templateItem)
      obj.application_id = obj.id
      let operationList = [];
      this.selectedTemplateId = obj.id;
      delete obj.id
      obj['application_name'] = obj.name
      delete obj.name
      if (
        this.task &&
        this.task.taskInfo &&
        this.task.taskInfo.taskTemplateDetail &&
        this.task.taskInfo.taskTemplateDetail.operations
      )
        operationList = _.cloneDeep(
          this.task.taskInfo.taskTemplateDetail.operations
        );
      let index = this.task.addTemplateIndex;
      if (!operationList[this.task.selectTemplateIndex].application_id && !operationList[this.task.selectTemplateIndex].id && operationList[this.task.selectTemplateIndex].application_name === '请选择一个应用') {
        index = this.task.selectTemplateIndex
        operationList[index] = obj
      } else if (this.task.addTemplateIndex < 0) {
        index = this.task.selectTemplateIndex
        operationList[index] = obj
      } else {
        operationList.splice(index, 0, obj);
      }
      let taskInfo = _.cloneDeep(this.task.taskInfo);
      taskInfo.taskTemplateDetail.operations = operationList;
      this.setTaskInfo(taskInfo);
      this.setSelectTemplateIndex(index);
      this.setShowStepsList(false);
      this.setCurrentOptIndex(1)
      this.setCurrentPageIndex(1)
      // 这个地方需要处理一下
    },
  },
};
</script>
  <style lang="less" scoped>
.template-list {
  // padding-right: 50px;
  .system-template,
  .custom-template {
    &-title {
      padding-bottom: 16px;
      font-size: 14px;
      color: #1d202d;
      letter-spacing: 0;
      font-weight: 600;
    }
  }
  .flex-warp {
    display: flex;
    flex-wrap: wrap;
    // width: 654px;
    width: 100%;
  }
  .template-container {
    margin: 8px 21px 8px 0;
    width: calc(33.33% - 14px);
    &:nth-child(3n) {
      margin: 8px 0 8px 0;
    }
  }
}
</style>