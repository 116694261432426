/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-07-07 18:09:23
 * @LastEditTime: 2023-12-15 17:15:14
 * @FilePath: \awx-ui\src\utils\dynamic-components.js
 */
const dynamicComponents = {
  '@/layouts/tabs/TabsView': () => import('@/layouts/tabs/TabsView'),
  '@/layouts/PageView': () => import('@/layouts/PageView'),
  '@/pages/templateShop/index': () => import('@/pages/templateShop/index'),
  '@/pages/templateStep/modifyStep': () => import('@/pages/templateStep/modifyStep'),
  '@/pages/templateStep/index': () => import('@/pages/templateStep/index'),
  '@/pages/securityCenter/user': () => import('@/pages/securityCenter/user'),
  '@/pages/securityCenter/master': () => import('@/pages/securityCenter/master'),


  '@/pages/overview/index': () => import('@/pages/overview/index'),
  '@/pages/pipeline/index': () => import('@/pages/pipeline/index'),
  '@/pages/taskManage/index': () => import('@/pages/taskManage/index'),
  '@/pages/pipeline/createFlowDesign': () => import('@/pages/pipeline/createFlowDesign'),
  '@/pages/dispatch/index': () => import('@/pages/dispatch/index'),
  '@/pages/appManage/index': () => import('@/pages/appManage/index'),
  '@/pages/appManage/editApp/index': () => import('@/pages/appManage/editApp/index'),
  '@/pages/authManage/index': () => import('@/pages/authManage/index'),
  
  '@/pages/fileManage': () => import('@/pages/fileManage'),
  '@/pages/newHostManage/index': () => import('@/pages/newHostManage/index'),
  '@/pages/groupManage/index': () => import('@/pages/groupManage/index'),

  '@/layouts/ThirdPartyPage/index': () => import('@/layouts/ThirdPartyPage/index'),
  '@/pages/management/myquota': () => import('@/pages/management/myquota'),
  '@/pages/management/index': () => import('@/pages/management/index'),

  '@/pages/operationConsole/index.vue': () => import('@/pages/operationConsole/index.vue'),
}
export default dynamicComponents