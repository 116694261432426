/*
 * @Author: mjzhu
 * @Date: 2022-05-24 10:22:10
 * @LastEditTime: 2023-11-20 17:21:11
 * @FilePath: \awx-ui\src\api\httpApi\template.js
 */
import paths from '@/api/baseUrl'// 后台服务地址

let path = paths.path().path

export default {
  getCatagories: path + '/catagories',
  getTemplateList: path + '/templates/task-template/',
  onlyTemplate: path + '/templates/',
  getTagsList: path + '/template_tags',
  createStep: path + '/templates/step-template/',
  getStepList: path + '/templates/step-template/',
  importStep: path + '/templates/step-import',
  getStepDetail: (id) => path + `/templates/step-template/${id}/`,
  updateStep: (id) => path + `/templates/step-template/${id}`,
  executeStep: (id) => path + `/templates/step-template/${id}/execute`,
  asyncExecuteStep: (id) => path + `/templates/step-template/${id}/execute_async`,
  copyStep: (id) => path + `/templates/step-template/${id}/copy`
}