

import genSchema from '../genSchema.js';

const viewSchema = {
  title: '是否选择(Select)',
  type: 'boolean',
  'ui:widget': 'SelectWidget'
};

export default {
  viewSchema,
  propsSchema: genSchema({
    options: {
      type: 'object',
      title: '选项',
      properties: {
        schemaOptions: {
          type: 'object',
          properties: {
            enumNames: {
              type: 'array',
              items: [{
                title: '选择文案',
                type: 'string',
                default: '是'
              }, {
                title: '非选择文案',
                type: 'string',
                default: '否'
              }]
            }
          }
        }
      }
    }
  })
};
