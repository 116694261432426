import { render, staticRenderFns } from "./Editor.vue?vue&type=template&id=2385cf7e&scoped=true"
import script from "./Editor.vue?vue&type=script&lang=js"
export * from "./Editor.vue?vue&type=script&lang=js"
import style0 from "./Editor.vue?vue&type=style&index=0&id=2385cf7e&prod&lang=less"
import style1 from "./Editor.vue?vue&type=style&index=1&id=2385cf7e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2385cf7e",
  null
  
)

export default component.exports