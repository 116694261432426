/*
 * @Author: mjzhu
 * @Date: 2022-05-24 10:22:10
 * @LastEditTime: 2024-05-21 09:34:03
 * @FilePath: \awx-ui\src\api\baseUrl.js
 */
export default {
  path() {
    let path = window.config.VUE_APP_API_BASE_URL
    let filePath = window.config.FILE_SYSTEM_URL
    if(process.env.NODE_ENV === 'development') {
      path = 'awx'
      filePath='uploadFile'
    }else {
      // path = 'awx'
    }
    console.log(path, filePath, '当前配置的路径')
    return {
      path,
      filePath
    }
  }
}
