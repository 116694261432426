/*
 * @Author: mjzhu
 * @Date: 2022-05-24 10:22:10
 * @LastEditTime: 2024-03-29 11:15:46
 * @FilePath: \awx-ui\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import {initRouter} from './router'
import './icons/index'
import './assets/less/index.less'
import Antd from 'ant-design-vue'
import Viser from 'viser-vue'
import '@/mock'
import '@/api'
import store from './store'
import {initI18n} from '@/utils/i18n'
import '@/utils/index.js'
import 'animate.css/source/animate.css'
import Plugins from '@/plugins'
import bootstrap from '@/bootstrap'
import 'moment/locale/zh-cn'
import '@/assets/fonts/font.css'
import VueDND from 'awe-dnd'
import "./assets/iconfont/font_3618935_h1q1rdzfhzh/iconfont.css"
import configMaster from './components/configMaster'
import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer)
import { message } from 'ant-design-vue';
import { Icon } from '@iconify/vue2'

// // 自定义的加载指示器组件
// const MyCustomLoadingIndicator = {
//   template: '<div>Custom Loading...</div>'
// };

// // 全局注册自定义加载指示器
// Spin.setDefaultIndicator({
//   indicator: MyCustomLoadingIndicator
// });

import Contextmenu from "@/plugins/vue-contextmenujs"
const router = initRouter(store.state.setting.asyncRoutes)
const i18n = initI18n('CN', 'US')
message.config({
  top: `53px`,
  duration: 2,
  maxCount: 3,
});
Vue.use(Antd)
Vue.config.productionTip = false
Vue.use(Viser)
Vue.use(Plugins)
Vue.use(VueDND)
Vue.component('configMaster',configMaster)
Vue.component('iconifyIcon',Icon)
Vue.use(Contextmenu);

Vue.prototype.$EventBus = new Vue()
bootstrap({router, store, i18n, message: Vue.prototype.$message})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
