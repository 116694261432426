<!--
 * @Author: zhang·xiao
 * @Date: 2022-11-29 15:38:40
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2022-11-29 15:38:54
 * @Description: 描述文件功能
-->
<template>
    <div class="fieldGroupWrap">
        <h3
            v-if="showTitle && trueTitle"
            class="fieldGroupWrap_title"
        >
            {{ trueTitle }}
        </h3>
        <p
            v-if="showDescription && description"
            class="fieldGroupWrap_des"
            v-html="description"
        >
        </p>
        <div class="fieldGroupWrap_box">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
  name: 'FieldGroupWrap',
  inject: ['genFormProvide'],
  props: {
    // 当前节点路径
    curNodePath: {
      type: String,
      default: ''
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showDescription: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  },
  computed: {
    trueTitle() {
      const title = this.title;
      if (title) {
        return title;
      }

      let fallbackLabel;
      if (typeof this.genFormProvide.fallbackLabel?.value === 'boolean') {
        fallbackLabel = this.genFormProvide.fallbackLabel?.value;
      } else {
        fallbackLabel = this.genFormProvide.fallbackLabel;
      }
      const backTitle = fallbackLabel && this.curNodePath.split('.').pop();
      if (backTitle !== `${Number(backTitle)}`) return backTitle;

      return '';
    }
  }
};
</script>
