/*
 * @Author: zhang·xiao
 * @Date: 2022-11-30 00:51:15
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2022-11-30 00:52:47
 * @Description: 描述文件功能
 */
export const fetchMaps = {
  'get': '$axiosGet',
  'post': '$axiosJsonPost',
  'put': '$axiosPut',
  'delete': '$axiosDelete',
  'patch': '$axiosPatch',
  'jsonpost': '$axiosJsonPost',
  'formdata': '$axiosFormData',
  'formpost': '$axiosPost',
} 