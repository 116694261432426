<!--
 * @Author: mjzhu
 * @Date: 2022-06-08 17:37:26
 * @LastEditTime: 2023-11-24 15:35:30
 * @FilePath: \awx-ui\src\components\createTask\confirmName\index.vue
-->
<template>
  <div>
    <a-form-model ref="ruleForm" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules" :model="form" class="p12-32-10-32">
      <a-form-model-item  prop="taskName"  :label="taskType === 'task' ? '任务名称' : '流程名称'">
        <a-input v-model="form.taskName" />
      </a-form-model-item>
    </a-form-model>
    <div class="ant-modal-confirm-btns-new">
      <a-button style="margin-right: 10px" type="primary" @click.stop="handleSubmit">确定</a-button>
      <a-button @click.stop="formCancel">取消</a-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    callBack: Function,
    taskName: String,
    taskType: String,
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
      },
      rules: {
        taskName: [
          {
            required: true,
            message: "请输入名称",
            trigger: ["blur", "change"],
          },
        ]
      },
      form: {
        taskName: "",
      },
    };
  },
  methods: {
    handleSubmit(e) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.callBack(this.form.taskName);
          this.$destroyAll();
        } else {
          return false;
        }
      });
    },
    formCancel() {
      this.$destroyAll();
    },
  },
  mounted() {
    this.form.taskName = this.taskName;
  },
};
</script>
<style lang="less" scoped>
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}
</style>
