<!--
 * @Author: zhang·xiao
 * @Date: 2022-12-21 20:06:31
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2023-06-04 21:48:46
 * @Description: 描述文件功能
-->
<template>
  <div :class="{
      'dark': curTheme === 'vs-dark',
      'light': curTheme === 'vs-light',
      'code-edit-field': true,
    }">
    <!-- <div class="code-edit-tool">
      <div class="language">
          <span>
            编辑器主题：
          </span>
          <el-select v-model="curTheme" @input="updateEdit({ theme: curTheme })">
            <el-option v-for="item in themes" :value="item.key" :key="item.key" :label="item.label" />
          </el-select>
        <span>
          语言：
        </span>
        <el-select v-model="curLanguage" @input="setLanguage">
          <el-option v-for="item in languages" :value="item.key" :key="item.key" :label="item.label" />
        </el-select>
      </div>
      <div class="theme">
      </div>
    </div> -->
    <div ref="editorRef" :style="{height: editorHeight}"></div>
  </div>
</template>
<script>
// 引用组件
import * as monaco from "monaco-editor";
import "monaco-editor/esm/vs/basic-languages/powershell/powershell.contribution";
import "monaco-editor/esm/vs/basic-languages/javascript/javascript.contribution";
import "monaco-editor/esm/vs/basic-languages/python/python.contribution";
import "monaco-editor/esm/vs/basic-languages/ruby/ruby.contribution";
import "monaco-editor/esm/vs/basic-languages/perl/perl.contribution";
import "monaco-editor/esm/vs/basic-languages/python/python.contribution";
export default {
  name: 'ElCodeEdit',
  props: {
    value: {
      type: String,
      default: '',
    },
    editorHeight: {
      type: String,
      default: '240px',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: "vs-dark",
    },
    language: {
      type: String,
      default: "powershell",
    },
  },
  data() {
    return {
      isInit: true,
      curCode: "",
      curLanguage: "",
      curTheme: "",
      themes:[
        {
          label: "dark",
          key: "vs-dark",
        },
        {
          label: "light",
          key: "vs-light",
        }
      ],
      languages: [
        {
          label: "Bash",
          key: "bash",
        },
        {
          label: "Shell",
          key: "sh",
        },
        {
          label: "Python2",
          key: "python2",
        },
        {
          label: "Python3",
          key: "python3",
        },
        {
          label: "Ruby",
          key: "ruby",
        },
        {
          label: "Perl",
          key: "perl",
        },
        {
          label: "PowerShell",
          key: "powershell",
        },
      ],
    };
  },
  watch: {
    language(newVal) {
      this.curLanguage = newVal;
      this.updateEdit({ language: newVal })
    },
    theme(newVal) {
      this.curTheme = newVal;
      this.updateEdit({ theme: newVal })
    },
    readOnly(newVal) {
      this.updateEdit({ readOnly: newVal })
    },
    value() {
      if (this.isInit) this.initValue()
    }
  },
  mounted() {
    this.init();
    this.initValue();
  },
  methods: {
    init() {
      this.curTheme = this.theme;
      this.curLanguage = this.language;
      // 使用 - 创建 monacoEditor 对象
      this.monacoEditor = monaco.editor.create(this.$refs.editorRef, {
        theme: this.curTheme, // 主题
        language: this.curLanguage, // 主题
        value: this.curCode, // 默认显示的值
        wordWrap: "on",
        folding: true, // 是否折叠
        foldingHighlight: true, // 折叠等高线
        foldingStrategy: "indentation", // 折叠方式  auto | indentation
        showFoldingControls: "always", // 是否一直显示折叠 always | mouseover
        disableLayerHinting: true, // 等宽优化
        emptySelectionClipboard: false, // 空选择剪切板
        selectionClipboard: false, // 选择剪切板
        automaticLayout: true, // 自动布局
        codeLens: true, // 代码镜头
        scrollBeyondLastLine: false, // 滚动完最后一行后再滚动一屏幕
        colorDecorators: true, // 颜色装饰器
        accessibilitySupport: "on", // 辅助功能支持  "auto" | "off" | "on"
        lineNumbers: "on", // 行号 取值： "on" | "off" | "relative" | "interval" | function
        lineNumbersMinChars: 5, // 行号最小字符   number
        enableSplitViewResizing: false,
        readOnly: this.readOnly, //是否只读  取值 true | false
        minimap: {
          enabled: true, // 不要小地图
        },
      });
      this.monacoEditor.getModel().onDidChangeContent(() => {
        if (!this.readOnly) {
          this.isInit = false;
          const value = this.monacoEditor.getValue();
          this.$emit("input", value);
          this.$emit("change", value);
        }
      });
    },
    initValue() {
      console.log('---initValue----')
      const code = this.value?.replace(/[\u001b]/g, '');
      if (code !== this.curCode) {
        this.curCode = code;
        this.monacoEditor?.setValue(this.curCode);
      }
    },
    updateEdit(params) {
      this.monacoEditor?.updateOptions(params);
    }
  },
};
</script>
<style lang="less" scoped>
  .code-edit-field {
    border-radius: 4px;
    padding: 6px 0;
    border: 1px solid #d9d9d9;
    .code-edit-tool {
      padding: 12px 24px;
      .theme {
        display: inline-block;
        width: 50%;
        text-align: right;
      }
      .language {
        display: inline-block;
        width: 50%;
        text-align: left;
      }
    }
    &.light {
      background-color: #fffffe;
    }
    &.dark {
      background-color: #1e1e1e;
      .code-edit-tool{
        span {
            color: #d4d4d4;
          }
          /deep/input.el-input__inner {
            background-color: #1e1e1e;
            color: #d4d4d4;
          }
      }
    }
  }
</style>
<style lang="less">
.dragArea .language {
  display: none !important;
}
</style>