/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-09-28 10:08:43
 * @LastEditTime: 2023-10-31 10:20:38
 * @FilePath: \awx-ui\src\store\modules\masterMd.js
 */

const state = {
	hasMaster: false
}

const getters = {
	hasMaster(state, getters, rootState) {
		try {
			const hasMaster = localStorage.getItem('hasMaster')
			state.hasMaster = hasMaster
		} catch (e) {
			console.error(e)
		}
		return state.hasMaster
	}
}

const mutations = {}

Object.keys(state).forEach((item) => {
	mutations[item] = (state, value) => {
		state[item] = value
	}
})

const actions = {
	setHasMaster({
		commit
	}, obj) {
		commit('hasMaster', obj)
		localStorage.setItem('hasMaster', obj)
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}