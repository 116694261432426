/*
 * @Author: mjzhu
 * @Date: 2022-05-24 10:22:10
 * @LastEditTime: 2024-01-22 09:34:54
 * @FilePath: \awx-ui\src\api\httpApi\user.js
 */
import paths from '@/api/baseUrl'// 后台服务地址

let path = paths.path().path

export default {
  login: path + '/login',
  smsLogin: path + '/sms/login',
  registry: path + '/registry',
  smsSend: path + '/sms/send',
  loginOut: path + '/logout',
  getUserList: path + '/users',// 用户列表
  addUser: path + '/users/',// 添加用户
  deleteUser: path + '/users',// 删除用户
  updateUser: path + '/users',// 更新用户
  getUserDetail: path + '/users', // 获取用户详情
  queryAllUser: path + '/api/user/all',
  getUserInfo: path + '/me',
  getCollectMenus: path + '/menus', //获取收藏菜单
  updateCollectMenus: path + '/menus', //更新收藏菜单
  getMemus: path + '/themenus',
  getQrCode: path + '/wx/mp/qrcode',
  getWxStatus: path + '/wx/status',
  wxLogin: path + '/wx/success',
  editPassword: (id) => path + `/users/${id}/pwd/`,
  userSmsSend:  path + '/users/sms/send',
  checkUserSms: path + '/users/sms/code/check'
}
