/*
 * @Author: zhang·xiao
 * @Date: 2022-11-26 19:24:42
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2022-12-26 13:47:48
 * @Description: 描述文件功能
 */

export const data6 = {
  "type": "object",
  "required": [],
  "properties": {
    "object_1669714897483x0": {
      "title": "基础信息",
      "type": "object",
      "required": [],
      "properties": {
        "string_1669728951659x0": {
          "title": "单选(Select)",
          "type": "string",
          "ui:widget": "SelectWidget",
          "enum": [
            "1",
            "2",
            "3"
          ],
          "enumNames": [
            "一",
            "二",
            "三"
          ],
          "ui:options": {
            "filterable": true,
            "allow-create": true,
            "default-first-option": true
          },
        },
        "array_1669728936252x0": {
          "type": "array",
          "uniqueItems": true,
          "title": "多选(受第一个值影响)",
          "ui:widget": "SelectWidget",
          "fetch": {
            api: '/get/api/cccc',
            type: 'get',
            params: {
              a: 'object_1669714897483x0.string_1669728951659x0',
            },
            transfromParams: (params) => {
              return params
            },
            transfromData: (data) => {
              return data
            }
          }
        },
        "array_1669728944774x0": {
          "type": "array",
          "uniqueItems": true,
          "title": "多选(受第一、二个值影响)",
          "ui:widget": "CheckboxesWidget",
          "items": {
            "enum": [
              "1",
              "2",
              "3"
            ],
            "enumNames": [
              "一",
              "二",
              "三"
            ]
          },
          "fetch": {
            api: '/get/api/cccc',
            type: 'post',
            needInit: true,
            params: {
              a: 'object_1669714897483x0.string_1669728951659x0',
              b: 'object_1669714897483x0.array_1669728936252x0',
              c: 'paramsProps.ddekkeje.deegee'
            }
          }
        }
      },
      "ui:order": [
        "string_1669728951659x0",
        "array_1669728936252x0",
        "array_1669728944774x0"
      ]
    },
    "array_1669713742758x0": {
      "title": "列表信息",
      "type": "array",
      "items": {
        "type": "object",
        "required": [],
        "properties": {
          "string_1669713767545x0": {
            "title": "单选(Radio)",
            "type": "string",
            "ui:widget": "RadioWidget",
            "enum": [
              "1",
              "2",
              "3"
            ],
            "enumNames": [
              "一",
              "二",
              "三"
            ]
          },
          "string_1669713769100x0": {
            "title": "单选(Select)",
            "type": "string",
            "ui:widget": "SelectWidget",
            "enum": [
              "1",
              "2",
              "3"
            ],
            "enumNames": [
              "一",
              "二",
              "三"
            ]
          },
          "array_1669713771289x0": {
            "type": "array",
            "uniqueItems": true,
            "items": {
              "type": "string",
              "enum": [
                "1",
                "2",
                "3"
              ],
              "enumNames": [
                "一",
                "二",
                "三"
              ]
            },
            "title": "多选(Checkbox)",
            "ui:widget": "CheckboxesWidget"
          }
        },
        "ui:order": [
          "string_1669713767545x0",
          "string_1669713769100x0",
          "array_1669713771289x0"
        ]
      },
      "uniqueItems": false
    },
    "boolean_1669713733308x0": {
      "title": "是否选择(Select)",
      "type": "boolean",
      "ui:widget": "SelectWidget",
      "enumNames": [
        "是",
        "否"
      ]
    },
    "boolean_1669713729496x0": {
      "title": "是否选择(Switch)",
      "type": "boolean"
    },
    "color_string_1669713727978x0": {
      "title": "颜色选择器",
      "type": "string",
      "format": "color"
    },
    "string_1669713736742x0": {
      "title": "单选(Radio)",
      "type": "string",
      "ui:widget": "RadioWidget",
      "enum": [
        "1",
        "2",
        "3"
      ],
      "enumNames": [
        "一",
        "二",
        "三"
      ]
    },
    "boolean_1669713735078x0": {
      "title": "是否选择(Radio)",
      "type": "boolean",
      "ui:widget": "RadioWidget",
      "enumNames": [
        "是",
        "否"
      ]
    },
    "boolean_1669713731461x0": {
      "title": "是否选择(Checkbox)",
      "type": "boolean",
      "ui:widget": "el-checkbox",
      "ui:options": {
        "label": "是否"
      }
    }
  },
  "ui:order": [
    "object_1669714897483x0",
    "array_1669713742758x0",
    "boolean_1669713733308x0",
    "boolean_1669713729496x0",
    "color_string_1669713727978x0",
    "string_1669713736742x0",
    "boolean_1669713735078x0",
    "boolean_1669713731461x0"
  ]
}

export const data1 = {
  schema: {
    type: 'object',
    required: [
      'name',
      'source'
    ],
    properties: {
      tgt_type: {
        title: '目标类型',
        type: 'string',
        description: '目标类型',
        tag: 'el-input',
        default: 'app',
        'ui:options': {
          placeholder: '请输入'
        }
      },
      tgt: {
        title: '目标',
        type: 'string',
        description: '具体的目标或者通配符',
        default: 'app',
        'ui:options': {
          placeholder: '请输入'
        }
      },
      name: {
        title: '目标文件',
        type: 'string',
        description: '目标文件的绝对路径',
        'ui:options': {
          placeholder: '请输入',
          activeText: '创建'

        }
      },
      source: {
        title: '源文件(source)',
        type: 'string',
        tag: 'el-select',
        description: '支持salt/https/http/ftp',
        'ui:options': {
          placeholder: '请输入'
        }
      },
      makedirs: {
        title: '创建目录(makedirs)',
        type: 'boolean',
        tag: 'el-select',
        'ui:options': {
          activeText: '创建',
          inactiveText: '不创建'
        },
        fetch: {
          api: '/get/api/cccc',
          type: 'POST',
          params: {
            a: 'tgt',
            b: 'source'
          }
        }
      },
      replace: {
        title: '替换(replace)',
        type: 'boolean',
        description: '文件不存在替换文件',
        tag: 'el-select',
        'ui:options': {
          activeText: '替换',
          inactiveText: '不替换'
        },
        fetch: {
          api: '/get/api/cccc',
          type: 'POST',
          params: {
            a: 'tgt_type',
            b: 'source'
          }
        }
      }
    },
    'ui:order': [
      'tgt',
      'tgt_type',
      'name',
      'source',
      'makedirs',
      'replace',
      'api.source'
    ]
  },
  uiSchema: {},
  formFooter: {
    show: false
  },
  formProps: {
    labelWidth: '200px'
  }
}

export const data2 = {
  type: 'object',
  required: [],
  properties: {
    object_1669646666972x0: {
      title: 'Object',
      type: 'object',
      required: [],
      properties: {
        array_1669646675772x0: {
          title: 'Array',
          type: 'array',
          items: {
            type: 'object',
            required: [],
            properties: {
              number_1669646680137x0: {
                title: '数字滑动',
                type: 'number',
                'ui:widget': 'ElSlider',
                multipleOf: 1
              },
              string_1669646677907x0: {
                title: '输入框',
                type: 'string',
                'ui:options': {
                  placeholder: '请输入'
                }
              }
            },
            'ui:order': [
              'number_1669646680137x0',
              'string_1669646677907x0'
            ]
          },
          uniqueItems: false
        },
        number_1669646703388x0: {
          title: '数字输入框',
          type: 'number',
          multipleOf: 1
        },
        color_string_1669646685727x0: {
          title: '颜色选择器',
          type: 'string',
          format: 'color'
        },
        boolean_1669646711366x0: {
          title: '是否选择(Select)',
          type: 'boolean',
          'ui:widget': 'SelectWidget',
          enumNames: [
            '是',
            '否'
          ]
        },
        boolean_1669646717847x0: {
          title: '是否选择(Radio)',
          type: 'boolean',
          'ui:widget': 'RadioWidget',
          enumNames: [
            '是',
            '否'
          ]
        },
        boolean_1669646709797x0: {
          title: '是否选择(Checkbox)',
          type: 'boolean',
          'ui:widget': 'el-checkbox',
          'ui:options': {
            label: '是否'
          }
        },
        boolean_1669646707529x0: {
          title: '是否选择(Switch)',
          type: 'boolean'
        },
        array_1669646730468x0: {
          type: 'array',
          uniqueItems: true,
          items: {
            type: 'string',
            enum: [
              '1',
              '2',
              '3'
            ],
            enumNames: [
              '一',
              '二',
              '三'
            ]
          },
          title: '多选(Select)',
          'ui:widget': 'SelectWidget'
        },
        string_1669646737652x0: {
          title: '单文件',
          type: 'string',
          'ui:widget': 'UploadWidget',
          'ui:options': {
            action: 'https://run.mocky.io/v3/518d7af7-204f-45ab-9628-a6e121dab8ca'
          }
        },
        array_1669646739943x0: {
          title: '多文件',
          type: 'array',
          items: {
            type: 'string'
          },
          'ui:widget': 'UploadWidget',
          'ui:options': {
            action: 'https://run.mocky.io/v3/518d7af7-204f-45ab-9628-a6e121dab8ca'
          }
        },
        date_array_1669646750778x0: {
          title: 'Date范围(字符串)',
          type: 'array',
          format: 'date',
          items: {
            type: 'string'
          },
          'ui:options': {
            startPlaceholder: '开始日期',
            endPlaceholder: '结束日期'
          }
        },
        date_array_1669646754542x0: {
          title: 'Date范围(字符串)',
          type: 'array',
          format: 'date',
          items: {
            type: 'string'
          },
          'ui:options': {
            startPlaceholder: '开始日期',
            endPlaceholder: '结束日期'
          }
        },
        'date-time_array_1669646756245x0': {
          title: 'DateTime范围(时间戳)',
          type: 'array',
          format: 'date-time',
          items: {
            type: 'number'
          },
          'ui:options': {
            startPlaceholder: '开始日期',
            endPlaceholder: '结束日期'
          }
        },
        date_array_1669646752709x0: {
          title: 'Date范围(时间戳)',
          type: 'array',
          format: 'date',
          items: {
            type: 'number'
          },
          'ui:options': {
            startPlaceholder: '开始日期',
            endPlaceholder: '结束日期'
          }
        },
        'date-time_array_1669646757714x0': {
          title: 'DateTime范围(字符串)',
          type: 'array',
          format: 'date-time',
          items: {
            type: 'string'
          },
          'ui:options': {
            startPlaceholder: '开始日期',
            endPlaceholder: '结束日期'
          }
        },
        time_string_1669646760601x0: {
          title: 'Time(字符串)',
          type: 'string',
          format: 'time',
          'ui:options': {
            placeholder: '请选择时间'
          }
        },
        date_number_1669646743927x0: {
          title: 'Date(时间戳)',
          type: 'number',
          format: 'date',
          'ui:options': {
            placeholder: '请选择日期'
          }
        },
        'date-time_string_1669646748601x0': {
          title: 'DateTime(字符串)',
          type: 'string',
          format: 'date-time',
          'ui:options': {
            placeholder: '请选择日期时间'
          }
        },
        date_string_1669646745503x0: {
          title: 'Date(字符串)',
          type: 'string',
          format: 'date',
          'ui:options': {
            placeholder: '请选择日期'
          }
        },
        array_1669646733590x0: {
          type: 'array',
          uniqueItems: true,
          items: {
            type: 'string',
            enum: [
              '1',
              '2',
              '3'
            ],
            enumNames: [
              '一',
              '二',
              '三'
            ]
          },
          title: '多选(Checkbox)',
          'ui:widget': 'CheckboxesWidget'
        },
        string_1669646728519x0: {
          title: '单选(Select)',
          type: 'string',
          'ui:widget': 'SelectWidget',
          enum: [
            '1',
            '2',
            '3'
          ],
          enumNames: [
            '一',
            '二',
            '三'
          ]
        },
        string_1669646726134x0: {
          title: '单选(Radio)',
          type: 'string',
          'ui:widget': 'RadioWidget',
          enum: [
            '1',
            '2',
            '3'
          ],
          enumNames: [
            '一',
            '二',
            '三'
          ]
        }
      },
      'ui:order': [
        'array_1669646675772x0',
        'number_1669646703388x0',
        'color_string_1669646685727x0',
        'boolean_1669646711366x0',
        'boolean_1669646717847x0',
        'boolean_1669646709797x0',
        'boolean_1669646707529x0',
        'array_1669646730468x0',
        'string_1669646737652x0',
        'array_1669646739943x0',
        'date_array_1669646750778x0',
        'date_array_1669646754542x0',
        'date-time_array_1669646756245x0',
        'date_array_1669646752709x0',
        'date-time_array_1669646757714x0',
        'time_string_1669646760601x0',
        'date_number_1669646743927x0',
        'date-time_string_1669646748601x0',
        'date_string_1669646745503x0',
        'array_1669646733590x0',
        'string_1669646728519x0',
        'string_1669646726134x0'
      ]
    }
  },
  'ui:order': [
    'object_1669646666972x0'
  ]
}

export const data3 = {
  "type": "object",
  "required": [
    "client",
    "tgt_type",
    "tgt",
    "source",
    "name"
  ],
  "properties": {
    "client": {
      "title": "客户端类型(client)",
      "type": "string",
      "ui:widget": "SelectWidget",
      "description": "client",
      "default": "local",
      "enum": [
        "local",
        "local_async",
        "local_subset",
        "ssh"
      ],
      "enumNames": [
        "代理模式",
        "代理模式异步",
        "代理模式子网",
        "SSH模式"
      ]
    },
    "test": {
      "type": "array",
      "uniqueItems": true,
      "items": {
        "type": "string",
      },
      "title": "测试动态选择组件(test)",
      "ui:widget": "SelectWidget",
      "fetch": {
        needInit: true,
        "api": "/awx/hosts",
        "type": "get",
        "params": {},
        "transfromParams": "function(params) {return params}",
        "transfromData": "function(data){ console.log(data); return data.results.map(item => { return {value: item.ip, label: item.name} })}"
      }
    },
    "tgt_type": {
      "title": "目标类型(tgt_type)",
      "type": "string",
      "ui:widget": "SelectWidget",
      "default": "list",
      "enum": [
        "list"
      ],
      "enumNames": [
        "枚举型(list)"
      ]
    },
    "tgt": {
      "title": "目标主机(tgt)",
      "type": "string",
      "description": "tgt",
      "default": "",
      "ui:options": {
        "placeholder": "请输入目标类型"
      }
    },
    "source": {
      "title": "源文件(source)",
      "type": "string",
      "description": "支持salt/https/http/ftp",
      "ui:options": {
        "placeholder": "请输入"
      }
    },
    "name": {
      "title": "目标文件(name)",
      "type": "string",
      "description": "目标文件的绝对路径",
      "ui:options": {
        "placeholder": "请输入"
      }
    },
    "makedirs": {
      "title": "创建目录(makedirs)",
      "type": "boolean",
      "ui:options": {
        "activeText": "创建",
        "inactiveText": "不创建"
      }
    },
    "replace": {
      "title": "替换(replace)",
      "type": "boolean",
      "description": "文件不存在替换文件",
      "ui:options": {
        "activeText": "替换",
        "inactiveText": "不替换"
      }
    }
  },
  "ui:order": [
    "client",
    "test",
    "tgt_type",
    "tgt",
    "source",
    "name",
    "makedirs",
    "replace"
  ]
}

export const data4 = {
  "type": "object",
  "required": [],
  "properties": {
    "color_string_1669710271902x0": {
      title: '语言(Select)',
      type: 'string',
      'ui:widget': 'SelectWidget',
      enum: [
        'powershell',
        'sh',
      ],
      enumNames: [
        'Powershell',
        'Shell',
      ]
    },
    "string_1669710265547x0": {
      title: '代码块',
      type: 'string',
      'ui:widget': 'ElCodeEdit',
      "ui:options": {
        readOnly: false,
        theme: 'vs-light',
        editorHeight: '240px',
      },
      dependOnProps: {
        language: 'color_string_1669710271902x0'
      },
    },
    "boolean_1669710274792x0": {
      "title": "是否选择(Select)",
      "type": "boolean",
      "ui:widget": "SelectWidget",
      "enumNames": [
        "是",
        "否"
      ]
    },
    "number_1669710266774x0": {
      "title": "数字滑动",
      "type": "number",
      "ui:widget": "ElSlider",
      "multipleOf": 1
    },
    "boolean_1669710269772x0": {
      "title": "是否选择(Switch)",
      "type": "boolean"
    },
    "number_1669710268106x0": {
      "title": "数字输入框",
      "type": "number",
      "multipleOf": 1
    }
  },
  "ui:order": [
    "string_1669710265547x0",
    "color_string_1669710271902x0",
    "boolean_1669710274792x0",
    "number_1669710266774x0",
    "boolean_1669710269772x0",
    "number_1669710268106x0"
  ]
}

export const data5 = {
  "type": "object",
  "required": [],
  "properties": {
    "array_1669713742758x0": {
      "title": "Array",
      "type": "array",
      "items": {
        "type": "object",
        "required": [],
        "properties": {
          "string_1669713767545x0": {
            "title": "单选(Radio)",
            "type": "string",
            "ui:widget": "RadioWidget",
            "enum": [
              "1",
              "2",
              "3"
            ],
            "enumNames": [
              "一",
              "二",
              "三"
            ]
          },
          "string_1669713769100x0": {
            "title": "单选(Select)",
            "type": "string",
            "ui:widget": "SelectWidget",
            "enum": [
              "1",
              "2",
              "3"
            ],
            "enumNames": [
              "一",
              "二",
              "三"
            ]
          },
          "array_1669713771289x0": {
            "type": "array",
            "uniqueItems": true,
            "items": {
              "type": "string",
              "enum": [
                "1",
                "2",
                "3"
              ],
              "enumNames": [
                "一",
                "二",
                "三"
              ]
            },
            "title": "多选(Checkbox)",
            "ui:widget": "CheckboxesWidget"
          }
        },
        "ui:order": [
          "string_1669713767545x0",
          "string_1669713769100x0",
          "array_1669713771289x0"
        ]
      },
      "uniqueItems": false
    },
    "string_1669713721999x0": {
      "title": "输入框",
      "type": "string",
      "ui:options": {
        "placeholder": "请输入"
      }
    },
    "number_1669713725636x0": {
      "title": "数字输入框",
      "type": "number",
      "multipleOf": 1
    },
    "number_1669713723987x0": {
      "title": "数字滑动",
      "type": "number",
      "ui:widget": "ElSlider",
      "multipleOf": 1
    },
    "boolean_1669713729496x0": {
      "title": "是否选择(Switch)",
      "type": "boolean"
    },
    "boolean_1669713731461x0": {
      "title": "是否选择(Checkbox)",
      "type": "boolean",
      "ui:widget": "el-checkbox",
      "ui:options": {
        "label": "是否"
      }
    },
    "color_string_1669713727978x0": {
      "title": "颜色选择器",
      "type": "string",
      "format": "color"
    },
    "boolean_1669713733308x0": {
      "title": "是否选择(Select)",
      "type": "boolean",
      "ui:widget": "SelectWidget",
      "enumNames": [
        "是",
        "否"
      ]
    },
    "string_1669713736742x0": {
      "title": "单选(Radio)",
      "type": "string",
      "ui:widget": "RadioWidget",
      "enum": [
        "1",
        "2",
        "3"
      ],
      "enumNames": [
        "一",
        "二",
        "三"
      ]
    },
    "boolean_1669713735078x0": {
      "title": "是否选择(Radio)",
      "type": "boolean",
      "ui:widget": "RadioWidget",
      "enumNames": [
        "是",
        "否"
      ]
    }
  },
  "ui:order": [
    "array_1669713742758x0",
    "string_1669713721999x0",
    "number_1669713725636x0",
    "number_1669713723987x0",
    "boolean_1669713729496x0",
    "boolean_1669713731461x0",
    "color_string_1669713727978x0",
    "boolean_1669713733308x0",
    "string_1669713736742x0",
    "boolean_1669713735078x0"
  ]
}