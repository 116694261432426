import { render, staticRenderFns } from "./Submenu.vue?vue&type=template&id=272b7468&scoped=true"
import script from "./Submenu.vue?vue&type=script&lang=js"
export * from "./Submenu.vue?vue&type=script&lang=js"
import style0 from "./Submenu.vue?vue&type=style&index=0&id=272b7468&prod&scoped=true&lang=css"
import style1 from "./Submenu.vue?vue&type=style&index=1&id=272b7468&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "272b7468",
  null
  
)

export default component.exports