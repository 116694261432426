<!--
 * @Author: zhang·xiao
 * @Date: 2022-11-29 13:37:16
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2022-11-29 22:34:35
 * @Description: 描述文件功能
-->
<template>
    <el-checkbox-group
        v-bind="$attrs"
        v-on="$listeners"
    >
        <el-checkbox
            v-for="(item, index) in enumOptions"
            :key="index"
            :label="item.value"
        >
            {{ item.label }}
        </el-checkbox>
    </el-checkbox-group>
</template>

<script>
export default {
  name: 'CheckboxesWidget',
  props: {
    enumOptions: {
      default: () => [],
      type: [Array]
    }
  }
};
</script>
