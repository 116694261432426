/*
 * @Author: zhang·xiao
 * @Date: 2022-11-29 13:33:57
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2023-06-04 22:10:19
 * @Description: 描述文件功能
 */
import ObjectField from './fields/ObjectField';

import StringField from './fields/StringField';

import NumberField from './fields/NumberField';

import IntegerField from './fields/IntegerField';

import BooleanField from './fields/BooleanField';

import ArrayField from './fields/ArrayField';

import AnyOfField from './fields/combiningSchemas/AnyOfField';

import OneOfField from './fields/combiningSchemas/OneOfField';

// 默认类型使用field映射关系
const FIELDS_MAPS = {
  array: ArrayField,
  boolean: BooleanField,
  integer: IntegerField,
  number: NumberField,
  object: ObjectField,
  string: StringField,
  null: {
    render() {
      return null;
    }
  },
  anyOf: AnyOfField,
  oneOf: OneOfField
};

export default FIELDS_MAPS;
