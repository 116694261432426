/*
 * @Author: zhang·xiao
 * @Date: 2022-11-29 13:37:16
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2022-11-29 14:12:44
 * @Description: 描述文件功能
 */

export default {
  name: 'TimePickerWidget',
  functional: true,
  render(h, context) {
    context.data.attrs = {
      'value-format': 'HH:mm:ss',
      ...context.data.attrs || {}
    };

    const oldInputCall = context.data.on.input;
    context.data.on = {
      ...context.data.on,
      input(val) {
        oldInputCall.apply(context.data.on, [val === null ? undefined : val]);
      }
    };

    return h('el-time-picker', context.data, context.children);
  }
};
