/*
 * @Author: mjzhu
 * @Date: 2022-05-24 10:22:10
 * @LastEditTime: 2024-05-31 10:35:58
 * @FilePath: \awx-ui\src\api\httpApi\index.js
 */
import project from './project'
import host from './host'
import services from './services'
import user from './user'
import system from './system'
import templeate from './templeate'
import task from './task'
import dispatch from './dispatch';
import management from "./management"
import notice from "./notice"
import fileDistribution from "./fileDistribution"
import target from "./target"
import master from "./master"
import template from "./template"
import app from "./app"
import uploadFile from "./uploadFile"
import fileManage from "./fileManage"


export default {...app,...master,...fileManage,...project, ...host, ...user, ...services, ...system, ...templeate, ...task, ...dispatch, ...management,...notice, ...target, ...fileDistribution, ...template, ...uploadFile}
