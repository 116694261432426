var render = function render(){var _vm=this,_c=_vm._self._c;return _c('draggable',_vm._b({ref:"draggable",class:['dragArea', 'formItemWrap'],attrs:{"list":_vm.childComponentList},on:{"change":_vm.handleDragChange}},'draggable',_vm.dragOptions,false),[_vm._l((_vm.childComponentList),function(item){return _c('div',{key:item.id,class:{
            draggableItem: true,
            w100: _vm.showNestedEditor(item),
            ['formItem']: true
        },style:(item.componentValue.baseValue.uiOptions.width ? {
            width: item.componentValue.baseValue.uiOptions.width,
            flexBasis: item.componentValue.baseValue.uiOptions.width
        } : {})},[_c('ViewComponentWrap',{attrs:{"form-data":_vm.formData,"editor-item":item,"drag-options":_vm.dragOptions,"show-nested-editor":_vm.showNestedEditor,"form-props":_vm.formProps},on:{"onOperate":_vm.handleItemOperate}})],1)}),_c('template',{slot:"footer"},[_vm._t("default")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }