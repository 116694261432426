<template>
    <div class="form-engine-container" v-loading="loading">
        <div class="container">
            <div
                :class="{
                    ['contentWrap']: true,
                    ['closeToolbar']: closeToolbar
                }"
            >
                <div :class="'toolBarWrap'">
                    <div :class="'toolsBar'">
                        <EditorToolBar
                            :drag-group="dragOptions.group"
                            :config-tools="configTools"
                            @onFilter="$message.error('该组件添加数目已达上限！')"
                        >
                        </EditorToolBar>
                    </div>
                    <span
                        :class="'leftCaret'"
                        @click="closeToolbar = !closeToolbar"
                    >
                        <i class="el-icon-caret-right"></i>
                    </span>
                </div>

                <div class="contentBox">
                    <EditorHeader default-active="4">
                      <el-button plain @click="handleSaveSchema">保存</el-button>
                      <el-button-group>
                        <el-button plain @click="handleImportSchema">导入Schema</el-button>
                        <el-button plain @click="handleExportSchema" >编辑schema</el-button>
                        <el-button plain @click="handlePreview" >预览展示</el-button>
                      </el-button-group>
                    </EditorHeader>
                    <el-form
                        class="genFromComponent"
                        v-bind="formProps"
                        :model="rootFormData"
                        :class="{
                            layoutColumn: !formProps.inline,
                            [`layoutColumn-${formProps.layoutColumn}`]: !formProps.inline,
                            formInlineFooter: formProps.inlineFooter,
                            formInline: formProps.inline,
                        }"
                    >
                        <NestedEditor
                            :child-component-list="componentList"
                            :drag-options="dragOptions"
                            :form-data="rootFormData"
                            :form-props="formProps"
                        >
                            <el-form-item
                                v-if="componentList.length > 0 && formFooter.show"
                                :style="{
                                    display: formProps.inline && formProps.inlineFooter ? 'inline-block' : 'block'
                                }"
                                class="formFooter_item w100 formFooter_item-editor"
                            >
                                <el-button @click="$emit('onCancel')">{{ formFooter.cancelBtn }}</el-button>
                                <el-button
                                    type="primary"
                                    @click="$emit('onSubmit')"
                                >
                                    {{ formFooter.okBtn }}
                                </el-button>
                            </el-form-item>
                        </NestedEditor>
                    </el-form>
                    <div v-if="componentList.length === 0" class="tipBox">
                        <p>拖拽左侧栏的组件进行添加</p>
                    </div>
                </div>
                <div :class="'rightForm'">
                    <el-tabs v-model="activeName">
                        <el-tab-pane
                            v-if="curEditorItem"
                            label="组件配置"
                            name="compConfig"
                        >
                            <VueJsonFrom
                                :class="'configForm'"
                                v-model="curEditorItem.componentValue"
                                :schema="curEditorItem.componentPack.propsSchema"
                                :form-props="{ labelPosition: 'top', labelWidth: '120px' }"
                                :form-footer="{ show: false }"
                            >
                            </VueJsonFrom>
                        </el-tab-pane>
                        <el-tab-pane
                            label="表单配置"
                            name="formConfig"
                        >
                            <VueJsonFrom
                                v-model="formConfig"
                                :class="'configForm'"
                                :schema="FormConfSchema"
                                :form-footer="{ show: false }"
                                :form-props="{ labelPosition: 'top', labelWidth: '120px' }"
                            >
                            </VueJsonFrom>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueJsonFrom from "@formEngine/config/index"
import componentWithDialog from './components/component-with-dialog/index.js';
import EditorHeader from './components/EditorHeader.vue';
import { openNewPage } from '@formEngine/utils/url.js';
import FormConfSchema from './viewComponents/FormConf';
import EditorToolBar from './EditorToolBar.vue';
import ExportSchemaView from './components/ExportSchemaView.vue';
import ImportSchemaView from './components/ImportSchemaView.vue';
import { deepFreeze, copyFormSchema } from './common/utils';
import configTools from './config/tools';
import NestedEditor from './components/NestedEditor';
import { componentList2JsonSchema, formatFormLabelWidth, saveEditorSchema } from './common/editorData';
import jsonSchema2ComponentList from './common/jsonSchema2ComponentList';

deepFreeze(configTools);
let oldSchemeCache = [];
export default {
  name: 'Editor',
  components: {
    VueJsonFrom,
    EditorToolBar,
    EditorHeader,
    NestedEditor
  },
  provide() {
    return {
      genFormProvide: { fallbackLabel: true }
    };
  },
  data() {
    return {
      closeToolbar: false,
      loading: false,
      configTools,
      rootFormData: {},
      schemaConfig: null,
      curEditorItem: null, // 选中的formItem
      componentList: [],
      FormConfSchema,
      formConfig: {
        formProps: {
          labelWidth: 40
        }
      },
      activeName: 'formConfig'
    };
  },
  computed: {
    formProps() {
      if (!this.formConfig.formProps) return {};
      return {
        ...this.formConfig.formProps,
        labelWidth: formatFormLabelWidth(this.formConfig.formProps.labelWidth)
      };
    },
    formFooter() {
      return this.formConfig.formFooter || {};
    },
    dragOptions() {
      return {
        animation: 300,
        group: 'listComponentsGroup',
        disabled: false,
        ghostClass: 'ghostItem',
        filter: '.disabled',
        draggable: '.draggableItem',
        tag: 'div',
        swapThreshold: 0.3,
        // forceFallback: true
        // fallbackTolerance: 0
      };
    },
  },
  watch: {
    componentList: {
      deep: true,
      immediate: false,
      handler(v) {
        this._schemaChange();
      },
    },
    formConfig: {
      deep: true,
      immediate: false,
      handler(v) {
        this._schemaChange();
      },
    }
  },
  mounted() {
    window.document.body.classList.add('page-decorate-design');
  },
  destroyed() {
    window.document.body.classList.remove('page-decorate-design');
  },
  created() {
    this.$on('onSetCurEditorItem', ({ editorItem }) => {
      this.activeName = editorItem ? 'compConfig' : 'formConfig';
      this.curEditorItem = editorItem;
    });
    this._schemaChange = _.debounce(() => {
      const value = this.getExportCode();
      this.$emit('change', value)
    }, 200)
  },
  methods: {
    getSchemaJson() {
      let values = { schema: '', model: '' };
      try {
        values = {
          schema: this.schemaConfig || JSON.stringify(this.getExportCode(), null, 2),
          model: JSON.stringify(this.rootFormData, null, 2)
        };
      } catch (error) {
        console.error(error)
      }
      return values;
    },
    getExportCode() {
      const { formFooter, formProps } = this;
      const defaultConfig = {
        formFooter: {
          show: true,
          okBtn: '保存',
          cancelBtn: '取消'
        },
        formProps: {
          inline: false,
          inlineFooter: false,
          layoutColumn: 1,
          labelPosition: 'top',
        }
      };

      // 不做深度
      const filter = (obj, defaultObj) => Object.keys(obj).reduce((pre, cur) => {
        if (!(obj[cur] === defaultObj[cur])) {
          pre[cur] = obj[cur];
        }
        return pre;
      }, {});

      return {
        schema: componentList2JsonSchema(this.componentList),
        uiSchema: {},
        formFooter: filter(formFooter, defaultConfig.formFooter),
        formProps: filter(formProps, defaultConfig.formProps)
      };
    },
    handleSaveSchema() {
      const instance = componentWithDialog({
        VueComponent: VueJsonFrom,
        dialogProps: {
          title: '保存数据',
          width: '1000px',
          customClass: 'save-dailog'
        },
        componentProps: {
          value: this.getSchemaJson(),
          ...saveEditorSchema
        },
        componentListeners: {
          'on-cancel': () => {
            instance.close();
          },
          'on-submit': (data) => {
            this.$emit('save', data)
            instance.close();
          }
        }
      });
    },
    handlePreview() {
      const props = this.getExportCode();
      const instance = componentWithDialog({
        VueComponent: VueJsonFrom,
        dialogProps: {
          title: '预览展示',
          width: '1000px',
          customClass: 'preview-dailog'
        },
        componentProps: {
          value: {},
          ...props
        },
        componentListeners: {
          toDemo: () => {
            this.handleToDemo();
          },
          'on-cancel': () => {
            instance.close();
          },
        }
      });
    },
    handleImportSchema() {
      const instance = componentWithDialog({
        VueComponent: ImportSchemaView,
        dialogProps: {
          title: '导入Schema',
          width: '1000px',
          customClass: 'import-dailog'
        },
        componentListeners: {
          onImport: (code) => this.importSchema(code, () => instance?.close())
        }
      });
    },
    importSchema(code, callback) {
      try {
        const data = jsonSchema2ComponentList(code, this.configTools);
        if (!data) return this.$message.warning('请先输入导入Schema');

        const { errorNode, componentList, formConfig } = data;
        this.componentList = componentList;
        if (formConfig.formProps) Object.assign(this.formConfig.formProps, formConfig.formProps);
        if (formConfig.formFooter) Object.assign(this.formConfig.formFooter, formConfig.formFooter);
        callback?.();
        // 存在导入失败的部分节点
        if (errorNode.length > 0 && Array.isArray(errorNode)) {
          return this.$message.error({
            content: [
              this.$createElement('span',{}, '以下节点存在schame格式错误！导入部分'),
              this.$createElement('br',),
              ...errorNode.map(item => this.$createElement('pre', {
                style: {
                  textAlign: 'left'
                }
              }, JSON.stringify(item, null, 4))),
            ],
            duration: 5
          });
        }
        return undefined;
      } catch (e) {
        this.$message.error('导入失败，详细查看控制台');
        throw e;
      }
    },
    handleExportSchema() {
      const instance = componentWithDialog({
        VueComponent: ExportSchemaView,
        dialogProps: {
          title: '编辑schema',
          width: '1000px',
          customClass: 'export-dailog'
        },
        componentProps: {
          genCode: this.getExportCode(),
        },
        componentListeners: {
          saveCode: (code) => {
            try {
              this.schemaConfig = code;
              const data = jsonSchema2ComponentList(code, this.configTools);
              if (!data) return this.$message.warning('Schema 存在错误');
              const { errorNode, componentList, formConfig } = data;
              this.componentList = componentList;
              if (formConfig.formProps) Object.assign(this.formConfig.formProps, formConfig.formProps);
              if (formConfig.formFooter) Object.assign(this.formConfig.formFooter, formConfig.formFooter);
              instance.close();
              // 存在导入失败的部分节点
              if (errorNode.length > 0 && Array.isArray(errorNode)) {
                return this.$message.error({
                  content: [
                    this.$createElement('span',{}, '以下节点存在schame格式错误！保存部分'),
                    this.$createElement('br',),
                    ...errorNode.map(item => this.$createElement('pre', {
                      style: {
                        textAlign: 'left'
                      }
                    }, JSON.stringify(item, null, 4))),
                  ],
                  duration: 5
                });
              }
              return undefined;
            } catch (error) {
              this.$message.error('Schema 语法结构存在错误，无法保存');
            }
            instance.close();
          },
          toDemo: () => {
            this.handleToDemo();
          }
        }
      });
    },
    handleToDemo() {
      const codeObj = this.getExportCode();
      const urlQueryString = Object.keys(codeObj).reduce((pre, cur) => {
        pre.push(`${cur}=${encodeURIComponent(JSON.stringify(codeObj[cur]))}`);
        return pre;
      }, []).join('&');

      const link = `/index.html#/demo?type=Test&${urlQueryString}`;
      openNewPage(link, '_specialViewForm');
    }
  }
};
</script>

<style lang="less">
    .layoutColumn-2 {
      .dragArea .formItem {
          display: inline-block;
          width: 50%;
          flex-basis: 50%;
      }
    }
    .layoutColumn-3 {
      .dragArea .formItem {
          display: inline-block;
          width: 33.333%;
          flex-basis: 33.333%;
      }
    }
    body.page-decorate-design{
        overflow: hidden;
    }
    .flip-list-move {
        transition: transform 0.3s;
    }
    .no-move {
        transition: transform 0s;
    }
    .save-dailog,
    .import-dailog,
    .export-dailog,
    .preview-dailog {
      .el-dialog__body {
        max-height: 70vh;
        overflow-y: auto;
      }
    }
    .save-dailog .el-dialog__body .genFromComponent .formFooter_item {
      border-top: none
    }
</style>
<style lang="less" scoped>
    .form-engine-container {
      height: 100%;
      min-width: 70vw;
      min-height: 50vh;
    }
    .container {
        position: relative;
        box-sizing: border-box;
        height: 100%;
        transition: 0.2s ease;
    }
    .hasTools {
        padding-left: 260px;
        /deep/.el-icon-caret-right {
            transform: rotate(180deg);
        }
    }
    .toolBarWrap, .rightForm{
        position: absolute;
        top: 0;
        bottom: 0;
        background: #FFF;
        box-shadow: 0 0 0 1px rgba(171 171 171,0.3);
        z-index: 2;
    }

    .rightForm, .toolsBar {
        overflow: auto;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
    .contentBox {
      position: relative;
      padding: 0;
      height: 100%;
      .genFromComponent {
        height: calc(100% - 46px)
      }
      .dragArea {
        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
        &::-webkit-scrollbar-track {
            background-color: var(--background-color-base);
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background-color: var(--color-text-placeholder);
        }
      }
    }
    .toolBarWrap {
        padding-top: 10px;
        width: 260px;
        left: 0;
        overflow: visible;
    }
    .toolsBar {
        height: 100%;
    }
    .leftCaret {
        cursor: pointer;
        position: absolute;
        display: flex;
        width: 18px;
        height: 38px;
        align-items: center;
        justify-content: center;
        top: 2px;
        right: 0;
        background: #FFFFFF;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        border-radius: 2px 0 0 2px;
        /deep/.el-icon-caret-right {
            transition: all .3s ease;
            transform: rotate(180deg);
        }
        &:hover {
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
            opacity: 1;
        }
    }
    .rightForm {
        box-sizing: border-box;
        padding: 10px;
        right: 0;
        width: 380px;
        .genFromComponent {
          border: none;
          background: #fff !important;
        }

    }
    .configForm {
        padding: 10px;
        &>h3 {
            font-size: 15px;
            font-weight: bold;
        }
    }

    /*content area*/
    .contentWrap {
        position: relative;
        overflow: auto;
        height: 100%;
        padding-left: 260px;
        padding-right: 380px;
    }
    .closeToolbar {
        padding-left: 0;
        .toolBarWrap {
            left: -260px;
            .leftCaret {
                right: -18px;
            }
            /deep/.el-icon-caret-right {
                transform: rotate(0);
            }
        }
    }
    .tipBox{
        pointer-events: none;
        top: 20px;
        position: absolute;
        left: 0;
        width: 100%;
        text-align: center;
        margin: 30vh 0;
        p {
            margin: 20px 0;
            font-size: 14px;
        }
    }
</style>
