<!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2023-12-25 14:52:09
 * @FilePath: \awx-ui\src\components\createTask\deploystep\operationBox.vue
-->
<template>
  <div class="operation-box">
    <div class="operation-box-container">
      <div class="lf">
        <OperationList :taskType="taskType" />
      </div>
      <div class="operation-box__boundary-line"></div>
      <div class="center" v-show="toolSelected === 'steps'">
        <div class="chooes-steps">
          <a-steps :current="currentStepsIndex" @change="changeCurrent">
            <a-step :class="['show-icon']">
              <template slot="title">
                <span>选择应用</span>
              </template>
            </a-step>
            <a-step :class="[task.currentOptIndex > 1 ?'show-icon': '']">
              <template slot="title">
                <span>选择操作</span>
              </template>
            </a-step>
            <a-step :class="[task.currentOptIndex > 2 ?'show-icon': '']">
              <template slot="title">
                <span>配置授权</span>
              </template>
            </a-step>
            <a-step :class="[task.currentOptIndex > 3 ?'show-icon':'']">
              <template slot="title">
                <span>配置参数</span>
              </template>
            </a-step>
        </a-steps>
        </div>
        <RightApp v-show="currentStepsIndex === 0" />
        <!-- 选择操作 -->
        <SelectOpt v-if="currentStepsIndex === 1" />
        <AuthConfig v-if="currentStepsIndex === 2" />
        <!-- 配置参数 -->
        <common-form ref="commonFormRef" v-if="currentStepsIndex > 2" />
      </div>
      <div class="center" v-show="toolSelected === 'params'">
        <paramSetting />
      </div>
      <div class="center" v-show="toolSelected === 'notice'">
        <notice />
      </div>
      <div class="center" v-show="toolSelected === 'log'">
        <Log class="mgr10" type="task" :taskId="taskId" editorHeight="650px" :noTips='true' />
      </div>
    </div>
    <div class="left-tabs">
      <div :class="['left-tabs-cate', toolSelected === 'steps' && 'left-tabs-cate-selected']" @click="selectTool('steps')">
        <div>
          <svg-icon class="left-tabs-cate-icon" :class="['left-tabs-cate-icon', toolSelected === 'steps' && 'left-tabs-cate-icon-selected']" icon-class="steps"></svg-icon>
        </div>
        <div :class="['left-tabs-cate-name', toolSelected === 'steps' && 'left-tabs-cate-name-selected']">步骤</div>
      </div>
      <div :class="['left-tabs-cate', toolSelected === 'params' && 'left-tabs-cate-selected']" @click="selectTool('params')">
        <div>
          <svg-icon class="left-tabs-cate-icon" :class="['left-tabs-cate-icon', toolSelected === 'params' && 'left-tabs-cate-icon-selected']" icon-class="task-params"></svg-icon>
        </div>
        <div :class="['left-tabs-cate-name', toolSelected === 'params' && 'left-tabs-cate-name-selected']">参数</div>
      </div>
      <div :class="['left-tabs-cate', toolSelected === 'notice' && 'left-tabs-cate-selected']" @click="selectTool('notice')">
        <div>
          <svg-icon class="left-tabs-cate-icon" :class="['left-tabs-cate-icon', toolSelected === 'notice' && 'left-tabs-cate-icon-selected']" icon-class="task-notice"></svg-icon>
        </div>
        <div :class="['left-tabs-cate-name', toolSelected === 'notice' && 'left-tabs-cate-name-selected']">通知</div>
      </div>
      <div :class="['left-tabs-cate', toolSelected === 'log' && 'left-tabs-cate-selected']" @click="selectTool('log')">
        <div>
          <svg-icon class="left-tabs-cate-icon" :class="['left-tabs-cate-icon', toolSelected === 'log' && 'left-tabs-cate-icon-selected']" icon-class="flow-log"></svg-icon>
        </div>
        <div :class="['left-tabs-cate-name', toolSelected === 'log' && 'left-tabs-cate-name-selected']">日志</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import CommonForm from "../components/commonForm.vue";
import paramSetting from "./paramSetting/paramContainer.vue";
import notice from "./notice/index.vue";
import RightApp from '../chooseTemplete/rightApp.vue'
import OperationList from "./operationList.vue";
import SelectOpt from './selectOpt.vue';
import { validateStep } from './validate'
import Log from '@/components/createTask/components/log.vue'
import AuthConfig from './authConfig/index.vue'


export default {
  name: "operationBox",
  props: {
    taskType: {
      type: String,
      default: "task",
    },
  },
  provide() {
      return {
        selectTool: this.selectTool
      };
    },
  components: { RightApp, OperationList, CommonForm, SelectOpt, paramSetting, notice, Log, AuthConfig},
  watch: {
    taskId: {
      handler(val) {
        if (val) {
          this.eidtFlag = true;
        }
      },
      immediate: true,
    },
    // 从模板那边进来的 隐藏右边的模板
    taskType: {
      handler(val) {
        if (val === "template") {
          this.eidtFlag = true;
        }
      },
      immediate: true,
    },
    'task.currentOptIndex': {
      handler(val) {
        // this.setCurrentPageIndex(val)
      },
      // immediate: true
    }
  },
  inject: ["handleCancel"],
  data() {
    return {
      toolSelected: 'steps',
      stepsType: "newcreatetemplate",
      interval: 0,
      currentSteps: 1,
      eidtFlag: false,
      // currentStepsIndex: -1,
    };
  },
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    taskId() {
      const taskId = this.task.taskInfo.task_id || "";
      return taskId;
    },
    currentTaskTYpe () {
      const obj = this.task?.taskInfo?.taskTemplateDetail?.operations[this.task.selectTemplateIndex]
      return this.task.selectTemplateIndex === 0 ? obj?.type : 'normal'
    },
    currentStepsIndex () {
      let currentStepsIndex = 0
      if ((this.task.selectTemplateIndex > 0 && this.task.selectTemplateIndex) || this.task.selectTemplateIndex === 0) {
        currentStepsIndex =  this.task?.taskInfo?.taskTemplateDetail?.operations[this.task.selectTemplateIndex]['currentStepsIndex'] || 0;
      }
      return currentStepsIndex
    }
  },
  methods: {
    ...mapActions("task", [
      "setTaskInfo",
      "setShowStepsList",
      "setSelectTemplateIndex",
      "setCurrentOptIndex",
      "setAddTemplateIndex",
      "setCurrentPageIndex"
    ]),
    selectTool (type) {
      this.toolSelected = type
    },
    changeCurrent (val) {
      if (this.task.currentOptIndex === 0) {
        if (val > 0) return false
      }
      if (this.task.currentOptIndex === 1) {
        if (val > 1) return false
      }
      if (this.task.currentOptIndex === 2) {
        if (val > 2) return false
      }
      // if (val === 2) val = 3
      this.setCurrentPageIndex(val)
      const step = this.task.taskInfo?.taskTemplateDetail?.operations[this.task.selectTemplateIndex]
      const flag = validateStep(step)
      if (flag && val === 3) {
          this.setCurrentPageIndex(4)
      }
      // 操作当个步骤的时候将添加的index清掉
      this.setAddTemplateIndex(-1)
      // this.setCurrentOptIndex(val)
    },
    savePublish() {},
    curdTaskTemplate() {
      // 选择的空白模板
      if (
        this.task.taskInfo.template_id === "_blank" ||
        !this.task.taskInfo.template_id
      ) {
        // console.log('ssss')
      } else {
        // 这个是查询新的模板
        this.spinning = true;
        this.$axiosGet(
          global.API.curdTaskTemplate + this.task.taskInfo.template_id + "/",
          {}
        ).then((res) => {
          this.spinning = false;
          if (
            [200, 201, 204, 202].includes(res.status) &&
            res.data &&
            res.data.operations &&
            res.data.operations.length > 0
          ) {
            let taskTemplateDetail = res.data
            taskTemplateDetail.operations.forEach(item => {
              if (item.application_id) {
                item['currentStepsIndex'] = 1
              }
              if (item.id) {
                item['currentStepsIndex'] = 2
              }
              if (item.application_id && item.id) {
                item['currentStepsIndex'] = this.task.taskInfo?.type !== '1' ? 4 : 2
              }
            })
            this.taskTemplateDetail = taskTemplateDetail;
            this.setTaskInfo({
              ...this.task.taskInfo,
              taskTemplateDetail: taskTemplateDetail,
            });
                // 如果有应用id 那就到选择操作的界面
            if (this.task.taskInfo?.taskTemplateDetail?.operations[0]?.application_id) {
              this.setCurrentOptIndex(1)
            } else {
              this.setCurrentOptIndex(0)
            }
            // 如果有操作也就是有步骤id 那么就展示配置参数的页面
            if (this.task.taskInfo?.taskTemplateDetail?.operations[0]?.id) {
              this.setCurrentOptIndex(2)
            }
            if (this.task.taskInfo?.taskTemplateDetail?.operations[0]?.application_id && this.task.taskInfo?.taskTemplateDetail?.operations[0]?.id) {
              if (this.task.taskInfo?.type !== '1') {
                this.setCurrentOptIndex(4)
              } else {
                this.setCurrentOptIndex(2)
              }
            }
            this.setShowStepsList(false);
            this.setSelectTemplateIndex(0);
          } else {
            this.setTaskInfo({
              ...this.task.taskInfo,
              taskTemplateDetail: {},
            });
            this.setCurrentOptIndex(0)
            this.setShowStepsList(true);
            this.setSelectTemplateIndex(0);
          }
        });
      }
    },
  },
  mounted() {
    if (!this.task.taskInfo.task_id && this.taskType !== "template") {
      this.curdTaskTemplate();
    } else {
      // 编辑任务的时候
      // 如果有应用id 那就到选择操作的界面
      if (this.task.taskInfo?.taskTemplateDetail?.operations[0]?.application_id) {
        this.setCurrentOptIndex(1)
      } else {
        this.setCurrentOptIndex(0)
      }
      // 如果有操作也就是有步骤id 那么就展示配置参数的页面
      if (this.task.taskInfo?.taskTemplateDetail?.operations[0]?.id) {
        this.setCurrentOptIndex(2)
      }
      if (this.task.taskInfo?.taskTemplateDetail?.operations[0]?.application_id && this.task.taskInfo?.taskTemplateDetail?.operations[0]?.id) {
        if (this.task.taskInfo?.type !== '1') {
          this.setCurrentOptIndex(4)
        } else {
          this.setCurrentOptIndex(2)
        }
      }
      this.setShowStepsList(false);
      this.setSelectTemplateIndex(0);
    }
  },
};
</script>
<style lang="less" scoped>
.operation-box {
  background: rgb(251, 253, 254) ;
  border-radius: 4px;
  display: flex;
  padding: 24px 24px 0 24px;
  .left-tabs {
    .left-tabs-cate {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 52px;
        height: 90px;
        background: rgba(247,248,250,0.50);
        border: 1px solid rgba(207,213,222,0.7);
        border-left: none;
        border-bottom: none;
        cursor: pointer;
        &-name {
          font-size: 13px;
          color: #34343C;
          letter-spacing: 0;
          font-weight: 400;
          margin-top: 0px;
        }
        &-name-selected {
          // color: @primary-color;
          font-weight: 550;
        }
        &-icon {
          font-size: 23px;
          margin-bottom: 5px;
          color: #9DA7BF;
          &-selected {
            color: #3974F4;
          }
        }
        &-selected {
          background: #fff;
          position: relative;
          width: 53px;
          border-right: 3px solid @primary-color;
          right: 1px;
        }
        &:first-child {
          border-radius: 0px 4px 0px 0px;
          // border-bottom: none;
        }
        &:last-child {
          border-radius: 0px 0px 4px 0px;
          border-bottom: 1px solid rgba(207,213,222,0.7);
        }
      }
  }
  .operation-box-container {
    display: flex;
    height: 680px;
    width: 100%;
    background: rgba(255,255,255,0.15);
    border: 1px solid rgba(209,215,225,1);
    box-shadow: 0px 2px 8px 0px rgba(227,234,250,0.7);
    border-radius: 4px 0px 4px 4px;
  }
  .lf {
    width: 358px;
    padding: 10px 0px 26px 36px;
    background-image: url('../../../assets/img/bg-point.png');
    background-size: cover;
    /deep/
      .ant-steps-vertical
      > .ant-steps-item
      > .ant-steps-item-container
      > .ant-steps-item-tail {
      padding: 23px 0 0;
      left: 11px;
    }
    /deep/ .ant-steps-item {
      height: 80px;
      .ant-steps-item-title {
        line-height: 23px;
        font-size: 14px;
        color: #666666;
        letter-spacing: 0.39px;
        font-weight: 500;
      }
    }
    // 等待还未到的
    /deep/ .ant-steps-item-wait {
      .ant-steps-item-icon {
        .ant-steps-icon {
          color: #666666;
          letter-spacing: 0;
          font-weight: 400;
        }
      }
    }
    /deep/ .ant-steps-item-active {
      .ant-steps-item-title {
        line-height: 23px;
        font-size: 14px;
        color: #111111;
        letter-spacing: 0.39px;
        font-weight: 600;
      }
    }
    // 灰色的线
    /deep/
      .ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-tail::after {
      background: #d1d4d9;
    }
  }
  &__boundary-line {
    border-right: 1px solid #CFD5DE !important;
  }
  .center {
    flex: 1;
    padding: 20px 0px 20px 65px;
    .chooes-steps {
      margin-right: 55px;
      /deep/ .ant-steps-item-icon {
        position: relative;
        top: 4px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        // border-color: rgb(209,212,217);
        font-size: 12px;
        margin-right: 10px;
      }
    }
    .task-steps {
      font-size: 20px;
      position: relative;
      top: -3px;
    }

    /deep/ .ant-steps-item-title::after {
      background: #cfd5de;
    }
    /deep/ .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
      background: @primary-color;
    }
    /deep/ .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
      font-size: 16px;
      color: #1D202D;
    }
    /deep/ .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
      font-size: 16px;
      color: #6E788C;
    }
    /deep/ .ant-steps-item  {
      .ant-steps-item-icon {
        // display: none;
      }
    }
    .show-icon {
      /deep/ .ant-steps-item-icon {
        // display: inline;
      }
    }
  }
  .rf {
    width: 400px;
    background: #f9fafb;
    box-shadow: -1px 0px 0px 0px rgba(209, 210, 215, 0.9);
    padding: 20px;
  }
}
@media (max-width: 1700px) {
  .operation-box-container  { 
    height: 580px!important;
   }
}
</style>