/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-09-28 10:08:43
 * @LastEditTime: 2023-01-10 15:25:43
 * @FilePath: \awx-ui\src\store\modules\base.js
 */
import nodeData from "../../pages/flow/components/node/node.json";

const state = {
  nodeSelectedKey: "null",
  addIndex: 'null',
  nodeData: nodeData,
  copyNodeData: {},
  showFlowSelect: false,
  showApp: false
}

const getters = {}

const mutations = {
}

Object.keys(state).forEach((item) => {
  mutations[item] = (state, value) => {
    state[item] = value
  }
})

const actions = {
  setSelectKey({ commit }, obj) {
    commit('nodeSelectedKey', obj)
  },
  setFlowSelect({ commit }, obj) {
    commit('showFlowSelect', obj)
  },
  setShowApp({ commit }, obj) {
    commit('showApp', obj)
  },
  setAddIndex({ commit }, obj) {
    commit('addIndex', obj)
  },
  setNodeData({ commit }, obj) {
    commit('nodeData', obj)
  },
  setCopyNodeData({ commit }, obj) {
    commit('copyNodeData', obj)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
