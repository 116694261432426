<!--
 * @Author: zhang·xiao
 * @Date: 2022-12-10 15:20:40
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2023-06-04 22:18:24
 * @Description: 描述文件功能
-->
<template>
  <div>
    <CodeEditWidget :readOnly="readOnly" editorHeight="calc(70vh - 180px)" language="javascript" :value="formatString" @change="onChange"/>
    <p style="margin-top: 20px; text-align: right;">
      <el-button @click="onChangeReadOnly">{{readOnly ? '编辑' : '退出编辑'}}</el-button>
      <el-button plain type="primary" @click="toCopy">
          复制
      </el-button>
      <el-button type="primary" @click="$emit('saveCode', copyValue)">
          保存
      </el-button>
    </p>
  </div>
</template>

<script>
import CodeEditWidget from '@formEngine/config/widgets/CodeEditWidget'
export default {
  name: 'ExportSchemaView',
  components: {
    CodeEditWidget
  },
  props: {
    genCode: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      copied: false,
      readOnly: true,
      value: ''
    };
  },
  computed: {
    formatString() {
      const string = String(this.genCode) === this.genCode ? this.genCode : JSON.stringify(this.genCode, null, 2);
      return `const schema = ${string}`
    },
    copyValue() {
      let str = this.value;
      if (!str) {
        str = String(this.genCode) === this.genCode ? this.genCode : JSON.stringify(this.genCode, null, 2);
      }
      return str
    }
  },
  methods: {
    toCopy() {
      if (this.copied) {
        return;
      }
      const textarea = document.createElement('textarea');
      textarea.value = this.copyValue;
      document.body.appendChild(textarea);
      textarea.select();
      this.copied = document.execCommand('copy')
      document.body.removeChild(textarea);
      setTimeout(() => {
        this.copied = false;
        this.$message.success('复制成功');
      }, 300);
    },
    onChange(val) {
      try {
        const v = val.replace('const schema = ', '');
        this.value = v;
        console.log(v)
      } catch (error) {
        console.log(error)
      }
    },
    onChangeReadOnly() {
      this.readOnly = !this.readOnly;
    }
  }
};
</script>
