<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-01-12 10:12:55
 * @LastEditTime: 2023-09-28 14:34:32
 * @FilePath: \awx-ui\src\components\menu\appDrawer\index.vue
-->
<!--
 * @Author: mjzhu
 * @describe: step2-主机环境校验 
 * @Date: 2022-06-13 16:35:02
 * @LastEditTime: 2023-01-17 14:40:59
 * @FilePath: \awx-ui\src\components\createTask\AppComponents\rightTempalte.vue
-->
<template>
  <div class="app-drawer">
    <a-input ref="userNameInput" v-model="keyword" class="search-input" @pressEnter="onSearch" placeholder="搜索关键字">
      <svg-icon icon-class="search" class="keyword-search" slot="prefix"></svg-icon>
    </a-input>
    <div class="rf">
      <a-tabs v-model="templateTab" @change="callback" :tab-position="mode" :class="[templateTab === 'all' && 'all-tab']" :tabBarStyle="{borderBottom: '1px solid #cfd5de'}">
        <a-tab-pane v-for="(item) in templateCate" :tab="item.name" :key="item.catagory"></a-tab-pane>
      </a-tabs>
      <a-spin :spinning="spinning" style="width: 100%;height: 100%;">
        <AppComponents :appList="appList" @seletCate="seletCate" @selectedTemplate="selectedTemplate" />
      </a-spin>
    </div>
  </div>
</template>
<script>
import AppComponents from "./appComponents.vue";


export default {
  data() {
    return {
      current: 1,
      total: 0,
      templateTab: "all",
      keyword: "",
      timer: null,
      spinning: false,
      systemLength: 1,
      customLength: 0,
      cateType: "",
      templateCate: [],
      templateTabArr: [],
      selectedTemplateId: "",
      customTemplateList: [],
      appList: [],
      moreList: [],
      mode: "top",
    };
  },
  computed: {},
  components: { AppComponents },
  methods: {
    changeMore(item) {
      this.templateTab = "";
      this.templateTabArr = [];
      this.templateTabArr.push(item.catagory);
      this.getTemplateList(item.catagory);
    },
    callback(key) {
      this.templateTab = key;
      if (key === "all") {
        this.getApp('');
        return false;
      }
      this.getApp(key);
    },
    getCatagories() {
      this.$axiosGet(global.API.getAppCatagories, {
        page: 1,
        pageSize: 1000,
        kind: -1
      }).then((res) => {
        this.loading = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          this.templateCate = [
            { catagory: "all", name: "全部" },
          ];
          res.data.results.map((item) => {
            this.templateCate.push(item);
          });
        }
      });
    },
    // 查询模板
    onSearch(val) {
      this.getApp('');
    },
    seletCate(type) {
      this.cateType = type;
    },
    selectedTemplate(id) {
      this.selectedTemplateId = id;
      this.$emit("selectedTemplate", id);
    },
    //表搜索
    getApp(key) {
      this.spinning = true;
      const params = {
        page_size: 15,
        page: this.current,
        keyword: this.keyword || "",
        kind: 1
      };
      if (key) params.catagory = key;
      this.$axiosGet(global.API.getApp, params).then((res) => {
        this.spinning = false;
        if ([200, 201, 204, 202].includes(res.status)) {
            this.appList = [
              ...res.data.results,
            ]
            this.systemLength = this.appList.length;
            this.total = res.data.total;
        }
      });
    },
  },
  mounted() {
    this.getCatagories();
    this.getApp();
  },
  beforeDestroy() {
  },
};
</script>
<style lang="less" scoped>
.app-drawer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .search-input {
    width: 490px;
    height: 30px;
    background: #41516b;
    border-radius: 4px;
    /deep/ .ant-input {
      padding-left: 40px;
      background: #41516b;
      color: #fff;
      border: none;
    }
    .keyword-search {
      color: rgb(172, 192, 190);
    }
  }
  .flex-warp {
    display: flex;
  }
  .rf {
    width: 490px;
    position: relative;
    padding: 6px 0 0 0;
    /deep/ .ant-tabs {
      width: 490px;
    }
  }
  /deep/ .ant-tabs-tab {
    padding: 8px 0;
    font-size: 14px;
    color: #ffffff;
  }
  /deep/ .ant-tabs-bar {
    border-bottom: none !important;
  }
  /deep/ .ant-tabs-tab-prev,
  /deep/ .ant-tabs-tab-next {
    color: #fff;
  }
  /deep/ .ant-tabs-ink-bar {
    height: 3px;
  }
  /deep/ .ant-tabs-nav .ant-tabs-tab-active {
    color: #4eadff;
    font-weight: 700;
    font-size: 15px;
  }
  .all-tab {
    /deep/ .ant-tabs-ink-bar {
      width: 28px !important;
    }
  }
}
</style>