<!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2024-01-29 11:46:10
 * @FilePath: \awx-ui\src\components\runTaskHistory\index.vue
-->
<template>
  <div class="run-collapse" >
    <div class="collapse-header">
      <a-tabs @click.stop id="a-tabs" v-model="runHistory.runtab" @change="(val) => callback(val)" style="width: 100%">
        <a-tab-pane v-for="(item) in tabs" :key="item.key" :tab="item.name">
      </a-tab-pane></a-tabs>
    </div>
  <div class="run-history-drawer">
        <a-spin :spinning="runHistory.taskLoading">
          <div class="run-container" v-if="runHistory.runHistoryList.length > 0">
            <div class="run-container-item" v-for="(item, index) in runHistory.runHistoryList" :key="item.id" style="height: 68px" @click.stop="handleClick(item, index)">
              <div class="run-history-drawer-title" slot="title">
                <div class="run-history-drawer-status">
                  <svg-icon v-if="item.job_status === 'running'" class="task-running" style="font-size: 16px" icon-class="task-running" />
                  <svg-icon v-if="item.job_status === 'failed'" style="font-size: 16px" icon-class="task-failed" />
                  <svg-icon v-if="item.job_status === 'successful'" style="font-size: 16px" icon-class="task-successful" />
                </div>
                <div class="run-history-drawer-content" :title="item.name">{{ item.name }}</div>
                <div class="run-history-drawer-description" slot="description">{{ item.detail ? moment(item.detail.started).format("YYYY-MM-DD HH:mm:ss"): moment(item.create_time).format("YYYY-MM-DD HH:mm:ss") }}</div>
              </div>
              <div class="run-history-drawer-description run-total" slot="description">
                <span>总数：{{ item.stat ? item.stat.total_count : 0}}</span>
                <a-divider type="vertical" />
                <span>成功：{{item.stat ? item.stat.success_count : 0}}</span>
                <a-divider type="vertical" />
                <span>失败：{{item.stat ? item.stat.fail_count : 0}}</span>
              </div>
            </div>
            <div class="load-more" v-if="!isEmpty" @click="loadMore">点击查看更多</div>
            <div class="is-empty" v-if="isEmpty">已经到底了~</div>
          </div>
          <div v-else class="no-data-page">
            <img src="@/assets/img/no-data-small.png" alt="暂无数据" />
            <div>暂无数据</div>
          </div>
        </a-spin>
        </div>
      </div>
</template>
  <script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import result from "./result.vue";
import moment from "moment";
export default {
  // components: {result},
  props: {
    historyList: {
      type: Array,
      default: () => [],
    },
    currentHistoryIndex: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      moment,
      collapseKey: 'open',
      tabs: [
        { key: "file", name: "文件" },
        { key: "cmd", name: "命令" },
        // { key: "script", name: "脚本" },
        { key: "task", name: "任务" },
      ],
      fileColumns: [
        { title: "源文件", key: "src", dataIndex: "src", ellipsis: true },
        {
          title: "源类型",
          key: "src_type",
          width: 100,
          ellipsis: true,
          dataIndex: "src_type",
        },
        {
          title: "目标主机",
          key: "tgt_name",
          dataIndex: "tgt_name",
          ellipsis: true,
          sorter: (a, b) => {
            return b.tgt_name.localeCompare(a.tgt_name, 'zh-Hans-CN', { numeric: true });
          },
          customRender: (text, row, index) => {
            return <span title={text}>{text ? text : "-"}</span>;
          },
        },
        {
          title: "目标文件",
          key: "dest",
          dataIndex: "dest",
          ellipsis: true,
        },
        {
          title: "同步结果",
          key: "status",
          width: 110,
          dataIndex: "status",
          sorter: (a, b) => {
            return b.status.localeCompare(a.status, 'zh-Hans-CN', { numeric: true });
          },
          customRender: (text, row, index) => {
            return (
              <span>
                <svg-icon style="font-size: 16px" icon-class={text === "success" ? "task-successful" : "task-failed"} />
                <span class='mgl4'>{text === "success" ? "成功" : "失败"}</span>
              </span>
            );
          },
        },
        {
          title: "备注",
          key: "msg",
          ellipsis: true,
          dataIndex: "msg",
          customRender: (text, row, index) => {
            return (
              <span
                title={text ? text : "-"}
                style={{
                  color: row.result === "false" ? "#e74e4f" : "#000000a6",
                }}
              >
                {text ? text : "-"}
              </span>
            );
          },
        },
      ],
      cmdColumns: [
        { title: "目标主机", key: "tgt_name", dataIndex: "tgt_name",
          sorter: (a, b) => {
            return b.tgt_name.localeCompare(a.tgt_name, 'zh-Hans-CN', { numeric: true });
          },
        },
        {
          title: "执行结果",
          key: "result",
          dataIndex: "result",
          width: 110,
          sorter: (a, b) => {
            return b.result.localeCompare(a.result, 'zh-Hans-CN', { numeric: true });
          },
          customRender: (text, row, index) => {
            return <span>
               <svg-icon style="font-size: 16px" icon-class={text === "success" ? "task-successful" : "task-failed"} />
                <span class="mgl4">{text === "success" ? "成功" : "失败"}</span>
            </span>;
          },
        },
        {
          title: "消息",
          key: "msg",
          ellipsis: true,
          dataIndex: "msg",
          customRender: (text, row, index) => {
            return <span title={text}>{text ? text : "-"}</span>;
          },
        },
      ],
      resultLoading: false,
    };
  },
  watch: {
    'runHistory.collapseKey': {
      handler(val) {
        this.collapseKey = val
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      runHistory: (state) => state.runHistory, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    isEmpty () {
      const isEmpty = this.runHistory.runtab === 'file' ? this.runHistory.fileIsEmpty : this.runHistory.runtab === 'task' ? this.runHistory.taskIsEmpty : this.runHistory.cmdIsEmpty
      return isEmpty
    },
  },
  filters: {
    formatStatus(val) {
      if (val === "successful") {
        return "已完成";
      } else if (val === "running") {
        return "运行中";
      } else if (val === "failed") {
        return "未完成";
      }
    },
  },
  methods: {
    ...mapActions("runHistory", [
      "getHistoryList",
      "changeShowHistory",
      "setRunTab",
      "setNumAdd",
      "changeCollapseKey",
    ]),
    ...mapActions("task", ["setJumpTaskDetail"]),
    changeCollaspeKey (val) {
      this.changeCollapseKey(val)
    },
    callback(val) {
      this.setRunTab(val);
      // 刷新列表 把分页置位1
      this.setNumAdd(true)
      // this.getHistoryList({ type: val, init: false });
    },
    onClose(e) {
      this.changeShowHistory(false);
    },
    loadMore () {
      this.setNumAdd()
    },
    handleClick(item, index) {
      if (this.runHistory.runtab === 'task') {
        if (item.type === '3') {
          this.$router.push(`/task-manage/pipeline-edit?id=${item.task_id}&readOnly=true&fromType=tab`);
        } else {
          this.$router.push({
            name: '任务'
          });
          this.setJumpTaskDetail(item)
        }
        return false
      }
      if (this.resultLoading) return false;
      const self = this;
      let width = 800;
      let title = "";
      this.resultLoading = true;
      this.$axiosGet(global.API.quickTaskDetail + item.id).then((res) => {
        this.resultLoading = false;
        if ([200, 201, 204].includes(res.status)) {
          self.resultData =
            res.data && res.data.stat && res.data.stat.result
              ? res.data.stat.result
              : [];
          let content = (
            <result
              resultColumns={this.runHistory.runtab === 'cmd' ? self.cmdColumns : self.fileColumns}
              resultLoading={self.resultLoading}
              resultData={self.resultData}
              currentHistory={res.data}
            />
          );
          self.$confirm({
            width: width,
            title: title,
            content: content,
            closable: false,
            class: "run-task-result-modal",
            icon: () => {
              return <div />;
            },
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.run-collapse {
  z-index: 1050;
  position: fixed;
  top: 48px;
  right: 0px;
  background-image: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  border: 1px solid rgba(208, 214, 223, 1);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);
  width: 400px;
  
  /deep/ .ant-collapse-item .ant-collapse-header {
    padding: 0px 0 0 12px;
  }
  /deep/ .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  .collapse-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /deep/ .ant-tabs-nav-wrap {
    text-align: center;
    .ant-tabs-nav .ant-tabs-tab {
      margin-right: 12px;
      font-size: 14px;
      color: #34343c;
      font-weight: 400;
      padding: 10px 0;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
      font-size: 14px;
      color: #34343c;
      font-weight: 600;
    }
  }
  /deep/ .ant-tabs-bar {
    margin: 0px;
  }
}
.run-history-drawer {
  width: 400px;
  height: 475px;
  .history-head {
    display: flex;
  }
  .run-container {
    max-height: 466px;
    overflow-y: auto;
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 12px 0 12px;
      border-bottom: 1px solid#ECF0F4;
      line-height: 1.5;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background: #e6e8ed;
      }
    }
  }
  &-icon {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: rgba(195, 202, 217, 0.9);

    &.active {
      width: 12px;
      height: 12px;
      border-radius: 12px;
      background: rgba(58, 116, 244, 1);
      border: 1px solid rgba(58, 116, 244, 0.4);
      padding: 1px;
      background-clip: content-box;
    }
  }

  &-title {
    width: 100%;
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #1d202d;
    display: flex;
    align-items: center;

    .run-history-drawer-content {
      width: 0;
      flex: auto;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px !important;
      font-weight: 500 !important;
      color: #1d202d;
    }
    .run-history-drawer-content:hover {
      color: #3974f4 !important;
    }
  }

  &-description {
    font-family: PingFangSC-Medium;
    font-size: 13px;
    color: #798baa;
  }
  .run-total {
    margin: 4px 0 0 0;
    padding: 0 0 0 21px;
  }
  .run-footer {
    height: 40px;
    border-top: 1px solid#ECF0F4;
    display: flex;
    justify-content: flex-end;
    padding: 0 12px 0 0;
    align-items: center;
    div {
      cursor: pointer;
    }
  }

  &-status {
    height: 22px;
    line-height: 23px;
    text-align: center;
    border-radius: 3px;
    margin-right: 6px;

    &.successful {
      background: #e4ecff;
      border: 1px solid rgba(189, 210, 254, 1);
      color: #3a74f4;
    }

    &.running {
      background: #fff3cd;
      border: 1px solid rgba(240, 197, 112, 1);
      color: #bf8b2f;
    }

    &.failed {
      background: #fae0e0;
      border: 1px solid rgba(245, 213, 213, 1);
      color: #e74e4f;
    }
  }
  @keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  .task-running {
    transform: rotate(360deg);
    animation: rotation 2s linear infinite;
  }
  .no-data-page {
    text-align: center;
    position: relative;
    box-sizing: border-box;
    padding: 13px 10px;
    margin-right: 20px;
    border: 1px solid transparent;
    border-bottom-color: #adb0b8;
    border-bottom: 1px solid var(--devui-line, #adb0b8);
    cursor: pointer;
    color: #adb0b8;
    &:hover {
      // border-left-color: #adb0b8;
      // border-left: 1px solid var(--devui-line, #adb0b8);
      // border-right-color: #adb0b8;
      // border-right: 1px solid var(--devui-line, #adb0b8);
    }
  }
  .load-more, .is-empty {
    font-size: 12px;
    text-align: center;
    color: #798baa;
    margin: 5px 0 0;
    cursor: pointer;
  }
}
.exit {
  position: relative;
  left: 116px;
  cursor: pointer;
  color: rgba(0, 0 ,0, 0.45);
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 0 3px 0 0;
  &:hover {
    color: rgba(0, 0, 0, 0.65);
    background: rgba(208, 210, 213);
  }
}
</style>