<!--
 * @Author: zhang·xiao
 * @Date: 2022-11-29 13:37:16
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2022-11-29 14:12:34
 * @Description: 描述文件功能
-->
<template>
    <el-radio-group
        v-bind="$attrs"
        v-on="$listeners"
    >
        <el-radio
            v-for="(item, index) in enumOptions"
            :key="index"
            :label="item.value"
        >
            {{ item.label }}
        </el-radio>
    </el-radio-group>
</template>

<script>
export default {
  name: 'RadioWidget',
  props: {
    enumOptions: {
      default: () => [],
      type: [Array]
    }
  }
};
</script>
