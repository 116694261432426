<!--
 * @Author: mjzhu
 * @Date: 2022-06-08 11:38:30
 * @LastEditTime: 2024-01-17 19:26:36
 * @FilePath: \awx-ui\src\pages\operationConsole\components\bottomOpe\addCmd.vue
-->
<template>
  <div class="quota-container">
    <apply-quota v-if="step === 1" :quotaType="quotaType" @toOrder="toOrder" @toMyquota="callBack" />
    <QuotaOrder v-if="step === 2" :quotaType="quotaType" :quotaDetail="quotaDetail" @toApply="toApply" @toMyquota="callBack"/>
  </div>
</template>
  <script>
import QuotaOrder from './quotaOrder.vue';
import ApplyQuota from './applyQuota.vue';

export default {
  components: {
    QuotaOrder,
    ApplyQuota
  },
  props: {
    quotaType: String,
    detail: {
      type: Object,
      default: function () {
        return {};
      },
    },
    currentTab: String,
    callBack: Function,
  },
  data () {
    return {
      step: 1,
      quotaDetail: {},
      contactCard: require("@/assets/img/bg-quoto.png"),
    };
  },
  watch: {
    detail: {
      handler (val) {
        if (val) this.quotaDetail = val
      },
      immediate: true
    },
    quotaType: {
      handler (val) {
        if (val === 'apply') return this.step = 1
        this.step = 2
      },
      immediate: true
    }
  },
  methods: {
    formCancel () {
      this.$destroyAll();
    },
    setDetail () {

    },
    toApply () {
      this.step = 1
    },
    toOrder (item) {
      this.step = 2
      this.quotaDetail = item
    }
  },
  mounted () {
  },
  beforeDestroy() {
  }
};
</script>
    <style lang="less" scoped>
.quota-container {

}
</style>
    