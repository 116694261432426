<!--
 * @Author: mjzhu
 * @Date: 2022-05-24 10:28:22
 * @LastEditTime: 2024-04-03 17:01:20
 * @FilePath: \awx-ui\src\components\menu\SideMenu.vue
-->
<template>
  <a-layout-sider :theme="sideTheme" :class="['side-menu', 'beauty-scroll', isMobile ? null : '']" :style="collapsed?'min-width:64px;width:64px;max-width:64px':'min-width:182px;width:182px;max-width:182px'" :collapsible="collapsible" v-model="collapsed" :trigger="null" style="background: transparent">
    <div class="menu-list-wrap" @click="hideSet">
      <li data-id="productListId" :class="['product-list']" @click="openDrawer">
        <svg-icon icon-class='product-list' :style="[{marginBottom:'6px'},{fontSize: collapsed ? '20px':'20px'}]"></svg-icon>
        <div>工作台</div>
      </li>
      <a-drawer
      v-show="drawerOpen"
      :visible="true" 
      :class="['drawer-wrap',{'drawer-wrap-close':collapsed}]"
      width="538"
      placement="left"
      :closable="false"
      :wrapStyle="{'width': collapsed?'calc(100% - 64px)':'calc(100% - 64px)','height':'calc(100% - 48px)','left': collapsed?'64px':'182px','top':'48px', 'z-index': 1040}"
      :maskStyle="{'background': 'transparent'}"
      @close="handleClose">
        <ul class="menu-list-wrap">
          <li class="menu-li" v-for="(menu,index) in menuData" :key="index">
            <div class="first-name">
              <span style="margin-right: 9px" @click="handleClickMenu(menu)">{{menu.name}}</span>
              <template v-if="!(menu.children&&menu.children.length)">
                <svg-icon v-if="isCollect(menu.path)" icon-class='fill-star' @click.native="changeCollect(menu)" />
                <svg-icon v-else icon-class='star' @click.native="changeCollect(menu)" />
              </template>
            </div> 
            <div v-if="menu.children.length" class="child-list-wrap">
              <span v-for="(item,idx) in menu.children" :key="idx" class="child-list">
                <svg-icon class="side-menu-icon" style="font-size: 20px" :icon-class="item.meta.icon" />
                <span class="second-name" @click="handleClickMenu(item)">{{item.name}}</span>
                <svg-icon v-if="isCollect(item.path)" icon-class='fill-star' @click.native="changeCollect(item)" />
                <svg-icon v-else icon-class='star' @click.native="changeCollect(item)" />
              </span>
            </div>
          </li>
        </ul>
      </a-drawer>
      <i-menu
      :theme="theme"
      :menuNum="menuNum"
      :collapsed="false"
      :options="options" 
      @select="onSelect" 
      @dragstart="dragstart"
      @dragenter="dragenter"
      @dragend="dragend"
      @dragover="dragover"
      class="menu left-menu" 
      style="background: transparent" />
      <a-popover v-model="showMore" title="" overlayClassName="app-more-popover" placement="right" trigger="click">
        <AppPopover slot="content" :moreAppList="childMenu" @toPath="toPath" :menuNum="menuNum" /> 
        <a v-if="isDashBoard && childMenu.length > menuNum" class="more-opt" @click="showMorePopover">
           <div><svg-icon icon-class="app-more" /></div>
           <div>更多</div>
         </a>
      </a-popover>
      <li v-if="isDashBoard" :class="['add-app', installAppDrawerShow && 'add-app-selected']" @click="showAppDrawer">
        <span><svg-icon :icon-class="installAppDrawerShow ? 'app-add' : 'app-add-normal'" :style="[{fontSize: collapsed ? '18px':'18px'}]"></svg-icon></span>
      </li>
      <a-drawer
        v-if="installAppDrawerShow"
        :visible="installAppDrawerShow" 
        :class="['drawer-wrap-app',{'drawer-wrap-close':collapsed}]"
        width="538"
        placement="left"
        :closable="false"
        :wrapStyle="{'width': collapsed?'calc(100% - 64px)':'calc(100% - 64px)','height':'calc(100% - 48px)','left': collapsed?'64px':'182px','top':'48px', 'z-index': 1040}"
        :maskStyle="{'background': 'transparent'}"
        @close="handleAppClose">
        <AppDrawer />
      </a-drawer>
     </div>
      <!-- 新建部署任务 -->
      <a-modal class="task-model" v-if="visible" title="" :visible="visible" :maskClosable="false" :closable="false" :width="clientWidth < 1700 ? 1280 : 1326"  :confirm-loading="confirmLoading" @cancel="handleCancelApp" :footer="null">
        <CreateNewtask taskType="app" />
      </a-modal>
  </a-layout-sider>
</template>

<script>
import IMenu from './menu'
import AppDrawer from './appDrawer'
import AppPopover from './appPopover'
import CreateNewtask from "@/components/createTask/deploystep/newCreateTask.vue";
import { mapState, mapMutations, mapActions } from "vuex";
const clientWidth = document.documentElement.clientWidth

import SvgIcon from '@/icons/SvgIcon.vue'
export default {
  name: 'SideMenu',
  components: {IMenu, SvgIcon, AppDrawer, AppPopover, CreateNewtask},
  props: {
    collapsible: {
      type: Boolean,
      required: false,
      default: false
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    },
    menuData: {
      type: Array,
      required: true
    },
    theme: {
      type: String,
      required: false,
      default: 'dark'
    }
  },
  data() {
    return {
      clientWidth,
      visible: false,
      taskModalTitleL: '新建任务',
      confirmLoading: false,
      installAppDrawerShow: false,
      drawerOpen:false,
      sideMenuData: [],
      oldData: {},
      newData: {},
      menuClick: false,
      isDashBoard: false,
      showMore: false,
      menuNum: 8
    }
  },
  created() {
    this.getCollectionMenu()
    // this.getHistoryListInit()
    // this.getMaster()
  },
  computed: {
    sideTheme() {
      return this.theme == 'light' ? this.theme : 'dark'
    },
    flatMenuData() {
      return this.flat(this.menuData, '')
    },
    ...mapState({
      account: (state) => state.account, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
      drawer: (state) => state.drawer,
      fileManage: (state) => state.fileManage
    }),
    ...mapState('setting', ['isMobile', 'systemName']),
    childMenu() {
      const currentMenu  = this.menuData.filter(item => this.$route.fullPath.includes(item.fullPath))
      return currentMenu[0].children &&  currentMenu[0].children.length ?  currentMenu[0].children : [...currentMenu]
    },
    options () {
      const arr = _.cloneDeep(this.childMenu)
      let options =  arr
      if (this.isDashBoard && this.childMenu.length > this.menuNum) options = arr.slice(0, 8)
      return options
    }
  },
  watch: {
    '$route': {
      handler(val) {
        if (val.path.includes('dashboard')) {
          this.isDashBoard = true
        } else {
          this.isDashBoard = false
        }
      },
      immediate: true,
      deep: true,
    }
  },
  provide() {
    return {
      createTask: this.createTaskApp,
      handleCancel: this.handleCancelApp,
      onlyCancel: this.handleCancelApp
    };
  },
  methods: {
    ...mapActions("runHistory", ["getHistoryList", "changeShowHistory"]),
    ...mapActions("masterMd", ["setHasMaster"]),
    ...mapActions("task", ["setTaskInfo"]),
    ...mapMutations("account", ["setAccountSetting"]),
    handleCancelApp(e) {
      this.visible = false;
    },
    hideSet () {
      if (this.account.accountSetting)  this.setAccountSetting(false)
      this.changeShowHistory(false)
    },
    createTaskApp(obj) {
      this.$axiosGet(
        global.API.curdTaskTemplate + obj.template_id + "/",
        {}
      ).then((res) => {
        if (
          [200, 201, 204, 202].includes(res.status) &&
          res.data &&
          res.data.operations &&
          res.data.operations.length > 0
        ) {
          // this.handleAppClose()
          this.taskModalTitle = "新建任务";
          let taskTemplateDetail = res.data
          taskTemplateDetail.operations.forEach(item => {
            if (item.application_id) {
              item['currentStepsIndex'] = 1
            }
            if (item.id) {
              item['currentStepsIndex'] = 2
            }
            if (item.application_id && item.id) {
              item['currentStepsIndex'] =  4
            }
          })
          this.setTaskInfo({
            ...obj,
            installAppid: obj.id,
            taskTemplateDetail: taskTemplateDetail,
          });
          this.visible = true;
          this.installAppDrawerShow = false
        }
      });
    },
    onSelect (obj) {
      this.$emit('menuSelect', obj)
      this.menuClick = true
      this.handleClose()
      this.handleAppClose()
    },
    showMorePopover () {
      this.showMore = true;
      this.drawerOpen = false
      this.installAppDrawerShow = false
    },
    hide() {
      console.log(111);
      this.showMore = false;
    },
    showAppDrawer () {
      this.drawerOpen = false
      this.installAppDrawerShow = !this.installAppDrawerShow
    },
    handleAppClose () {
      this.drawerOpen = false
      this.installAppDrawerShow = false
    },
    openDrawer () {
      this.installAppDrawerShow = false
      this.drawerOpen = !this.drawerOpen
    },
    toggleCollapsed() {
      // this.collapsed = !this.collapsed
      this.$emit('toggleCollapse')
    },
    getMaster() {
      this.spinning = true;
      this.$axiosGet(global.API.getMasterInfo).then((res) => {
        this.spinning = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          if (res.data.results.length > 0) {
           const onlineCluster = res.data.results.filter(item => item.status === 'online')
           let DefaultMaster = {} 
           if (onlineCluster.length > 0) {
              this.setHasMaster(true)
              const arr  = onlineCluster.filter(item => item.is_default)
              if (arr.length > 0) {
                DefaultMaster = arr[0]
              } else {
                DefaultMaster = onlineCluster[0]
              }
           } else {
            this.setHasMaster(false)
           }
           localStorage.setItem('masterOptions', JSON.stringify(res.data.results))
           localStorage.setItem('FILE_SYSTEM_URL', DefaultMaster?.public_addr  || DefaultMaster?.private_addr || '')
           window.config.FILE_SYSTEM_URL = DefaultMaster.public_addr  || DefaultMaster.private_addr || ''
          } else {
           localStorage.setItem('FILE_SYSTEM_URL', '')
            window.config.FILE_SYSTEM_URL = ''
          }
        }
      });
    },
    getHistoryListInit () {
      this.getHistoryList({type: 'file', init: true})
    },
    handleMouseEnter(e) {
      if (e.target.dataset.id === 'productListId') {
        if (this.collapsed) {
          this.$emit('toggleCollapse')
        }
        setTimeout(() => {
          this.drawerOpen = true
          this.installAppDrawerShow = false
        },200)
      }
    },
    menuMouseEnter() {
      if (this.collapsed) {
        this.$emit('toggleCollapse')
      }
    },
    menuMouseLeave() {
      if (!this.drawerOpen&&!this.collapsed) {
        this.$emit('toggleCollapse')
      }
    },
    handleClose() {
      this.installAppDrawerShow = false
      this.drawerOpen = false
      if (!this.collapsed) {
        this.$emit('toggleCollapse')
      }
    },
    flat(arr,parentIcon){
      let newArr=[];//申请新数组
      for(let i=0;i<arr.length;i++){
        // if (parentIcon) arr[i].meta.icon = parentIcon
        if(arr[i].children&&arr[i].children.length){//children存在
          newArr.push(...this.flat(arr[i].children, arr[i].meta.icon));
          //递归，调用flat方法，并将返回结果push到新数组
        }
        newArr.push({...arr[i]});//三个点，展开对象，push新对象  
      }  
      return newArr;//返回数组
    },
    getCollectionMenu() {
      this.$axiosGet(global.API.getCollectMenus).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          let data = res.data || []
          let newMenuData = new Array(data.length)
          this.flatMenuData.forEach(item => {
            let index = data.findIndex(val => val.biz_id === item.path)
            if (index > -1) {
              let currentItem = Object.assign({}, item)
              currentItem.children = []
              newMenuData.splice(index, 1, {
                biz_id: currentItem.path,
                ...currentItem
              })
            }
          }) 
          this.sideMenuData = newMenuData
        }
      }); 
    },
    isCollect(path) {//是否已收藏
      return this.sideMenuData.some(item =>item.path===path)
    },
    changeCollect(val) {
      if (this.sideMenuData.some(item=>item.path===val.path)) {
        this.sideMenuData = this.sideMenuData.filter(item => item.path !==val.path)
      } else {
        let obj = Object.assign({}, val)
        obj.biz_id = obj.path
        obj.children = []
        this.sideMenuData.push(obj)
      }
      this.updateMenus()
    },
    toPath (menu) {
      this.hide()
      this.$router.push({
        path: menu.fullPath,
      });
    },
    handleClickMenu(item) {
      this.$router.push({
        path: item.fullPath
      })
      this.handleClose()
    },
    updateMenus() {
      let params = this.sideMenuData.map(item => {
        return { biz_id: item.biz_id}
      })
      this.$axiosJsonPost(global.API.updateCollectMenus, params).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.getCollectionMenu()
        }
      });
    },
    dragstart(value) {
      this.oldData = Object.assign({}, value)
    },
    dragenter(value, e) {// 记录移动过程中信息
      this.newData = Object.assign({}, value)
      e.preventDefault()
    },
    dragend(value, e) {// 拖拽最终操作
      if (this.oldData.path !== this.newData.path) {
        const oldIndex = this.sideMenuData.findIndex(item=> item.path === this.oldData.path)
        const newIndex = this.sideMenuData.findIndex(item=> item.path === this.newData.path)
        const newItems = [...this.sideMenuData]
        // 删除老的节点
        newItems.splice(oldIndex, 1)
        // 在列表中目标位置增加新的节点
        newItems.splice(newIndex, 0, this.oldData)
        this.sideMenuData = [...newItems]
        this.updateMenus()
      }
    },
    dragover(e) {// 拖动事件（主要是为了拖动时鼠标光标不变为禁止）
      e.preventDefault()
    }
  }
}
</script>

<style lang="less" scoped>
@import "index";
.add-app {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  cursor: pointer;
  border-radius: 8px;
  margin:0 auto;
  &:hover {
    background: rgba(0,0,0,0.24);
    border: 1px solid rgba(0,0,0,0.05);
  }
  &-selected {
    background: rgba(0,0,0,0.24);
    border: 1px solid rgba(0,0,0,0.05);
  }
}
.product-list {
  position: absolute;
  bottom: 64px;
  height: 56px;
  width: 56px;
  list-style-type: none;
  flex-direction: column;
  color: rgba(254, 254, 254, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  cursor: pointer; 
  &.collapsed {
    padding-left: calc(50% - 12px);
  }
  &.list-active {
    background-color: rgba(0,0,0,0.24)!important;
    span {
      background: linear-gradient(#FFFFFF, #6697FF);
      -webkit-background-clip: text;
      color: transparent;
    }
  }
  &:hover {
    color: #fff;
    span {
      background: linear-gradient(#FFFFFF, #6697FF);
      -webkit-background-clip: text;
      color: transparent;
    }
  }
  .expand-icon {
    position: absolute;
    right: 10px;
  }
}
.menu-list-wrap {
  height: calc(100% - 120px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 6px;
}
/deep/.ant-layout-sider-collapsed {
  width: 64px !important;
  min-width: 64px !important;
  max-width: 64px !important;
}
.drawer-wrap-app {
  /deep/ .ant-drawer-body {
    padding: 24px!important;
  }
  /deep/  .ant-drawer-content-wrapper {
    box-shadow: none;
    border-radius: 10px;
    .ant-drawer-content {
      background: #364660;
      border-radius: 10px;
    }
  } 
}
</style>
<style lang="less">
.app-more-popover  {
  .ant-popover-inner {
    border-radius: 8px;
    box-shadow: none;
  }
  .ant-popover-inner-content {
    width: 216px;
    height: 160px;
    background: #364660;
    box-shadow: 0px 2px 12px 0px rgba(54,70,96,0.45);
    border-radius: 8px;
    padding: 12px 16px;
  }
  .ant-popover-arrow {
    display: none!important;
  }
}
.more-opt {
    width: 50px;
    height: 38px;
    border-radius: 8px;
    margin: 0 auto 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
</style>