import genSchema from '../genSchema.js';

const viewSchema = {
  title: 'Date(时间戳)',
  type: 'number',
  format: 'date'
};

export default {
  viewSchema,
  propsSchema: genSchema({
    options: {
      type: 'object',
      title: '选项',
      required: [],
      properties: {
        uiOptions: {
          type: 'object',
          properties: {
            placeholder: {
              type: 'string',
              title: '输入占位文本',
              default: '请选择日期'
            }
          }
        }
      }
    }
  })
};
