/*
 * @Author: zhang·xiao
 * @Date: 2022-11-29 13:33:57
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2022-11-30 01:05:00
 * @Description: 描述文件功能
 */
import SelectLinkageField from '../SelectLinkageField';

export default {
  name: 'oneOfField',
  functional: true,
  render(h, context) {
    const { props, ...otherData } = context.data;
    return h(SelectLinkageField, {
      ...otherData,
      props: {
        ...props,
        combiningType: 'oneOf',
        selectList: props.schema.oneOf
      }
    }, context.children);
  }
};
