<!--
 * @Author: mjzhu
 * @describe: step2-主机环境校验 
 * @Date: 2022-06-13 16:35:02
 * @LastEditTime: 2023-08-21 15:59:01
 * @FilePath: \awx-ui\src\components\createTask\components\noData.vue
-->
<template>
  <div class="no-data-page" :style="{'border-bottom': type === 'task'? '1px solid #adb0b8' : 'none', 'top': type === 'task'? '0px' : '30px' }">
    <img v-if="showImg" src="@/assets/img/no-data-small.png" alt="暂无数据" />
    <div>暂无数据</div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    type: {
      type:String,
      default: 'task'
    },
    showImg: {
      type:Boolean,
      default: true
    }
  },
  methods: {},
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="less" scoped>
.no-data-page {
  text-align: center;
  position: relative;
  box-sizing: border-box;
  padding: 13px 10px;
  margin-right: 20px;
  border: 1px solid transparent;
  border-bottom-color: #adb0b8;
  cursor: pointer;
  color: #adb0b8;
  &:hover {
  }
}
</style>