/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-05-24 10:28:22
 * @LastEditTime: 2022-07-11 15:25:16
 * @FilePath: \ddh-ui\src\mock\common\index.js
 */
const avatars = [
]

const positions = [
]

const sayings = [
]

const logos = [
]

const admins = ['ICZER', 'JACK', 'LUIS', 'DAVID']

const groups = []

const users = [
]

const teams = []

export {logos, sayings, positions, avatars, admins, groups, users, teams}
