/*
 * @Author: zhang·xiao
 * @Date: 2022-11-29 13:37:16
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2022-11-30 01:04:09
 * @Description: 描述文件功能
 */
export default {
  name: 'DatePickerWidget',
  functional: true,
  render(h, context) {
    const { isNumberValue, isRange, ...otherProps } = context.data.attrs || {};

    context.data.attrs = {
      type: isRange ? 'daterange' : 'date',
      'value-format': isNumberValue ? 'timestamp' : 'yyyy-MM-dd',
      ...otherProps
    };

    const oldInputCall = context.data.on.input;
    context.data.on = {
      ...context.data.on,
      input(val) {
        const trueVal = val === null ? (isRange ? [] : undefined) : val;
        oldInputCall.apply(context.data.on, [trueVal]);
      }
    };

    return h('el-date-picker', context.data, context.children);
  }
};
