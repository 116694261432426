<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-08-03 17:14:47
 * @LastEditTime: 2023-12-25 14:34:29
 * @FilePath: \awx-ui\src\components\createTask\chooseTemplete\index.vue
-->
<template>
  <div class="template-list">
    <div class="system-template">
      <div v-if="systemTemplateList.length >0" class="flex-warp">
        <div v-for="(item, index) in systemTemplateList" :key="index" class="template-container">
          <TemplateItem :templateItem="item" :selectedTemplateId="selectedTemplateId" @selectedTemplate="selectedTemplate" />
        </div>
      </div>
      <div v-else>
        <NoData />
      </div>
    </div>
  </div>
</template>
<script>
import NoData from "../components/noData.vue";
import TemplateItem from "../components/chooesItem.vue";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  components: { NoData, TemplateItem },
  props: {
    systemTemplateList: Array,
    customTemplateList: Array,
  },
  data() {
    return {
      selectedTemplateId: "",
    };
  },
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
  },
  methods: {
    ...mapActions("task", [
      "setTaskInfo",
      "setShowStepsList",
      "setSelectTemplateIndex",
      "setCurrentOptIndex"
    ]),
   async selectedTemplate(templateItem) {
      this.selectedTemplateId = templateItem.id;
      const type = Number(templateItem.type) === 0 ? "system" : "custom";
      this.$emit("seletCate", type);
      const taskInfo = {
        ...templateItem,
        template_id: templateItem.id,
        taskTemplateDetail: {
          operations: templateItem.operations,
          configs: templateItem.configs,
          notify: templateItem.notify
        },
      };
      if (templateItem.id === "_blank") {
        const res = await this.$axiosGet(global.API.getConfigByStepsId + "/" + 'manual' +'/')
        let manualSchedule = {
            ...res.data, application_name: '调度方式', currentStepsIndex: 2, rules: {
            timer: ['scheduletype', 'start_time', 'end_time', 'cron'],
            once: ['runtime']
          }
        }
        manualSchedule.schema['schema']['required'] = []
        taskInfo.name = ''
        taskInfo.taskTemplateDetail.operations = [{...manualSchedule}, {application_name: '请选择一个应用',isEmpty: true}]
      }
      this.setTaskInfo(taskInfo);
      //  空白模版还是展示步骤
      if (templateItem.id === "_blank") {
        setTimeout(() => {
          this.$emit("selectedTemplate", templateItem.id);
        },0)
        return false;
      }
      // 如果有应用id 那就到选择操作的界面
      if (templateItem?.operations[0]?.application_id) {
        this.setCurrentOptIndex(1)
      } else {
        this.setCurrentOptIndex(0)
      }
      // 如果有操作也就是有步骤id 那么就展示配置参数的页面
      if (templateItem?.operations[0]?.id) {
        this.setCurrentOptIndex(2)
      }
      if (templateItem?.operations[0]?.application_id && templateItem?.operations[0]?.id) {
        if (templateItem.type !== '1') {
          this.setCurrentOptIndex(4)
        } else {
          this.setCurrentOptIndex(2)
        }
      }
      this.setShowStepsList(false);
      this.setSelectTemplateIndex(0);
      setTimeout(() => {
        this.$emit("selectedTemplate", templateItem.id);
      },0)
    },
  },
};
</script>
<style lang="less" scoped>
.template-list {
  // padding-right: 50px;
  .system-template,
  .custom-template {
    &-title {
      padding-bottom: 16px;
      font-size: 18px;
      color: #1d202d;
      letter-spacing: 0;
      font-weight: 600;
      // border: 1px solid transparent;
      // border-bottom-color: #adb0b8;
      // border-bottom: 1px solid var(--devui-line, #adb0b8);
    }
  }
  .flex-warp {
    display: flex;
    flex-wrap: wrap;
    width: 989px;
  }
  .template-container {
    margin: 16px 30px 16px 0;
    width: calc(20% - 24px);
    height: 120px;
    &:nth-child(5n) {
      margin: 16px 0 16px 0;
    }
  }
}
</style>