/*
 * @Author: mjzhu
 * @Date: 2022-05-24 10:22:10
 * @LastEditTime: 2022-07-19 10:36:47
 * @FilePath: \awx-ui\src\api\httpApi\services.js
 */
import paths from '@/api/baseUrl'// 后台服务地址

let path = paths.path().path


// console.log(path, '请求的地址')
export default {
  quickFileSync: path + `/quick-file-sync`,  // 文件同步
  quickTasks: path + `/tasks/`,  // 执行历史
  quickTaskDetail: path + `/task_histories/`,  // 执行历史详情
}
