/*
 * @Author: zhang·xiao
 * @Date: 2022-11-29 13:33:57
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2022-11-29 14:13:50
 * @Description: 描述文件功能
 */

import { getWidgetConfig, optionsList } from '@formEngine/utils/formUtils';

import Widget from '../../components/Widget';
import vueProps from '../props';

export default {
  name: 'BooleanField',
  props: vueProps,
  functional: true,
  render(h, context) {
    const {
      schema, uiSchema, curNodePath, rootFormData, globalOptions
    } = context.props;

    // Bool 会默认传入枚举类型选项 true false
    const enumOptions = optionsList({
      enumNames: schema.enumNames || ['true', 'false'],
      enum: schema.enum || [true, false]
    }, uiSchema, curNodePath, rootFormData);

    const widgetConfig = getWidgetConfig({
      schema,
      uiSchema,
      curNodePath,
      rootFormData
    }, () => ({
      widget: globalOptions.WIDGET_MAP.types.boolean
    }));

    widgetConfig.uiProps.enumOptions = widgetConfig.uiProps.enumOptions || enumOptions;

    return h(
      Widget,
      {
        ...context.data,
        props: {
          ...context.props,
          ...widgetConfig
        }
      }
    );
  }
};
