import {
  isRootNodePath, path2prop, getPathVal, setPathVal
} from '@formEngine/utils/vueUtils';

import { validateFormDataAndTransformMsg } from '@formEngine/utils/schema/validate';
import { IconQuestion } from '@formEngine/utils/icons';
import { fallbackLabel } from '@formEngine/utils/formUtils';
import eventBus from '@formEngine/utils/eventBus'
import { debounce } from 'lodash'
import { fetchMaps } from './FetchMaps'
// 请求类型
export default {
  name: 'Widget',
  inject: ['genFormProvide'],
  props: {
    // 是否同步formData的值，默认表单元素都需要
    // oneOf anyOf 中的select属于formData之外的数据
    isFormData: {
      type: Boolean,
      default: true
    },
    // isFormData = false时需要传入当前 value 否则会通过 curNodePath 自动计算
    curValue: {
      type: null,
      default: 0
    },
    schema: {
      type: Object,
      default: () => ({})
    },
    uiSchema: {
      type: Object,
      default: () => ({})
    },
    errorSchema: {
      type: Object,
      default: () => ({})
    },
    customFormats: {
      type: Object,
      default: () => ({})
    },
    // 自定义校验
    customRule: {
      type: Function,
      default: null
    },
    widget: {
      type: [String, Function, Object],
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    // 解决 JSON Schema和实际输入元素中空字符串 required 判定的差异性
    // 元素输入为 '' 使用 emptyValue 的值
    emptyValue: {
      type: null,
      default: undefined
    },
    // 部分场景可能需要格式化值，如vue .number 修饰符
    formatValue: {
      type: [Function],
      default: val => ({
        update: true,
        value: val
      })
    },
    rootFormData: {
      type: null
    },
    curNodePath: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    // width -> formItem width
    width: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    // Widget attrs
    widgetAttrs: {
      type: Object,
      default: () => ({})
    },
    // Widget className
    widgetClass: {
      type: Object,
      default: () => ({})
    },
    // Widget style
    widgetStyle: {
      type: Object,
      default: () => ({})
    },
    // Field attrs
    fieldAttrs: {
      type: Object,
      default: () => ({})
    },
    // Field className
    fieldClass: {
      type: Object,
      default: () => ({})
    },
    // Field style
    fieldStyle: {
      type: Object,
      default: () => ({})
    },
    // props
    uiProps: {
      type: Object,
      default: () => ({})
    },
    formProps: null,
    getWidget: null,
    widgetListeners: null, // widget组件 emits
    renderScopedSlots: null, // 作用域插槽
    renderChildren: null, // 子节点 插槽
    globalOptions: null, // 全局配置
    onChange: null
  },
  data(){
    return { 
      fetchOptions: null,
      dependProps: {},
      fetch: debounce(this.fetchApi, 500),
      depend: debounce(this.dependFn, 500),
    }
  },
  computed: {
    value: {
      get() {
        if (this.isFormData) {
          return getPathVal(this.rootFormData, this.curNodePath);
        }
        return this.curValue;
      },
      set(value) {
        // 大多组件删除为空值会重置为null。
        const trueValue = (value === '' || value === null) ? this.emptyValue : value;
        if (this.isFormData) {
          setPathVal(this.rootFormData, this.curNodePath, trueValue);
        } else {
          this.$emit('onOtherDataChange', trueValue);
        }
        eventBus.$emit(this.curNodePath, trueValue );
      }
    }
  },
  methods: {
    fetchApi(){
      let params = {};
      const api = this.schema?.fetch?.api;
      const transfromParams = this.schema?.fetch?.transfromParams;
      const transfromData = this.schema?.fetch?.transfromData;
      const keys = Object.keys(this.schema?.fetch?.params || {});
      const values = Object.values(this.schema?.fetch?.params || {});
      keys.forEach((e, i) => {
        try {
          params[e] = getPathVal(this.rootFormData, values[i])
        } catch(error) {
          console.error(`${values[i]} 值无法获取，请仔细检查schema-params配置`)
        }
      })
      if (transfromParams) {
        let fn = transfromParams;
        if (typeof transfromParams === 'string') {
          fn = new Function(`return ${transfromParams}`)()
        }
        try {
          params = fn(params) || params;
        } catch(err) {
          console.error(`transfromParams转换函数出错，请仔细检查schema-transfromParams配置`)
        }
      }
      let realApi = api;
      const fReg = /^function.*/;
      if (fReg.test(api)) {
        try {
          const apiFn = new Function(`return ${api}`)();
          realApi = apiFn(params);
        } catch (error) {
          console.log('Api 参数存在')
        }
      }
      this[fetchMaps[this.schema?.fetch?.type] || '$axiosGet'](realApi, params)
        .then(res => {
          let data = res.data;
          if (transfromData) {
            let fn = transfromData;
            if (typeof transfromData === 'string') {
              fn = new Function(`return ${transfromData}`)()
            }
            try {
              data = fn(data, params) || data.data;
            } catch(err) {
              console.error(`transfromData转换函数出错，请仔细检查schema-transfromData配置`)
            }
          }
          if (Array.isArray(data)) {
            if (data.length && data?.[0]?.label && data?.[0]?.value) {
              this.fetchOptions = data || [];
              // 配置是否清空无效值
              if (this.schema?.fetch?.needEmptyValue) {
                const values = this.fetchOptions.map(e => e.value);
                if (Array.isArray(this.value)) {
                  let nVals = this.value.filter(e => (values.includes(e)));
                  if (nVals.length != this.value.length) {
                    this.value = nVals;
                  }
                } else {
                  if (!values.includes(this.value)) {
                    this.value = undefined;
                  }
                }
              }
            }
          } else {
            console.error(
              `接口数据格式不符合要求,请与前后端沟通调整,或者联调人员通过transfromData进行结果转换`,
              '数据格式见打印:\n',
              [{label: '选项名称', value: '选项值'}]
            )
          }
        })
        .catch(e => {
          console.error(
            `form-schema中配置的fetch信息错误，请仔细检查配置的${this?.schema?.fetch?.api}接口是否正确,且接口返回值模型需与打印一致？`,
            [{label: '选项名称', value: '选项值'}]
          )
        })
    },
    onParamChange() {
      const params = this.schema?.fetch?.params || {}
      const values = Object.values(params)
      for (let i = 0; i < values.length; i++) {
        if (values[i]) eventBus.$on(values[i], this.fetch)
      }
    },
    offParamChange() {
      const params = this.schema?.fetch?.params || {}
      const values = Object.values(params)
      for (let i = 0; i < values.length; i++) {
        eventBus.$off(values[i], this.fetch)
      }
    },
    dependFn() {
      let params = {};
      const keys = Object.keys(this.schema?.dependOnProps);
      const values = Object.values(this.schema?.dependOnProps);
      keys.forEach((e, i) => {
        try {
          params[e] = getPathVal(this.rootFormData, values[i])
        } catch(error) {
          console.error(`${values[i]} 值无法获取，请仔细检查schema-params配置`)
        }
      })
      this.dependProps = params;
    },
    onDependChange() {
      const params = this.schema?.dependOnProps || {}
      const values = Object.values(params)
      for (let i = 0; i < values.length; i++) {
        if (values[i]) eventBus.$on(values[i], this.depend)
      }
    },
    offDependChange() {
      const params = this.schema?.dependOnProps || {}
      const values = Object.values(params)
      for (let i = 0; i < values.length; i++) {
        eventBus.$off(values[i], this.depend)
      }
    }
  },
  created() {
    // 枚举类型默认值为第一个选项
    if (this.uiProps.enumOptions
            && this.uiProps.enumOptions.length > 0
            && this.value === undefined
            && this.value !== this.uiProps.enumOptions[0]
    ) {
      // array 渲染为多选框时默认为空数组
      if (this.schema.items) {
        this.value = [];
      } else if (this.required && this.formProps.defaultSelectFirstOption) {
        this.value = this.uiProps.enumOptions[0].value;
      }
    }
  },
  mounted() {
    if (this.schema?.fetch?.needInit) {
      this.fetch();
    }
    if (this.schema?.fetch) {
      this.onParamChange();
    }
    if (this.schema?.dependOnProps) {
      this.onDependChange();
    }
  },
  beforeDestroy() {
    // 关闭fetch依赖属性监听
    if (this.schema?.fetch) {
      this.offParamChange();
    }
    // 关闭依赖属性监听
    if (this.schema?.dependOnProps) {
      this.offDependChange();
    }
  },
  render(h) {
    const self = this;

    const { curNodePath } = this.$props;

    // 判断是否为根节点
    const isRootNode = isRootNodePath(curNodePath);

    const isMiniDes = self.formProps && self.formProps.isMiniDes;
    const miniDesModel = isMiniDes || self.globalOptions.HELPERS.isMiniDes(self.formProps);

    const descriptionVNode = (self.description) ? h(
      'div',
      {
        domProps: {
          innerHTML: self.description
        },
        class: {
          genFromWidget_des: true,
          genFromWidget_des_mini: miniDesModel
        }
      },
    ) : null;

    const { COMPONENT_MAP } = self.globalOptions;

    const miniDescriptionVNode = (miniDesModel && descriptionVNode) ? h(COMPONENT_MAP.popover, {
      style: {
        margin: '0 2px',
        fontSize: '16px',
        cursor: 'pointer'
      },
      props: {
        placement: 'top',
        trigger: 'hover'
      }
    }, [
      descriptionVNode,
      h(IconQuestion, {
        slot: 'reference'
      })
    ]) : null;

    // form-item style
    const formItemStyle = {
      ...self.fieldStyle,
      ...(self.width ? {
        width: self.width,
        flexBasis: self.width,
        paddingRight: '10px'
      } : {})
    };

    // 运行配置回退到 属性名
    const label = fallbackLabel(self.label, (self.widget && this.genFormProvide.fallbackLabel), curNodePath);
    // const ellipsisLabel = h(COMPONENT_MAP.popover, {
    //   props: { trigger: 'hover', content: label }
    // }, [[label, h('span', { slot: 'reference' }, [label])]])
    return h(
      COMPONENT_MAP.formItem,
      {
        class: {
          ...self.fieldClass,
          genFormItem: true
        },
        style: formItemStyle,
        attrs: self.fieldAttrs,
        props: {
          ...self.labelWidth ? { labelWidth: self.labelWidth } : {},
          ...this.isFormData ? {
            // 这里对根节点打特殊标志，绕过elementUi无prop属性不校验
            prop: isRootNode ? '__$$root' : path2prop(curNodePath),
            rules: [
              {
                validator(rule, value, callback) {
                  if (isRootNode) value = self.rootFormData;

                  // 校验是通过对schema逐级展开校验 这里只捕获根节点错误
                  const errors = validateFormDataAndTransformMsg({
                    formData: value,
                    schema: self.$props.schema,
                    uiSchema: self.$props.uiSchema,
                    customFormats: self.$props.customFormats,
                    errorSchema: self.errorSchema,
                    required: self.required,
                    // propPath: path2prop(curNodePath)
                  });
                  if (errors.length > 0) return callback(errors[0].message);

                  // customRule 如果存在自定义校验
                  const curCustomRule = self.$props.customRule;
                  if (curCustomRule && (typeof curCustomRule === 'function')) {
                    return curCustomRule({
                      field: curNodePath,
                      value,
                      rootFormData: self.rootFormData,
                      callback
                    });
                  }

                  return callback();
                },
                trigger: 'change'
              }
            ]
          } : {},
        },
        scopedSlots: {
          // 错误只能显示一行，多余...
          error: props => (props.error ? h('div', {
            class: {
              formItemErrorBox: true
            },
            attrs: {
              title: props.error
            }
          }, [props.error]) : null),
        },
      },
      [
        label ? h('span', {
          slot: 'label',
          class: {
            genFormLabel: true,
            genFormItemRequired: self.required,
          },
        }, [
          h('span', { attrs: { title: label } }, [label]),
          miniDescriptionVNode,
          `${(self.formProps && self.formProps.labelSuffix) || ''}`
        ]) : null,
        // 非mini模式显示 description
        !miniDesModel ? descriptionVNode : null,
        h( // 关键输入组件
          self.widget,
          {
            ref: 'widgetRef',
            style: self.widgetStyle,
            class: self.widgetClass,
            attrs: {
              ...self.widgetAttrs,
              ...self.uiProps,
              ...self.dependProps,
              enumOptions: self.fetchOptions || self.uiProps.enumOptions,
              value: this.value, // v-model
            },
            ...(self.renderScopedSlots ? {
              scopedSlots: self.renderScopedSlots(h) || {}
            } : {}),
            on: {
              ...self.widgetListeners ? self.widgetListeners : {},
              'hook:mounted': function widgetMounted() {
                if (self.widgetListeners && self.widgetListeners['hook:mounted']) {
                  // eslint-disable-next-line prefer-rest-params
                  self.widgetListeners['hook:mounted'].apply(this, [...arguments]);
                }

                // 提供一种特殊的配置 允许直接访问到 widget vm
                if (self.getWidget && typeof self.getWidget === 'function') {
                  self.getWidget.call(null, self.$refs.widgetRef);
                }
              },
              input(event) {
                const formatValue = self.formatValue(event);
                // 默认用户输入变了都是需要更新form数据保持同步，唯一特例 input number
                // 为了兼容 number 小数点后0结尾的数据场景
                // 比如 1. 1.010 这类特殊数据输入是不需要触发 新值的设置，否则会导致schema校验为非数字
                // 但由于element为了解另外的问题，会在nextTick时强制同步dom的值等于vm的值所以无法通过这种方式来hack，这里旧的这份逻辑依旧保留 不过update一直为true
                const preVal = self.value;
                if (formatValue.update && preVal !== formatValue.value) {
                  self.value = formatValue.value;
                  if (self.onChange) {
                    self.onChange({
                      curVal: formatValue.value,
                      preVal,
                      parentFormData: getPathVal(self.rootFormData, self.curNodePath, 1),
                      rootFormData: self.rootFormData
                    });
                  }
                }

                if (self.widgetListeners && self.widgetListeners.input) {
                  // eslint-disable-next-line prefer-rest-params
                  self.widgetListeners.input.apply(this, [...arguments]);
                }
              }
            }
          },
          self.renderChildren ? self.renderChildren(h) : null
        )
      ]
    );
  }
};
