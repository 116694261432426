/*
 * @Author: mjzhu
 * @Date: 2022-05-24 10:22:10
 * @LastEditTime: 2022-08.24-19 18:41:09
 * @FilePath: \awx-ui\src\api\httpApi\dispath.js
 */
  
  
import paths from '@/api/baseUrl'// 后台服务地址

let path = paths.path().path

export default {
  getCount:path+'/dashboard/counts',  //计数
  getAnnouncementOperation:path+'/dashboard/jobs_stats'  ,//图表
  getActive:path+'/dashboard/tasks'  ,           //公告活跃
  getRun:path+'/dashboard/jobs'
}
