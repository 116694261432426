<!--
 * @Author: mjzhu
 * @Date: 2022-06-08 11:38:30
 * @LastEditTime: 2024-01-17 20:06:41
 * @FilePath: \awx-ui\src\pages\operationConsole\components\bottomOpe\addCmd.vue
-->
<template>
  <div class="quota-order">
    <div class="title">订单信息</div>
    <div class="account-container">
      <span class="label">购买账号</span>
      <span class="account">{{userName}}</span>
    </div>
    <div
      class="quoto-bg"
      :style="{background: `url(${contactCard}) no-repeat`}"
    >
      <span class="name">{{ quotaDetail?.name }}</span>
      <span class="tips ell" :title="quotaDetail?.description || ''">{{ quotaDetail?.description || '' }}</span>
      <span class="express">有效期至: {{ expriseTime }}</span>
    </div>
    <div class="code">
      <!-- <img
        :src="src"
        alt=""
        style="width: 98%;"
      > -->
      <canvas id="qrcodeCanvas"></canvas>
    </div>
    <div class="scan">微信扫一扫</div>
    <div class="opt-tips">付款完成后5秒内生效，或手动刷新立马生效</div>
    <a-button  class="btn-back" type="primary" @click="formCancel">返回</a-button>
  </div>
</template>
  <script>
import moment from 'moment'
export default {
  components: {
  },
  props: {
    quotaType: String,
    quotaDetail: {
      type: Object,
      default: function () {
        return {};
      },
    },
    currentTab: String,
    callBack: Function,
    step: Number
  },
  data () {
    return {
      contactCard: require("@/assets/img/quota.jpg"),
      timer: null,
      uuid: '',
      src: '',
    };
  },
  watch: {},
  computed: {
    userName () {
      const user = JSON.parse(localStorage.getItem('admin.user'))
      return user?.username
    },
    expriseTime () {
        const now = moment();
        // 将当前时间加9个月
        const result = now.add(this.quotaDetail.month, 'months');

        // 打印结果
        return result.format('YYYY/MM/DD')
    }
  },

  methods: {
    formCancel () {
      clearInterval(this.timer)
      this.timer = null
      if (this.quotaType === 'apply')  return this.$emit('toApply')
      this.$destroyAll();
    },
    getCode () {
      const self = this
      clearInterval(this.timer)
      this.timer = null
      let apiAjax = global.API.getManagementList + '/' + this.quotaDetail.id + '/pay'
      fetch(apiAjax, {
        method: 'POST',
        headers: {
          'token': localStorage.getItem('token')
        }
      })
        .then(
          response => response.json()
        )
        .then(data => {
          if (data.code === 200) {
            const code = data.url
            const canvas = document.getElementById('qrcodeCanvas');
            // 设置Canvas的宽度和高度
            const width = 96; // 替换为你想要的宽度
            const height = 96; // 替换为你想要的高度
            canvas.width = width;
            canvas.height = height;
             new QRCode.toCanvas(canvas, code, function (error) {
              if (error) console.error(error);
            });
            clearInterval(this.timer)
            this.timer = null 
            this.timer = setInterval(() => {
              this.getStatus(data.order_number)
          }, 3000)
          } else {
            self.$message.error('当前二维码获取失败')
          }
        })
        .catch(error => {
          self.$message.error(error)
        });
    },
    // 获取状态
    getStatus (orderNo) {
      const self = this
      this.$axiosGet(global.API.getQuotaPayStatus + '/' +orderNo + '/status', {}).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          if (res.data.code === 200) {
            self.$message.success('订阅成功')
            clearInterval(self.timer);
            self.timer = null
            self.$emit('toMyquota')
            self.$destroyAll()
          }
        }
      });
    },
  },
  mounted () {
    this.uuid = global.utils.generateUUID()
    this.getCode()
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  }
};
</script>
    <style lang="less" scoped>

.quota-order {
  width: 320px;
  height: 460px;
  background-image: linear-gradient(0deg, #ffffff 70%, #f3f6f7 92%);
  border: 1px solid rgba(223, 225, 227, 1);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  margin: 20px auto 30px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 17px;
    color: #000000;
    letter-spacing: 0;
    font-weight: 500;
  }
  .account-container {
    font-size: 14px;
    color: #000000;
    letter-spacing: 0;
    font-weight: 400;
    margin: 10px 16px;
    span {
      display: inline-block;
    }
    .label {
      margin-right: 10px;
    }
  }
  .quoto-bg {
    width: 240px;
    height: 110px;
    background-size: 100% 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 16px 0;
    span {
      display: block;
    }
    .name {
      font-size: 17px;
      color: #000000;
      letter-spacing: 0;
      font-weight: 550;
    }
    .tips, .express{
      max-width: 200px;
      font-size: 14px;
      color: #4d5b7b;
      letter-spacing: 0;
      font-weight: 400;
    }
    .tips {
      // margin: 6px 0;
    }
  }
  .code {
    width: 98px;
    height: 98px;
    border: 1px solid #f6f7f8;
    margin: 48px 0 12px;
    canvas {
      width: 96px!important;
      height: 96px!important;
    }
  }
  .scan, .opt-tips {
    font-size: 14px;
    color: #4D5B7B;
    letter-spacing: 0;
    font-weight: 400;
  }
  .opt-tips {
    margin: 6px 0 14px;
    font-size: 13px;
  }
  .btn-back {
    width: 100%;
    border-radius: 5px;
  }
}
</style>
    