const configTools = [
  {
    groupName: '自定义组件',
    componentList: [{
      title: '调度组件',
      btnClass: 'w100',
      componentPack: 'schedule',
      componentPath: '@/components/customization/schedule.vue'
    }]
  }
];
const loadComponents = {
  "schedule": () => import("@/components/customization/schedule.vue"),
}

export {configTools, loadComponents} 