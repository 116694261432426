<template>
    <div class="box">
        <div class="btns">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
  name: 'EditorHeader',
  components: {},
  props: {
    version: {
      default: 'vue2',
      type: String
    },
    showVersion: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    handleVersionChange(val) {
      // eslint-disable-next-line no-unused-vars
      const { ui, ...query } = this.$route.query;
      const genRoute = this.$router.resolve({ query });
      window.location.href = `${(val === 'vue3' ? '/v3/' : '/')}${genRoute.href}`;
    },
  }
};
</script>

<style lang="less" scoped>
    .box {
      display: flex;
      padding: 12px 24px 0;
      justify-content: flex-start;
      align-items: center;
      background-color: #f7f8fb;
      .btns {
          display: flex;
          text-align: right;
          align-items: center;
          justify-content: center;
          /deep/.el-button {
            height: 32px;
            padding: 0 12px;
          }
          .el-button {
            &:first-child {
              margin-right: 12px;
            }
          }
      }
    }
</style>
