/*
 * @Author: zhang·xiao
 * @Date: 2022-11-29 13:33:57
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2022-11-29 22:04:03
 * @Description: 描述文件功能
 */

import { getWidgetConfig } from '@formEngine/utils/formUtils';
import vueProps from '../../props';
import Widget from '../../../components/Widget';

export default {
  name: 'ArrayFieldSpecialFormat',
  props: vueProps,
  functional: true,
  render(h, context) {
    const {
      schema, uiSchema, curNodePath, rootFormData, globalOptions
    } = context.props;
    const widgetConfig = getWidgetConfig({
      schema: {
        'ui:widget': globalOptions.WIDGET_MAP.formats[schema.format],
        ...schema
      },
      uiSchema,
      curNodePath,
      rootFormData
    });

    return h(
      Widget,
      {
        ...context.data,
        props: {
          ...context.props,
          ...widgetConfig
        }
      }
    );
  }
};
