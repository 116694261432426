<!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2023-12-25 16:53:01
 * @FilePath: \awx-ui\src\components\createTask\deploystep\selectOpt.vue
-->
<template>
  <div class="select-opt-list">
    <a-spin :spinning="spinning">
    <div v-if="selectOptList && selectOptList.length > 0" class="operation-steps-list">
      <div class="operation-steps-item" v-for="(item, index) in selectOptList" :key="index">
        <div :class="[stepsId === item.id ? 'opt-item-selected' : '', 'opt-item']" @click="selectOpt(item)">
          <div class="name" :title="item.name">{{ item.name }}</div>
          <div class="description" :title="item.description">{{ item.description }}</div>
        </div>
      </div>
    </div>
    <div v-else>
      <NoData />
    </div>
  </a-spin>
  </div>
</template>
  <script>
import { mapState, mapMutations, mapActions } from "vuex";
import NoData from "../components/noData.vue";
import { validateStep } from './validate'

export default {
  name: "OperationList",
  components: { NoData },
  props: {
    taskType: {
      type: String,
      default: "task",
    },
  },
  watch: {
    taskId: {
      handler(val) {
        if (val) {
          this.eidtFlag = true;
        }
      },
      immediate: true,
    },
    // 从模板那边进来的 隐藏右边的模板
    taskType: {
      handler(val) {
        if (val === "template") {
          this.eidtFlag = true;
        }
      },
      immediate: true,
    },
    currentAppId: {
      handler(val) {
        if (val) {
          // this.getOptListByAppId(val);
        }
      },
      immediate: true,
    },
    "task.selectTemplateIndex": {
      handler() {
        const taskType = this.task?.taskInfo?.taskTemplateDetail?.operations[this.task.selectTemplateIndex]?.type
        // todo  如果是调度的不走这个逻辑
        if (taskType === 'schedule') return false
        this.getOptListByAppId(this.currentAppId)
      },
      // immediate: true,
    },
    "task.refreshFlag": {
      handler() {
        this.getOptListByAppId(this.currentAppId)
      },
    },
    "task.currentOptIndex": {
      handler() {
        this.getOptListByAppId(this.currentAppId)
      },
      // immediate: true,
    },
  },
  // inject: ['currentStepsSub'],
  data() {
    return {
      nextLoading: false,
      eidtFlag: false,
      spinning: false,
      selectOptList: [],
    };
  },
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    taskId() {
      const taskId = this.task.taskInfo.task_id || "";
      return taskId;
    },
    currentAppId() {
      let operationList = [];
      if (
        this.task &&
        this.task.taskInfo &&
        this.task.taskInfo.taskTemplateDetail &&
        this.task.taskInfo.taskTemplateDetail.operations
      )
      operationList = this.task.taskInfo.taskTemplateDetail.operations;
      return operationList[this.task.selectTemplateIndex].application_id;
    },
    opType () {
      return this.task.selectTemplateIndex === 0 ? "1" : '0';
    },
    stepsId() {
      let operationList = [];
      if (
        this.task &&
        this.task.taskInfo &&
        this.task.taskInfo.taskTemplateDetail &&
        this.task.taskInfo.taskTemplateDetail.operations
      )
        operationList = this.task.taskInfo.taskTemplateDetail.operations;
      let id =
        operationList[this.task.selectTemplateIndex] &&
        operationList[this.task.selectTemplateIndex].id
          ? operationList[this.task.selectTemplateIndex].id
          : "";
      return id;
    },
  },
  methods: {
    ...mapActions("task", ["setTaskInfo", "setSelectTemplateIndex", "setCurrentOptIndex", "setCurrentPageIndex"]),
    getOptListByAppId(id) {
      if (!id) return false
      this.spinning = true;
      this.$axiosGet(global.API.getApp + "/" + id + "/operations?op_type=" + this.opType).then(
        (res) => {
          this.spinning = false;
          if ([200, 201, 204, 202].includes(res.status)) {
            this.selectOptList = res.data.results;
          }
        }
      );
    },
    getConfigByStepsId (id, operationList) {
      this.$axiosGet(global.API.getConfigByStepsId + "/" + id +'/').then(
        (res) => {
          this.spinning = false;
          if ([200, 201, 204, 202].includes(res.status)) {
            const obj = {
              ...operationList[this.task.selectTemplateIndex],
              ...res.data,
              icon: operationList[this.task.selectTemplateIndex].icon || res.data.icon,
              application_name: operationList[this.task.selectTemplateIndex].application_name
            }
            operationList[this.task.selectTemplateIndex] = obj
            let taskInfo = _.cloneDeep(this.task.taskInfo);
            taskInfo.taskTemplateDetail.operations = operationList;
            this.setTaskInfo(taskInfo);
             // 如果操作配置完成了，那么配置参数tab为完成态
             const flag = validateStep(obj)
             this.setCurrentOptIndex(2)
             this.setCurrentPageIndex(2) 
            //  if (flag) {
            //   this.setCurrentOptIndex(4)
            //   this.setCurrentPageIndex(4) 
            // } else {  
            //   this.setCurrentOptIndex(3)
            //   this.setCurrentPageIndex(3) 
            // }
          }
        }
      );
    },
    // 选择操作
    selectOpt(templateItem) {
      const selectItem = _.cloneDeep(templateItem)
      let operationList = [];
      if (
        this.task &&
        this.task.taskInfo &&
        this.task.taskInfo.taskTemplateDetail &&
        this.task.taskInfo.taskTemplateDetail.operations
      )
        operationList = _.cloneDeep(
          this.task.taskInfo.taskTemplateDetail.operations
        );
        const obj = {
          ...operationList[this.task.selectTemplateIndex],
          ...selectItem,
          icon: operationList[this.task.selectTemplateIndex].icon || selectItem.icon,
          application_name: operationList[this.task.selectTemplateIndex].application_name
        }
      operationList[this.task.selectTemplateIndex] = obj

      // TODO 这个地方需要控制选择的应用是否有授权配置 如果没有的话 走下面的逻辑 如果没有就直接到第三步
      this.getConfigByStepsId(selectItem.id, operationList)
    },
  },
  mounted() {
    this.getOptListByAppId(this.currentAppId)
  },
};
</script>
  <style lang="less" scoped>
.select-opt-list {
  .operation-steps-list {
    overflow-y: auto;
    padding-right: 55px;
    max-height: 540px;
    margin-top: 20px;
    .operation-steps-item {
      .opt-item {
        padding: 16px 20px;
        cursor: pointer;
        widows: 100%;
        height: 78px;
        background: #ffffff;
        border: 1px solid rgba(219, 229, 249, 1);
        box-shadow: 0px 2px 6px 0px rgba(200, 212, 233, 0.1);
        border-radius: 6px;
        font-size: 14px;
        color: #7D8292;
        letter-spacing: 0;
        font-weight: 400;
        margin-bottom: 12px;
        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #242B3A;
          max-width: 610px;
        }
        .description {
          max-width: 610px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #7D8292;
          margin-top: 4px;
        }
      }
      .opt-item-selected, .opt-item:hover {
        border: 1px solid rgba(58, 116, 244, 0.75);
        box-shadow: 0px 2px 8px 0px;
      }
    }
  }
  .actions-empty {
    text-align: center;
    &-icon {
      height: 528px;
      width: 276px;
    }
  }
  .operation-box__tip {
    color: #8a8e99;
    font-size: 14px;
    margin-top: 30px;
    margin: 30px 20px 0 0;
    .devui-link {
      color: #526ecc;
    }
  }
  .submit-template {
    margin: 30px 20px 0 0;
    width: calc(100% - 20px);
    opacity: 0.9;
    background: #eef2f7;
    border-radius: 4px;
    font-size: 14px;
    color: #5a5a69;
    letter-spacing: 0;
    font-weight: 400;
  }
}
</style>