
const peerId = guid();
const peerOption = {
  initiator: true,
  host: 'alstra.cn',
  port: 7009,
  path: '/',
  secure: true,
  token: generateRandomToken(), // 自己实现随机生成token的函数
  key: 'peerjs',
  config: {
    connectionTimeout: 5000,
    iceServers: [{
      // urls: ['turn:alstra.cn:3478', 'stun:alstra.cn:3478'],
      // urls: ['stun:stun.l.google.com:19302', 'stun:stun1.l.google.com:19302'],
      urls: ['stun:stun.services.mozilla.com', 'stun:stun.l.google.com:19302', 'stun:stun1.l.google.com:19302'],
      username: 'admin',
      credential: 'admin@2024',
      credentialType: 'password',
    }, ],
    sdpSemantics: 'unified-plan',
  },
  debug: 1, // PeerJS 的 debug 级别可能与 Go 版本略有不同，这里设置为最大级别以尽量接近 Debug: 1
};
let reconnectAttempts = 0;
const MAX_RECONNECT_ATTEMPTS = 1000; // 最大重试次数
const RECONNECT_INTERVAL = 3000;

// 注册自己
const peer = new Peer(peerId, peerOption);
var conn = null
peer.on('connection', (c) => {
  console.info("the peer connection is open")
})
peer.on('open', () => {
  console.info("the peer is open")
})
peer.on('error', (err) => {
  console.error("the peer error", err)
  if (err.type == 'peer-unavailable') {
    console.log("the peer is unavailable")
  }
})
peer.on('disconnected', () => {
  console.info("the peer is disconnected")
  reconnectToPeer();
})

function reconnectToPeer() {
  if (reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
      console.error('Maximum reconnect attempts reached. Please check your connection and try again.');
      return;
  }
  reconnectAttempts++;
  setTimeout(() => {
      console.log(`Reconnecting attempt #${reconnectAttempts}...`);
      peer.reconnect();
  }, RECONNECT_INTERVAL);
}

peer.on('close', () => {
  console.info("the peer is closed")
})

let listeners = new Map()
let progressListeners = new Map()
let progressListenersDownload = new Map()
let connections = new Map()
// axios适配器
function shouldUsePeerJsForRequest(config) {
  // TODO 定义何种情况下通过WebRTC发送消息请求
  // return config.url.includes('uploadFile')
  // if (config && config.rtcscope === 'downLoad') {
  //   console.log(config, '下在的')
  //   return false
  // }
  return config.url.includes('cluster_id') || (config.params && config.params.cluster_id !== undefined)
}
function openDownLoadChannel (config) {
  let param = {
    rtcscope: "fileUpload",
    headers: {Accept: 'multipart/form-data', token: localStorage.getItem('token')},
    method: "get",
    ...config,
    responseType: "blob",
    withCredentials: true,
    xsrfCookieName: "sessionId",
    xsrfHeaderName: "sessionId"
  }
  let channel = getChannel(param, rtcscope, dispatchRtcResponseChunk)
  return new Promise((resolve, reject) => {
    channel.then(channel => {
      return resolve(sendRtcHttpRequest(channel, config))
    }).catch(err => {
      return reject(err)
    });
  });
}
const rtcAdapter = (config => {
  // 检查是否需要通过 PeerJS 传输此请求
  let theScope = "default"
  if (config.rtcscope){
    theScope = config.rtcscope
  }
  let channel = getChannel(config, theScope, dispatchRtcResponseChunk)
  return new Promise((resolve, reject) => {
    channel.then(channel => {
      return resolve(sendRtcHttpRequest(channel, config))
    }).catch(err => {
      return reject(err)
    });
  });
});

// 分片传输
class ArrayBufferSlicer {
  constructor(arrayBuffer, sliceSize = 32 * 1024) {
    this.arrayBuffer = arrayBuffer;
    this.sliceSize = sliceSize;
    this.slices = this._sliceArrayBuffer();
  }
  _sliceArrayBuffer() {
    const uint8View = new Uint8Array(this.arrayBuffer);
    const slices = [];
    for (let i = 0; i < uint8View.length; i += this.sliceSize) {
      slices.push(uint8View.subarray(i, i + this.sliceSize));
    }
    return slices;
  }
  getSlice(index) {
    if (index >= 0 && index < this.slices.length) {
      return this.slices[index];
    }
    throw new Error('Index out of range');
  }
  getSlicesCount() {
    return this.slices.length;
  }
  getChunk(index, requestId) {
    if (index >= 0 && index < this.slices.length) {
      const slice = this.slices[index];
      // requestId: ef5e7a47-6e99-4678-b29c-88ebc34b0cdf8032768, total: 多少个片, N:当前分片; Data: 负载
      // 0-35
      const requestIdBytes = new TextEncoder().encode(requestId);

      // 使用DataView确保大尾数字节序
      const totalBytesBE = new DataView(new ArrayBuffer(4));
      totalBytesBE.setUint32(0, this.slices.length, false); // false 表示大尾数

      const nBytesBE = new DataView(new ArrayBuffer(4));
      nBytesBE.setUint32(0, index, false); // false 表示大尾数
      const sliceBytes = slice

      // 计算chunk总大小
      const chunkSize = requestIdBytes.length + 4 + 4 + slice.byteLength; // 注意：每个DataView占4字节
      const chunk = new Uint8Array(chunkSize);
      let offset = 0;

      // 拼接chunk
      chunk.set(requestIdBytes, offset);
      offset += requestIdBytes.length;

      chunk.set(new Uint8Array(totalBytesBE.buffer), offset);
      offset += 4; // DataView转换的4字节

      chunk.set(new Uint8Array(nBytesBE.buffer), offset);
      offset += 4; // DataView转换的另4字节

      // chunk.set(new Uint8Array(slice.buffer), offset);
      // offset += slice.byteLength;
      chunk.set(sliceBytes, offset);
      offset += sliceBytes.length;

      return chunk;
    }
    throw new Error('Index out of range');
  }
}

// 通道管理
function getConnection(peerId, scope) {
  if (scope == null || scope == undefined) {
    scope = "default"
  }
  if (connections[scope] == null || connections[scope] == undefined) {
    connections[scope] = new Map()
  }
  return connections[scope][peerId]
}

function removeConnection(peerId, scope) {
  if (scope == null || scope == undefined) {
    scope = "default"
  }
  if (connections[scope] == null || connections[scope] == undefined) {
    connections[scope] = new Map()
  }
  delete connections[scope][peerId]
}

function putConnection(peerId, scope, conn) {
  if (scope == null || scope == undefined) {
    scope = "default"
  }
  if (connections[scope] == null || connections[scope] == undefined) {
    connections[scope] = new Map()
  }
  connections[scope][peerId] = conn
}
// 从请求消息中自动解析出对应的RTC通道
async function getChannel(config, scope, onData) {
  config.params = config.params || {};
  let originUrl = 'http://124.71.178.225:7007' + '/' + config.url
  let url = new URL(originUrl);
  let searchParams = new URLSearchParams(url.search);
  let targetPeerId = searchParams.get('cluster_id');
  if (!targetPeerId) {
    targetPeerId = config.params['cluster_id'];
  }
  // if (!targetPeerId) {
  //   targetPeerId = "32f1fc6e-913b-463e-a47a-327fc173a93c"
  // }
  console.log(targetPeerId, config, 'conn')
  // eslint-disable-next-line no-cond-assign
  let conn = getConnection(targetPeerId, scope)
  if (conn && conn._open && conn.peerConnection.iceConnectionState != 'disconnected') {
    return Promise.resolve(conn)
  } else {
    // 废弃之前的链接
    if (conn && conn.peerConnection.iceConnectionState == 'disconnected') {
      conn.close()
      removeConnection(targetPeerId, scope)
    }
    if (!peer.open) {
      // return Promise.reject("the peer is not open")
    }
    return await new Promise((resolve, reject) => {
      const channel = peer.connect(targetPeerId, {
        reliable: true,
        serialization: 'raw',
        metadata: {
          "url": config.url
        }
      });
      peer.on('connection', () => {
        console.log("the peer connection is open")
      })
      channel.on('open', () => {
        console.log("the channel is open")
        if (onData != null && onData != undefined) {
          channel.on('data', (data) => {
            onData(data, config)
          });
          // channel.dataChannel.onmessage = (event) => {onData(event.data)}
        }
        putConnection(targetPeerId, scope, channel)
        resolve(channel)
      });
      channel.on('close', () => {
        console.log("the channel is closed")
        removeConnection(targetPeerId, scope)
        reject("the channel is closed")
      });
      channel.on('error', (err) => {
        console.error("the channel error", err)
        reject(err)
      });
      channel.on('disconnected', (err) => {
        console.error("the channel is disconnected", err)
        removeConnection(targetPeerId, scope)
        reject(err)
      });
      // putChannel()
      peer.on('error', (err) => {
        if (err.type == 'peer-unavailable' && err.message == 'Could not connect to peer ' + targetPeerId) {
          reject(`目标节点[${targetPeerId}]不可用`);
        }
      });
    });
  }
}

// 创建组合消息
async function createCombinedMessage(metadata) {
  config = metadata.config
  delete metadata.config
  const boundary = '---------------------------WebKitFormBoundary' + Math.random().toString(36).substring(2);
  return new Promise((resolve, reject) => {
    if (config.data instanceof FormData) {
      metadata.headers['Content-Type'] = 'multipart/form-data; boundary=' + boundary;
    }
    const separator = String.fromCharCode(0, 0, 0); // Using ASCII null byte as separator
    // 如果是Form表单,需要将Form表单内容转换为二进制流
    if (config.data instanceof FormData) {
      // 定义一个stringparts数组
      const blobParts = [];
      let formDataArray = [];
      for (var pair of config.data.entries()) {
        formDataArray.push('--' + boundary + "\r\n");
        if (pair[1] instanceof File) {
          formDataArray.push(`Content-Disposition: form-data; name="${pair[0]}"; filename="${pair[1].name}"` + "\r\n");
          formDataArray.push(`Content-Type: ${pair[1].type}` + "\r\n");
          formDataArray.push('' + "\r\n");
          formDataArray.push(new Blob([pair[1]], {
            type: pair[1].type
          }));
          formDataArray.push('' + "\r\n");
        } else {
          formDataArray.push(`Content-Disposition: form-data; name="${pair[0]}"` + "\r\n");
          formDataArray.push('' + "\r\n");
          formDataArray.push(pair[1] + "\r\n");
        }
      }
      formDataArray.push('--' + boundary + '--');

      let combinedBlobPromises = formDataArray.map(item => {
        // 如果item是Blob对象，则读取为ArrayBuffer
        if (item instanceof Blob) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(item);
          });
        } else {
          return Promise.resolve(new TextEncoder().encode(item.toString()));
        }
      });
      // 使用Promise.all来等待所有Promise完成，然后组合它们的内容到一个Blob
      Promise.all(combinedBlobPromises).then(arrayBuffersAndEncodedStrings => {
        // 将所有内容片段连接成一个总的ArrayBuffer或Uint8Array数组
        let totalContent = new Uint8Array(
          arrayBuffersAndEncodedStrings.reduce((acc, curr) => acc + curr.byteLength, 0)
        );
        metadata.headers['Content-Length'] = totalContent.byteLength + ""; // 一定是字符串,否则go会置空
        let offset = 0;
        arrayBuffersAndEncodedStrings.forEach(chunk => {
          if (chunk instanceof ArrayBuffer) {
            totalContent.set(new Uint8Array(chunk), offset);
          } else { // 如果已经是Uint8Array，则直接设置
            totalContent.set(chunk, offset);
          }
          offset += chunk.byteLength;
        });
        // 根据需要，可以在这里添加边界字符串等处理逻辑，但直接创建Blob即可满足大多数情况
        const combinedData = new Blob([JSON.stringify(metadata), separator, totalContent], {
          type: 'multipart/form-data; boundary=' + boundary
        });
        resolve(combinedData);
        // 这里可以继续使用combinedBlob进行后续操作
      }).catch(error => {
        console.error("Failed to combine blobs and strings:", error);
      });

      // const combinedData = new Blob([metadataStr, separator, combinedBlob], { type: 'application/octet-stream' });
      // resolve(combinedData);
      // const reader = new FileReader();
      // reader.onloadend = () => {
      //   const combinedData = new Blob([metadataStr, separator, reader.result], { type: 'application/octet-stream' });
      //   resolve(combinedData);
      // };
      // reader.onerror = reject;
      // reader.readAsArrayBuffer(fileBlob);
    } else {
      resolve(new Blob([JSON.stringify(metadata), separator, config.data], {
        type: 'application/octet-stream'
      }));
    }
  });
}

// 通过HTTP发送RTC消息请求
async function sendRtcHttpRequest(channel, config) {
  let requestId = guid();
  let url = config.url
  if (config.url.includes('awx')) url = config.url.replace('awx', 'side')
  if (config.url.includes('uploadFile')) url = config.url.replace('uploadFile', 'side')
  // 将config.params中的所有非字符串数字类型参数转换为字符串
  for (const key in config.params) {
    if (typeof config.params[key] === 'number') {
      config.params[key] = config.params[key].toString();
    }
  }
  const request = {
    type: 'http-request',
    requestId: requestId,
    method: config.method.toUpperCase(),
    // url: localStorage.getItem('FILE_SYSTEM_URL') + '/' + url,
    url: config.url,
    headers: config.headers,
    params: config.params,
    config: config,
  };
  console.log('rtcUrl', request)
  // 添加监听器逻辑
  const result = await new Promise((resolve) => {
    // 注册一个临时的响应处理器
    const onResponse = (message) => {
      if (message.type === 'http-response' && message.requestId) {
        requestId = message.requestId
        listeners.delete(requestId);
        progressListeners.delete(requestId);
        let contentType = message.headers["Content-Type"]
        if (contentType == "application/octel-stream") {
          message.data = message.body;
        } else {
          try {
            message.data = JSON.parse(message.body);
          } catch (error) {
            message.data = message.body;
          }
        }
        delete message.body;
        message.config = config;
        console.log(request.url, message, 'sssss')
        resolve(message);
      } else {
        console.log("================= bad response")
      }
    };
    // 如果包含chunk监听器，则注册chunk监听器
    if (config.onUploadProgress) {
      // 注册chunk监听器
      config.onUploadProgress({"loaded": 0, "total": 1})
      progressListeners.set(requestId, (message) => {
        if (message.type === 'http-response-chunk' && message.requestId) {
          requestId = message.requestId
          try {
            message.data = JSON.parse(message.body);
          } catch (error) {
            message.data = message.body;
          }
          config.onUploadProgress(message.data)
        } else {
          console.error("bad response")
        }
      })
    }
    if (config.onDownloadProgress) {
      // 注册chunk监听器
      config.onDownloadProgress({"loaded": 0, "total": 1})
      progressListenersDownload.set(requestId, (message) => {
        if (message.type === 'http-response-chunk' && message.requestId) {
          requestId = message.requestId
          try {
            message.data = JSON.parse(message.body);
          } catch (error) {
            message.data = message.body;
          }
          config.onDownloadProgress(message.data)
        } else {
          console.error("bad response")
        }
      })
    }
    listeners.set(requestId, onResponse)
    // 发送请求
    let combinedMessage = createCombinedMessage(request);
    combinedMessage.then(combinedMessage => {
      // channel.send(combinedMessage);
      combinedMessage.arrayBuffer().then(arrayBuffer => {
        const slicer = new ArrayBufferSlicer(arrayBuffer);
        let totalCount = slicer.getSlicesCount()
        for (let i = 0; i < totalCount; i++) {
          const chunk = slicer.getChunk(i, request.requestId);
          channel.send(chunk);
        }
        // channel.send(new Uint8Array(arrayBuffer));
      });
    });
  });
  return result
}

async function dispatchRtcResponseChunk(data, config) {
  // 将data的array buffer 转换成字符串
  let chunk = processChunkMessage(data)
  if (chunk.n < chunk.total - 1) {
    // if (config.onUploadProgress) {
    if (progressListenersDownload.has(chunk.requestId)) {
      let progressProcess = progressListenersDownload.get(chunk.requestId)
      progressProcess({
        "requestId": chunk.requestId,
        "type": "http-response-chunk",
        "body": {
          "loaded": chunk.n,
          "total": chunk.total
        }
      })
    } else {
      // console.error("bad response")
      // }
    }
    return
  }
  let combineRequest = parseCombinedMessage(chunk.data)
  let decoder = new TextDecoder()
  let theText = decoder.decode(combineRequest.metadata)
  let message = JSON.parse(theText)
  if (message.type === 'http-response' && message.requestId) {
    if (message.headers['Content-Type'] == "application/octel-stream") {
      message.body = combineRequest.fileData
    } else {
      if (combineRequest.fileData) {
        message.body = decoder.decode(combineRequest.fileData)
      }
    }
    let requestId = message.requestId
    let httpResponseHandler = listeners.get(requestId)
    httpResponseHandler(message);
  } else if (message.type === 'http-response-chunk' && message.requestId) {
    if (progressListeners.has(message.requestId)) {
      let progressProcess = progressListeners.get(message.requestId)
      if (combineRequest.fileData) {
        message.body = decoder.decode(combineRequest.fileData)
      }
      progressProcess(message)
    } else {
      // console.error("bad response")
    }
  }
}

// 处理RTC消息响应
async function dispatchRtcResponse(data) {
  // 将data的array buffer 转换成字符串
  const dataString = new TextDecoder().decode(data);
  message = JSON.parse(dataString)
  if (message.type === 'http-response' && message.requestId) {
    requestId = message.requestId
    httpResponseHandler = listeners.get(requestId)
    httpResponseHandler(message);
  } else if (message.type === 'http-response-chunk' && message.requestId) {
    requestId = message.requestId
    if (progressListeners.has(requestId)) {
      let progressProcess = progressListeners.get(requestId)
      progressProcess(message)
    } else {
      console.error("bad response")
    }
  } else {
    console.warn("bad response")
  }
  console.log("the peer data is", message)
}

// 生成随机token
function generateRandomToken() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

// 生成guid
function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

async function blobToArrayBuffer(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(this.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
}

// 数据分片解析代码
class ChunkData {
  constructor(requestId, total, n, data) {
    this.requestId = requestId;
    this.total = total;
    this.n = n;
    this.data = data;
  }
}

const chunkDataMap = new Map();

function processChunkMessage(chunkdata) {
  if (chunkdata.length < 44) {
    throw new Error('数据长度不足');
  }
  let decoder = new TextDecoder()
  const requestId = decoder.decode(chunkdata.slice(0, 36))
  const totalBytes = chunkdata.slice(36, 40);
  const nBytes = chunkdata.slice(40, 44);
  const data = chunkdata.slice(44);

  let total = 0,
    n = 0;

  // 对4个字节的大尾数进行解码
  total = new DataView(totalBytes).getUint32(0, false);
  n = new DataView(nBytes).getUint32(0, false);

  const chunk = new ChunkData(requestId, total, n, data);

  // 初始化请求ID对应的映射如果不存在
  if (!chunkDataMap.has(requestId)) {
    chunkDataMap.set(requestId, new Map());
  }
  const requestMap = chunkDataMap.get(requestId);
  requestMap.set(n, chunk);

  if (chunk.n === chunk.total - 1) {
    chunkDataMap.delete(requestId);
    // 重建消息
    let bufferLength = (chunk.total - 1) * requestMap.get(0).data.byteLength + chunk.data.byteLength;
    let buffer = new Uint8Array(bufferLength);
    let offset = 0;
    for (let i = 0; i < chunk.total; i++) {
      const chunkPart = requestMap.get(i);
      if (!chunkPart) {
        console.log(`Missing chunk at index: ${i}`);
        continue;
      }
      buffer.set(new Uint8Array(chunkPart.data), offset);
      offset += chunkPart.data.byteLength;
    }
    chunk.data = buffer;
  }
  return chunk;
}

class CombinedMessage {
  constructor(metadata, fileData) {
    this.metadata = metadata;
    this.fileData = fileData;
  }
}

function findSeparatorIndex(buffer, separator) {
  const uint8Array = new Uint8Array(buffer);
  for (let i = 0; i < uint8Array.length - separator.length + 1; i++) {
    let isMatch = true;
    for (let j = 0; j < separator.length; j++) {
      if (uint8Array[i + j] !== separator[j]) {
        isMatch = false;
        break;
      }
    }
    if (isMatch) return i;
  }
  return -1;
}

function parseCombinedMessage(combinedData) {
  const separator = new Uint8Array([0, 0, 0]); // Assuming null byte as separator
  const sepIndex = findSeparatorIndex(combinedData, separator);
  if (sepIndex === -1) {
    const result = new CombinedMessage(combinedData, null);
    return result;
  }
  const metadata = new Uint8Array(combinedData.slice(0, sepIndex));
  const fileData = new Uint8Array(combinedData.slice(sepIndex + separator.length));
  return new CombinedMessage(metadata, fileData);
}
export {
  shouldUsePeerJsForRequest,
  rtcAdapter,
  getChannel,
  blobToArrayBuffer,
  guid,
  openDownLoadChannel,
  getConnection,
  removeConnection,
}