/*
 * @Author: mjzhu
 * @Date: 2022-05-24 10:22:10
 * @LastEditTime: 2022-08.24-19 18:41:09
 * @FilePath: \awx-ui\src\api\httpApi\dispath.js
 */
  
  
import paths from '@/api/baseUrl'// 后台服务地址

let path = paths.path().path

export default {
  getDispatchList:path+'/schedules'  //请求调度列表
}
