<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-06-13 16:35:02
 * @LastEditTime: 2023-09-28 14:18:54
 * @FilePath: \awx-ui\src\components\menu\appDrawer\appItem.vue
-->
<template>
  <div :class="['app-item', selectAppId === app.id ? 'app-item-selected' : '']">
    <div v-if="app.installed && app.installed > 0" class="circle-border">
      <svg-icon icon-class="app-installed" />  
    </div>
    <span class="app-logo" @click="installApp(app)">
      <img class="app-add-mark" src="../../../icons//app//app-add.png" alt="">
      <img v-if="app.id !== '_blank'" :src="app.icon" alt="" />
      <svg-icon v-else icon-class="task-blank" style="font-size: 44px"></svg-icon>
    </span>
    <div class="template-text-info">
      <div class="app-name over-flow-ellipsis" :style="{'max-width': templateType === 'app' ? '116px': '125px', 'font-size': templateType === 'app' ? '14px': '16px'}" :title="app.name">{{app.name}}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    app: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectAppId: String,
    templateType: {
      type: String,
      default: 'template'
    }
  },
  inject: ["createTask"],
  data() {
    return {};
  },
  methods: {
    installApp(obj) {
      this.createTask(obj)
    },
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="less" scoped>
.app-item {
  position: relative;
  width: 100%;
  height: 120px;
  background: rgba(0,0,0,0.18);
  border: 1px solid rgba(0,0,0,0.05);
  border-radius: 8px;
  cursor: pointer;
  color: #adb0b8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &:hover {
  }
  .app-logo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    background: #41516b;
    height: 48px;
    border-radius: 12px;
    margin-bottom: 10px;
    img {
      width: 100%;
      border-radius: 12px;
    }
    .app-add-mark {
      display: none;
      position: absolute;
    }
  }
  .app-name {
    line-height: 24px;
    font-size: 14px;
    color: #FFFFFF;
    letter-spacing: 0;
    font-weight: 700;
    max-width: 125px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .circle-border {
    top: 4px;
    position: absolute;
    right: 8px;
    display: none;
  }
  &:hover {
    .circle-border {
      // display: block;
    }
    .app-add-mark {
      display: block;
    }
  }
  // 选中的这个模板
  &-selected {
    // border: 1px solid rgba(58,116,244,0.8);
    .circle-border {
      display: block;
    }
  }
}
</style>