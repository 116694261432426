/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-05-24 10:28:22
 * @LastEditTime: 2022-07-11 15:24:52
 * @FilePath: \ddh-ui\src\store\modules\account.js
 */
import persist from 'vuex-persistedstate'
export default {
  state: {
    // 申请配额数据
    Applyforquota:[]
  },
  getters: {
   
  },
  mutations: {
    addApplyforquotaList(state,val){
      state.Applyforquota.push(val)
      console.log(state.Applyforquota,"vuex");
    },
    search(state,val){
      // console.log(val);
      state.Applyforquota=val
    }
  },
  plugins: [
    new persist({
      storage: window.localStorage,
    }),
  ],
  
}
