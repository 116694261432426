/*
 * @Author: zhang·xiao
 * @Date: 2022-11-29 13:37:16
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2023-06-04 22:11:17
 * @Description: 组件映射表
 */

// widget 组件对应elementUi 配置表
import widgetComponents from './index';

const {
  CheckboxesWidget,
  RadioWidget,
  SelectWidget,
  CodeEditWidget,
  TimePickerWidget,
  DatePickerWidget,
  DateTimePickerWidget
} = widgetComponents;

export default {
  types: {
    boolean: 'el-switch',
    string: 'el-input',
    number: 'el-input-number',
    integer: 'el-input-number',
  },
  formats: {
    color: 'el-color-picker',
    time: TimePickerWidget, // 20:20:39+00:00
    date: DatePickerWidget, // 2018-11-13
    'date-time': DateTimePickerWidget, // 2018-11-13T20:20:39+00:00
  },
  common: {
    select: SelectWidget,
    radioGroup: RadioWidget,
    checkboxGroup: CheckboxesWidget,
  },
  widgetComponents
};
