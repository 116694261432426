/*
 * @Author: mjzhu
 * @Date: 2022-05-24 10:22:10
 * @LastEditTime: 2023-05-26 00:27:12
 * @FilePath: \awx-ui\src\api\httpApi\host.js
 */
import paths from '@/api/baseUrl'// 后台服务地址

let path = paths.path().path

export default {
  getTargets: path + '/targets', // 获取目标
}
