/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-06-14 20:06:51
 * @LastEditTime: 2023-11-30 20:20:09
 * @FilePath: \awx-ui\src\store\modules\task.js
 */

import task from "@/api/httpApi/task"

const state = {
  taskIndexShow: 'list',
  flowIndexShow: 'list',
  hostGroup: [],
  hostList: [],
  taskInfo: {},
  stepInfo: {},
  selectStepId: '',
  selectTemplateIndex: null, // 当前选中步骤的index
  addTemplateIndex: -1, // 要追加步骤的index
  showStepsList: true, // 是否展示步骤去选择
  currentOptIndex: 0, // 0 选择应用 1 选择操作 2配置账号 3 配置参数 // 这个是步骤里面操作
  hasChange: false,
  currentPageIndex: -1, // 0 选择应用 1 选择操作 2配置账号 3 配置参数 当前页面操作
  refreshFlag: 0,
  firstEdit: false,
  jumpTaskDetail: null,
  isCreateFlowFlag: false,
}
const getters = {}
const mutations = {}
Object.keys(state).forEach((item) => {
  mutations[item] = (state, value) => {
    state[item] = value
  }
})

const actions = {
  setTaskInfo({
    commit
  }, obj) {
    if (obj && !obj.hasChange) {
      commit('hasChange', true)
    } else {
      commit('hasChange', false)
    }
    commit('taskInfo', obj)
  },
  setTaskIndexShow({
    commit
  }, obj) {
    commit('taskIndexShow', obj)
  },
  setFlowIndexShow({
    commit
  }, obj) {
    commit('flowIndexShow', obj)
  },
  setSelectTemplateIndex({
    commit
  }, obj) {
    commit('selectTemplateIndex', obj)
  },
  setRefreshFlag({
    commit,state
  }, obj) {
    commit('refreshFlag', obj)
  },
  setStepInfo({
    commit,state
  }, obj) {
    commit('stepInfo', obj)
  },
  
  // 同步每个步骤当前操作在第几步，并不是实际在第几步，只是记录操作的
  setCurrentPageIndex({
    commit, state
  }, obj) {
    if ((state.selectTemplateIndex > 0 && state.selectTemplateIndex) || state.selectTemplateIndex === 0) {
      let taskInfo = _.cloneDeep(state?.taskInfo)
      taskInfo.taskTemplateDetail.operations[state.selectTemplateIndex]['currentStepsIndex'] = obj
      console.log('state', taskInfo)
      commit('taskInfo', taskInfo)
    }
    commit('currentPageIndex', obj)
  },
  setIsCreateFlowFlag ({
    commit
  }, obj) {
    commit('isCreateFlowFlag', obj)
  },
  setSelectStepId ({
    commit
  }, obj) {
    commit('selectStepId', obj)
  },
  setJumpTaskDetail ({
    commit
  }, obj) {
    commit('jumpTaskDetail', obj)
  },
  setCurrentOptIndex({
    commit
  }, obj) {
    commit('currentOptIndex', obj)
  },
  setAddTemplateIndex({
    commit
  }, obj) {
    commit('addTemplateIndex', obj)
  },
  changeFirstEdit({
    commit
  }, obj) {
    commit('firstEdit', obj)
  },
  setShowStepsList({
    commit
  }, obj) {
    commit('showStepsList', obj)
  },
  setHostGroup({
    commit
  }, obj) {
    commit('hostGroup', obj)
  },
  setHostList({
    commit
  }, obj) {
    commit('hostList', obj)
  },
}
export default {
  namespaced: 'steps',
  state,
  getters,
  actions,
  mutations
}