import { render, staticRenderFns } from "./applyQuota.vue?vue&type=template&id=63fe4e37&scoped=true"
import script from "./applyQuota.vue?vue&type=script&lang=js"
export * from "./applyQuota.vue?vue&type=script&lang=js"
import style0 from "./applyQuota.vue?vue&type=style&index=0&id=63fe4e37&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63fe4e37",
  null
  
)

export default component.exports