/*
 * @Author: mjzhu
 * @Date: 2022-05-24 10:22:10
 * @LastEditTime: 2024-01-17 18:14:18
 * @FilePath: \awx-ui\src\api\httpApi\management.js
 */
  
  
import paths from '@/api/baseUrl'// 后台服务地址

let path = paths.path().path

export default {
  getManagementList:path+'/quota_templates',//请求配额方案列表
  getQuotaRecordList:path+'/quotas',   //配额记录列表
  getDetailedList:path+"/users",    // 用户配额详细信息
  getQuotaPayStatus: path + '/quotas/order',
  getQuotaCatagories: path+ '/quota_catagories'
}