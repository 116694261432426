<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-11-24 16:07:29
 * @LastEditTime: 2024-03-18 17:08:49
 * @FilePath: \awx-ui\src\components\createTask\components\log.vue
-->
<template>
  <div class="log-editor">
    <editor id="editor" ref="formEditor" style="position: relative; top: 8px;" :editorHeight="editorHeight" :propsCode="step.log" :readOnly="true" theme="vs-dark" language="json" />
  </div>
</template>

<script>
import editor from "@/components/editor";

export default {
  components: {
    editor
  },
  props: {
    taskId: String,
    taskType: String,
    stepId: String,
    editorHeight: String,
    noTips: Boolean,
    runStatus: Object
  },
  data() {
    return {
      step: {
        name: "",
        log: "",
      },
    };
  },
  watch: {
    taskId: {
      handler(val) {
        if (val) {
          if (this.taskType !== 'taskLog') {
            this.getAllCommonLog(val)
          }
        }
      },
      immediate: true
    },
    runStatus: {
      handler (val) {
        if (val && this.taskType === 'taskLog') {
          this.step.name = ''
          this.step.log = JSON.stringify(val, null, 2)
          this.$refs.formEditor.resetCode(this.step.log)
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
  },
  mounted() {
    this.$EventBus.$on("getAllCommonLog", (taskId) => {
      this.getAllCommonLog(taskId);
    });
    this.$EventBus.$on("openCommonLogByStepId", (step, stepId, taskId) => {
      this.openCommonLogByStepId(step, stepId, taskId);
    });
  },
  methods: {
    async getAllCommonLog(taskId) {
      if (!taskId) return false
      let res = await this.$axiosGet(
        global.API.curdTasks + `${taskId}/op_runs`,
        { exclude: "result" }
      );
      if ([200, 201, 204, 202].includes(res.status)) {
        this.step.name = ''
        this.step.log = JSON.stringify(res.data, null, 2)
        this.$refs.formEditor.resetCode(this.step.log)
      } else {
        if (!this.noTips) this.$message.error("获取流程日志失败");
      }
    },
    async openCommonLogByStepId(step, stepId, taskId) {
      if (!taskId) return false
      if (!stepId || !step) {
        return this.getAllCommonLog(taskId);
      }
      let res = await this.$axiosGet(
        global.API.curdTasks + `${taskId}/op_runs`,
        { exclude: "result", uuid: stepId }
      );
      if ([200, 201, 204, 202].includes(res.status)) {
        if (res.data[`${stepId}`].status === "unknown") {
          this.step.name = ''
          this.step.log = ''
          this.$refs.formEditor.resetCode(this.step.log)
          if (!this.noTips) this.$message.warning("暂无执行日志");
          return false
        }
        this.step = {
          name: step.step ? "执行详情 / " + step.step.name : '',
          log: JSON.stringify(res.data[`${stepId}`].result, null, 2),
        };
        this.$refs.formEditor.resetCode(this.step.log)
      } else {
        if (!this.noTips) this.$message.error("获取流程日志失败");
      }
    },
  },
  beforeDestroy() {
      this.$EventBus.$off("getAllCommonLog");
      this.$EventBus.$off("openCommonLogByStepId");
    },
};
</script>
<style scoped lang='less'>
.log-editor {
  margin-right: 10px;
}
</style>