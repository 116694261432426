<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-08-04 10:03:03
 * @LastEditTime: 2023-12-27 09:47:55
 * @FilePath: \awx-ui\src\components\createTask\deploystep\newCreateTask.vue
-->
<template>
  <div class="task-steps">
    <div v-if="!editFlag" class="task-steps-name">
      {{task.taskInfo.name || ''}}
      <span style="cursor: pointer;" @click="editName">
        <svg-icon class="mgl10 task-steps-name-edit" icon-class="flow-edit"></svg-icon>  
      </span>
      <div class="file-full exit" @click="exit">
        <a-icon type="close" />
      </div>
    </div>
    <div v-else class="task-steps-name">
      <a-form :form="form" :label-col="{ span: 0 }" :wrapper-col="{ span: 6 }">
        <a-form-item label style="margin-bottom: 0px">
          <a-input placeholder="请输入任务名称" @blur="getName" style="margin-top: 3px" v-decorator="['taskName', { rules: [{ required: true, message: '任务名称不能为空' }] }]" />
        </a-form-item>
      </a-form>
    </div>
    <rightTempleate v-show="stepsName === 'template'" @selectedTemplate="selectedTemplate" />
    <operation-box v-if="stepsName === 'chooesApp'" :taskType="taskType" />
    <div v-if="stepsName === 'chooesApp'" class="footer-save">
      <a-button v-if="taskType === 'task'" class="mgr10 btn-gray" type="primary" :loading="customLoading" @click="back">返回</a-button>
      <a-popconfirm
        overlayClassName="pop-confrim-modal"
        title="确定删除吗?"
        ok-text="是"
        cancel-text="否"
        @confirm="delTemplate"
      >
        <a-button v-if="taskType === 'template' && canDelete" class="mgr10 btn-gray" :loading="deleteTemplateLoading">删除</a-button>
      </a-popconfirm>
      <a-button v-if="taskType === 'task'" class="mgr10 btn-gray" type="primary" @click="openCollectModal">收藏</a-button>
      <a-button v-if="taskType !== 'app' && taskType !== 'template'" class="mgr10 btn-save" type="primary" :loading="nextLoading" @click="save('save')">保存</a-button>
      <a-button v-if="taskType !== 'app' && taskType === 'template'" class="mgr10 btn-save" type="primary" :loading="nextLoading" @click="editTemplate()">保存</a-button>
      <a-button class="mgr10 btn-save" type="primary" :loading="nextRunLoading" @click="save('start')">{{ taskType === 'app' ? '安装' : '执行' }}</a-button>
      <a-button class="mgr10 btn-gray" @click="exit">退出</a-button>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState, mapMutations, mapActions } from "vuex";
import rightTempleate from '../chooseTemplete/rightTempalte.vue'
import OperationBox from "./operationBox";
// 校验任务里面的每一个步骤
import {validateTask} from './validate.js'
import ConfrimTaskNameModal from '@/components/createTask/confirmName/index.vue'
import TemplateModal from './templateModal/index.vue'
const clientWidth = document.documentElement.clientWidth
export default {
  components: {rightTempleate, OperationBox},
  data() {
    return {
      clientWidth,
      saveFlag: false,
      stepsName: 'template',
      nextLoading: false,
      deleteTemplateLoading: false,
      customLoading: false,
      editFlag: false,
      nextRunLoading: false,
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      activeKey: "1",
    };
  },
  props: {
    taskType: {
      type: String,
      default: 'task'
    },
    refreTemplate: Function
  },
  inject: ["handleCancel", "onlyCancel", "onSearchByCreateTime"],
  watch: {
    'taskId' : {
      handler(val) {
        if (val) {
          this.stepsName = "chooesApp";
        }
      },
      immediate: true
    },
    taskType: {
      handler(val) {
        if (val === 'template' || val === 'app') {
          this.stepsName = "chooesApp";
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    taskId () {
      const taskId = this.task.taskInfo.task_id || ''
      return taskId
    },
    canDelete () {
      let canDelete = false
      if (this.task.taskInfo.task_type === '1')  {
        canDelete = true
      } else {
        if (this.isSuperuser) canDelete = true
      }
      return canDelete
    }
  },
  methods: {
    ...mapActions("task", [
      "setTaskInfo",
      "setShowStepsList",
      "setSelectTemplateIndex",
      "setCurrentOptIndex",
      "setCurrentOptIndex",
    ]),
    back () {
      this.stepsName = 'template'
    },
    selectedTemplate(id) {
      // 因为 新家空白的任务 第一个节点默认的是调度 所以现在默认选中第二个空白节点 所以操作 setCurrentOptIndex(0)第一步, setSelectTemplateIndex（1）展示第二个节点
      this.setCurrentOptIndex(0)
      this.setShowStepsList(true);
      this.setSelectTemplateIndex(1);
      this.stepsName = 'chooesApp'
    },
    closeModal() {
      this.handleCancel(this.task.taskInfo);
    },
    exit() {
      this.onlyCancel();
      // if (this.saveFlag) {
      //   // 带刷新
      //   this.closeModal()
      // } else {
      //   // 不带刷新
      //   this.onlyCancel();
      // }
    },
    callback(key) {
      this.activeKey = key;
    },
    editName() {
      this.editFlag = true;
      this.form.getFieldsValue(["taskName"]);
      this.form.setFieldsValue({
        taskName: this.task.taskInfo.name,
      });
    },
    openCollectModal () {
      const self = this;
      let width = 520;
      let title = "收藏";
      let content = <TemplateModal ref="TemplateModalRef" templateName={this.task.taskInfo.name} callBack={(form) => self.saveCustomTemplate(form)} />
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
   async delTemplate () {
    this.deleteTemplateLoading = true
    let res = await this.$axiosDelete(
        global.API.onlyTemplate + this.task.taskInfo.id + '/',
        {}
      );
      if ([200, 201, 204, 202].includes(res.status)) {
        this.$message.success("删除成功");
        this.deleteTemplateLoading = false;
        this.$emit('refreTemplate')
        this.exit()
      }
   },
   async editTemplate () {
      this.nextLoading = true;
      let param = _.cloneDeep(this.task.taskInfo);
      if (
        !(
          param.taskTemplateDetail &&
          param.taskTemplateDetail.operations &&
          param.taskTemplateDetail.operations.length > 0
        )
      ) {     
        this.nextLoading = false;
        this.$message.warning("步骤尚未执行");
        return false;
      }
      param.operations = this.hanlderScheduleParam(param.taskTemplateDetail.operations)
      param.configs = param.taskTemplateDetail.configs || [];
      param.notify = param.taskTemplateDetail.notify  || [] // 通知
      delete param.taskTemplateDetail;
      let flag = validateTask(param)
      const NameArr = param.operations.map(item => {return item.name})
      const NameArrIndex =  NameArr.length < 4 ? NameArr.length : 4
      if (!param.name) {
        param.name = this.processArrayItem(NameArr, NameArrIndex)
      }
      if (!flag) {
        this.nextLoading = false;
        return flag
      }
      let res = await this.$axiosPut(
        global.API.curdTaskTemplate + param.id,
        param
      );
      if ([200, 201, 204, 202].includes(res.status)) {
        this.$message.success("保存成功");
        this.nextLoading = false;
        this.$emit('refreTemplate')
      }
    },
    // 存为模板
    async saveCustomTemplate(form) {
      let param = _.cloneDeep(this.task.taskInfo)
      param.operations = param.taskTemplateDetail.operations // 步骤
      param.configs = param.taskTemplateDetail.configs // 参数
      param.notify = param.taskTemplateDetail.notify  // 通知
      param.name = form.name
      param.icon = form.icon
      param.description =form.description
      param.categories =(form.categories && form.categories.length) ? form.categories.join(',') : ''
      param.tags = (form.tags && form.tags.length) ? form.tags.join(',') : ''
      delete param.taskTemplateDetail
      delete param.id
      delete param.template_id
      let res = await this.$axiosJsonPost(
        global.API.curdTaskTemplate,
        param
      );
      this.$refs.TemplateModalRef.loading = false
      if ([200, 201, 204, 202].includes(res.status)) {
        this.$message.success("收藏成功");
        this.$refs.TemplateModalRef.formCancel()
        this.$EventBus.$emit('getTemplates')
      }
    },
    // 提交调度方式的时候 清除其他两种调度里面的参数
    hanlderScheduleParam (operations) {
      operations.forEach((item) => {
        if (item.application_id === 'schedule') {
          // 周期
          if (item.model.scheduletype === 'timer') {
            item.model.runtime = undefined
          } else if (item.model.scheduletype === 'once') {
            // 一次性调度
            item.model = {
              ...item.model,
              cron: "",
              start_time: undefined,
              end_time: undefined,
              timezone: "Asia/Shanghai",
              cronObj: {
                forever: false,
                interVal: "",
                dispatchType: "day",
                time: undefined,
                days: undefined,
                weeks: undefined,
              }
            }
          } else {
            // 手动调度
            item.model = {
              ...item.model,
              cron: "",
              runtime: undefined,
              start_time: undefined,
              end_time: undefined,
              timezone: "Asia/Shanghai",
              cronObj: {
                forever: false,
                interVal: "",
                dispatchType: "day",
                time: undefined,
                days: undefined,
                weeks: undefined,
              }
            }
          }
        }

      })
      return operations
    },
    processArrayItem(item, index) {
      return item.slice(1, index).join('_');
    },
    // 在没哟设置任务名称的时候需要去确认自动带入的名称
    confirmName (type, param) {
      const self = this
      const NameArr = param.operations.map(item => {return item.name})
      const NameArrIndex =  NameArr.length < 4 ? NameArr.length : 4
      const paramName = this.processArrayItem(NameArr, NameArrIndex)
      let width = 600;
      let content = (
        <ConfrimTaskNameModal
          taskType="task"
          taskName={paramName}
          callBack={(name) => {
            param.name = name
            self.confirmSave(type, param)
          }}
        />
      );
      this.$confirm({
        width: width,
        title: '任务名称确认',
        content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    // 确定保存任务 ajax请求
    async confirmSave (type, param) {
      const self = this
      if (type === 'save') this.nextLoading = true;
      if (type === 'start') this.nextRunLoading = true
      let res = null;
      let url = ''
      if (this.task.taskInfo.task_id) {
        url =  type === 'save' ?   global.API.curdTasks + `${this.task.taskInfo.task_id}` :  global.API.curdTasks + `${this.task.taskInfo.task_id}`
      } else {
        url = global.API.curdTasks
      }
      try {
        if (this.task.taskInfo.task_id) {
          res = await this.$axiosPut(
            url,
            param
          );
        } else {
          res = await this.$axiosJsonPost(url, param);
        }
        if (type === 'save') this.nextLoading = false;
        if (type === 'start') this.nextRunLoading = false
        if ([200, 201, 204, 202].includes(res.status)) {
          this.saveFlag = true
          const taskInfo = {
            ...res.data,
            taskTemplateDetail: {
              operations: self.task?.taskInfo?.taskTemplateDetail?.operations
            },
          };
          if (!this.task.taskInfo.task_id) {
            this.setTaskInfo(taskInfo);
            this.onSearchByCreateTime()
          }
          if (type === "start") {
            self.savePublish(this.task.taskInfo.task_id);
            return false;
          }
          this.$message.success("保存成功");
        }
      } catch (error) {
        // 捕捉异常
        if (type === 'save') this.nextLoading = false;
        if (type === 'start') this.nextRunLoading = false;
      }
    },
    // 保存前工作
    save(type) {
      const self = this;
      let param = _.cloneDeep(this.task.taskInfo);
      if (
        !(
          param.taskTemplateDetail &&
          param.taskTemplateDetail.operations &&
          param.taskTemplateDetail.operations.length > 0
        )
      ) {     
        this.$message.warning("步骤尚未执行");
        return false;
      }
      param.operations = this.hanlderScheduleParam(param.taskTemplateDetail.operations)
      param.configs = param.taskTemplateDetail.configs || [];
      param.notify = param.taskTemplateDetail.notify  || [] // 通知
      delete param.taskTemplateDetail;
      let flag = validateTask(param)
      if (!flag) return false
      if (!param.name) {
        this.confirmName(type, param)
      } else {
        this.confirmSave(type, param)
      }
    },
    async savePublish(taskId) {
      // 这个执行 调用哪个接口
      let res = await this.$axiosJsonPost(
        global.API.curdTasks + `${taskId}/start`,
        {}
      );
      this.nextRunLoading = false
      if ([200, 201, 204, 202].includes(res.status)) {
        this.$message.success("提交成功");
        this.closeModal();
      }
    },
    initName() {
      const name = "deployenv" + moment(new Date()).format("YYYYMMDDHHMMSS");
      this.setTaskInfo({ ...this.task.taskInfo, name: name, hasChange: true });
    },
    getName() {
      const _this = this;
      this.form.validateFields((err, values) => {
        console.log(values);
        if (!err) {
          this.editFlag = false;
          this.setTaskInfo({ ...this.task.taskInfo, name: values.taskName });
        }
      });
    },
  },
  created () {
    if (this.taskType === 'template') {
      this.isSuperuser = JSON.parse(
        localStorage.getItem("admin.user")
      ).isSuperuser; //判读是不是超级用户
      console.log(this.isSuperuser, 'isSuperuserisSuperuserisSuperuser')
    }
  },
  mounted() {
  },
};
</script>
<style lang="less" scoped>
.task-steps {
  width: 100%;
  background: rgb(251, 252, 254);
  position: relative;
  border-radius: 4px!important;
  &-name {
    font-size: 18px;
    color: #1D202D;
    font-weight: 600;
    padding: 0px 0 0 24px;
    height: 43px;
    line-height: 43px;
    background:linear-gradient(to right,rgba(226, 235, 250, 0.4), rgba(240, 243, 247, 0.4));
    border-radius: 6px 6px 0 0;
    border-bottom: 1px solid #Dee8f5;
    &-edit {
      cursor: pointer;
    }
    .task-steps-name-edit {  
      color: @primary-color;
      font-size: 16px;
    }
  }
  .task-baseInfo {
    width: 1200px;
    margin: 0 auto;
  }
  .footer-save {
    width: 100%;
    display: flex;
    height: 76px;
    justify-content: center;
    align-items: center;
    .btn-gray {
      background: #ECF1F7;
      border-radius: 4px; 
      border: none;
      color: #5A5A69;
      width: 92px;
      &:hover {
        color: #34343C;
        background: #d9e4f5;
      }
    }
    .btn-save {
      width: 92px;
    }
    /deep/
      .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
      padding-left: 20px;
    }
  }
  .exit {
    margin-right: 0px; 
    float: right;
    cursor: pointer;
    color: rgba(0, 0 ,0, 0.45);
    font-size: 16px;
    width: 43px;
    height: 100%;
    border-radius: 0 4px 0 0;
    text-align: center;
    &:hover {
      color: rgba(0, 0 ,0, 0.65);
      background: rgba(208, 210 ,213);
    }
  }
}
</style>