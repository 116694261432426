<!--
 * @Author: zhang·xiao
 * @Date: 2022-12-17 21:26:54
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2023-06-04 22:18:38
 * @Description: 描述文件功能
-->
<template>
    <div>
        <div style="margin: -10px 0 10px; text-align: left;color: #999;">
            <p>如果出现导入异常，请仔细检查Schema数据的语法正确性，莫要随意输入！</p>
        </div>
        <CodeEditWidget editorHeight="calc(70vh - 200px)" :value="importStr" @change="onChange" />
        <p style="margin-top: 20px; text-align: right;">
            <el-button
                type="primary"
                @click="$emit('onImport', importStr)"
            >
                导入
            </el-button>
        </p>
    </div>
</template>

<script>
import CodeEditWidget from '@formEngine/config/widgets/CodeEditWidget'

export default {
  name: 'ImportSchemaView',
  components: { CodeEditWidget },
  data() {
    return { importStr: '' };
  },
  methods: {
    onChange(val) {
      this.importStr = val.replace('const schema = ', '')
    }
  }
};
</script>
