<!--
 * @Author: mjzhu
 * @describe: 左边的已经选择过的步骤
 * @Date: 2022-06-13 16:35:02
 * @LastEditTime: 2024-03-29 11:25:26
 * @FilePath: \awx-ui\src\components\createTask\components\logTemplate.vue
-->
<template>
  <div>
    <div :class="['log-template-item', task.selectTemplateIndex === templateItemIndex ? 'log-template-item-selected' : '', templateItemIndex === 0 && 'schedule-item']" :style="{marginTop: templateItemIndex === 0 ? '16px': '0px', marginBottom: templateItemIndex === operationList.length -1 ? '0px': '0px'}" @click="selectedTemplate(templateItemIndex)">
      <div :class="['small-point', task.selectTemplateIndex === templateItemIndex && 'small-point-selected']"></div>
       <div class="run-status" v-if="templateItemIndex > 0 && itemRunStautus">
         <svg-icon :icon-class="itemRunStautus === 'success' ? 'task-successful' : 'task-failed-old'" />
       </div>
      <div v-if="templateItemIndex > 0" :class="['small-circle', task.selectTemplateIndex === templateItemIndex && 'small-circle-selected']"></div>
      <div class="img-warp">
        <!-- 系统图标 -->
        <img v-if="templateItem.icon && !IsColorIcon(templateItem.icon) && !IsNetWorkIcon(templateItem.icon)" :src="templateItem.icon " alt />
         <!-- 纯色图标 -->
       <div v-if="templateItem.icon && IsColorIcon(templateItem.icon) && !IsNetWorkIcon(templateItem.icon)"  class='icon-item' :style="{
          'background': templateItem.icon,
          'text-align': 'center',
          'width': '42px',
          'height': '42px',
          'border-radius': '8px',
          'margin': 'auto'
        }">
          <span :style="{
            'color': '#fff',
            'font-size': iconColorName(templateItem.name).length === 1 ? '20px' : '14px',
            'display': 'block',
            'width': '100%',
            'height': '100%',
            'line-height': '42px'
          }">{{ iconColorName(templateItem.name) }}</span>  
        </div>
        <iconifyIcon v-if="templateItem.icon && !IsColorIcon(templateItem.icon) && IsNetWorkIcon(templateItem.icon)" :icon="iconNetWorkName(templateItem.icon)" style="font-size: 32px;" />
        <svg-icon v-if="!templateItem.icon" icon-class="flow-app" style="font-size: 24px;position: relative;left: 3px;color:#3974f4;margin: auto;display: inline-block"></svg-icon>
      </div>
      <div class="action-content" :style="{'width': templateItemIndex === 0 ? 'calc(100% - 16px)' : 'calc(100% - 16px)'}">
        <div class="action-text-wrap">
          <p class="action-label over-flow-ellipsis action-name" :style="{'max-width': templateItemIndex === 0 ? '100%' : '100%'}" :title="templateItem.application_name">{{templateItem.application_name}}</p>
        </div>
        <div>
          <p v-if="templateItem.application_id && !templateItem.id" class="action-desc over-flow-ellipsis">请选择操作</p>
          <p v-if="templateItem.application_id && templateItem.id" class="action-desc over-flow-ellipsis" :title="templateItem.name">操作： {{templateItem.name}}</p>
          <p v-if="templateItemIndex === 0 && templateItem.application_name === '请配置一个触发条件'" class="action-desc over-flow-ellipsis" title="当满足条件时，则流程自动触发">当满足条件时，则流程自动触发</p>
        </div>
      </div>
    </div>
    <!-- 一个箭头线的开始 -->
    <div class="ArrowDown__Wrapper-finQpt fJoHGo">
      <div :class="['node-arrow-line', 'node-arrow-down', task.selectTemplateIndex === templateItemIndex ? 'log-template-line-selected' : '']" :style="{height: '50px'}">
        <svg style="margin-left: 50%;" width="2px" :height="'48px'" version="1.1">
          <path :stroke="task.selectTemplateIndex === templateItemIndex  ? '#42526C' : '#42526C'" stroke-width="2" fill="none" :d="templateItemIndex === operationList.length -1 ? 'M 1 0 v 24' : 'M 1 0 v 50'" />
        </svg>
      </div>
      <div v-if="templateItemIndex === operationList.length -1 " style="top: 18px" :class="['small-circle', task.selectTemplateIndex === templateItemIndex && 'small-circle-selected']"></div>
    </div>
    <!-- 一个箭头线的结束 -->
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {
    templateItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    runStatus: {
      type: Object,
      default: () => {
        return {}
      },
    },
    operationList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    templateItemIndex: Number,
    selectedTemplateId: String,
  },
  data() {
    return {
      selectedTemplateObj: {},
    };
  },
  watch: {},
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    itemRunStautus () {
      if (!this.runStatus) return false
      if (!(this.templateItem.model && this.templateItem.model.nodeObj && this.templateItem.model.nodeObj.id)) return false
      const uuid = this.templateItem.model?.nodeObj?.id || null
      const status = (uuid && this.runStatus  &&  this.runStatus[`${uuid}`]) ? this.runStatus[`${uuid}`].status : false
      if (uuid && status) {
        return status
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions("task", ["setSelectTemplateIndex", "setTaskInfo"]),
    IsColorIcon(icon) {
      return global.utils.IsColorIcon(icon);
    },
    iconColorName (name){
      return global.utils.iconColorName(name);
    },
    IsNetWorkIcon(icon) {
      return global.utils.IsNetWorkIcon(icon);
    },
    iconNetWorkName (name){
      return global.utils.iconNetWorkName(name);
    },
    selectedTemplate(index) {
      if (
        this.task &&
        this.task.taskInfo &&
        this.task.taskInfo.taskTemplateDetail &&
        this.task.taskInfo.taskTemplateDetail.operations
      ) {
        this.setSelectTemplateIndex(index)
        if (index === 0) {
          this.$EventBus.$emit("getAllCommonLog", this.task.taskInfo.task_id)
        } else {
          this.$EventBus.$emit("openCommonLogByStepId", this.templateItem, this.templateItem.model?.nodeObj?.id, this.task.taskInfo.task_id)
        }
      }
    },
  },
  mounted() {
    console.log(this.runStatus, this.templateItem)
  },
  beforeDestroy() {},
};
</script>
<style lang="less" scoped>
.log-template-item {
  cursor: pointer;
  height: 64px;
  padding: 0 13px;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
  background: #ffffff;
  border: 1px solid rgba(151, 151, 151, 1);
  border-radius: 8px;
  .small-point {
    position: absolute;
    left: 50%;
    margin-left: -3px;
    bottom: -4px;
    width: 8px;
    height: 8px;
    background: #42526c;
    border-radius: 50%;
    &-selected {
      background: #3974f4;
    }
  }
  &:hover {
    border: 2px solid rgba(57, 116, 244, 1);
    box-shadow: 0px 2px 10px 0px rgba(66, 82, 108, 0.2);
    border-radius: 8px;
    color: #1d202d;
  }
  .img-warp {
    height: 42px;
    margin: 0px 14px 0 0;
    width: 42px;
    min-width: 42px;
    min-height: 42px;
    background: #FFF; // 这个是图标的颜色定义
    align-items: center;
    justify-content: center;
    // border: 1px solid rgba(207,213,222,1);
    // box-shadow: 0px 2px 10px 0px rgba(65,81,107,0.1);
    box-shadow: 0px 3px 10px 0px rgba(66, 82, 108, 0.2);
    border-radius: 8px;
    display: flex;
    img {
      width: 42px;
      border-radius: 8px;
      margin: auto;
    }
  }
  .action-content {
    display: inline-block;
    width: calc(100% - 100px);
    padding-top: 4px;
    .action-desc {
      color: #7d838e;
    }
  }
  .action-text-wrap {
    width: 100%;
    white-space: nowrap;
    line-height: 20px;
    .action-label {
      display: inline-block;
      font-weight: 700;
      max-width: 72%;
      font-size: 14px;
      color: #1d202d;
      letter-spacing: 0;
      font-weight: 600;
      margin-right: 10px;
      min-width: 10px;
    }
  }
}
.small-circle {
  position: absolute;
  left: 50%;
  margin-left: -7px;
  top: -8px;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 2px solid rgba(66, 82, 108, 1);
  border-radius: 50%;
  z-index: 10000;
  &-selected {
    border-color: #3974f4;
  }
}
.run-status {
  position: absolute;
  top: -12px;
  left: -12px;
  svg {
    font-size: 20px;
  }
}
.log-template-item-selected {
  border: 2px solid rgba(57, 116, 244, 1);
  box-shadow: 0px 2px 10px 0px rgba(66, 82, 108, 0.2);
  border-radius: 8px;
}
.schedule-item {
  height: 64px;
  .action-text-wrap {
    line-height: 1;
  }
  .img-warp {
    height: 42px;
    min-width: 42px;
    vertical-align: top;
    width: 42px;
    padding: 0;
    background: #fff; // 这个是图标的颜色定义
    border-radius: 6px;
    img {
      width: 100%;
    }
  }
}
// 线
.fJoHGo {
  position: relative;
  .node-arrow-line {
    position: relative;

    .add-line {
      position: absolute;
      top: 0px;
      bottom: 0px;
      width: 100%;

      .fBFgIu {
        height: 100%;

        .addline-wrap-container {
          position: relative;
          height: 100%;
          opacity: 1;

          &:hover {
            opacity: 1;
          }
        }

        .trigger-add-line {
          top: 45px;
        }

        .addline-wrap {
          position: absolute;
          margin-left: -10px;
          left: 50%;
          top: calc(50% - 14px);
          color: #42526c;
          border-radius: 20px;
          height: 20px;
          font-size: 16px;
          cursor: pointer;
          line-height: 23px;
          background: #fff;
        }

        .trigger-add-line {
          top: 45px;
        }
      }
    }

    .text {
      width: max-content;
      position: absolute;
      left: 12px;
      top: 17px;
      font-size: 12px;
      background-color: rgb(240, 242, 244);
      height: 23px;
      line-height: 23px;
    }
  }
}
</style>