/*
 * @Author: mjzhu
 * @Date: 2022-05-24 10:22:10
 * @LastEditTime: 2023-05-26 00:26:59
 * @FilePath: \awx-ui\src\api\httpApi\project.js
 */
import paths from '@/api/baseUrl'// 后台服务地址

let path = paths.path().path

export default {
  getProjectList: path+ '/projects', // 查询项目列表
  saveProject: path+ '/projects', // 保存项目
}
