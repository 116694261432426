<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-12-04 11:36:35
 * @LastEditTime: 2023-11-13 16:15:03
 * @FilePath: \awx-ui\src\pages\formRenderDemo\Render.vue
-->
<template>
  <div>
      <schema-form
        ref="schemaForm"
        :schema="schema"
        :ui-schema="uiSchema"
        :error-schema="errorSchema"
        :custom-formats="customFormats"
        :form-footer="formFooter"
        :form-props="formProps"
        :fallback-label="true"
        v-model="formData"
        paramsProps="{}"
        @keyup.native.enter="handleSearch"
        @on-form-mounted="handleFormMounted"
        @on-change="handleDataChange"
        @on-cancel="handleCancel"
        @on-submit="handleSubmit"
      />
  </div>
</template>

<script>
import SchemaForm  from "@formEngine/index"
import { mapState, mapMutations, mapActions } from "vuex";
import { data1, data2, data3, data4, data5, data6 } from './data';
export default {
  name: "Login",
  props: {
    selectedTemplateObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    model: {
      type: Object,
      default: () => {
        return {}
      }
    },
    designerType: {
      type: String,
      default: 'task'
    }
  },
  components: { SchemaForm },
  data() {
    return {
      schema: data3,
      formData: {},
      uiSchema: {},
      errorSchema:{},
      customFormats: null,
      formFooter: {
        show: false
      },
      formProps: {
        layoutColumn: 1,
        labelWidth: "20%",
        labelPosition: 'left',
        labelSuffix: null,
        isMiniDes: true
      }
    }
  },
  watch: {
    selectedTemplateObj: {
      handler(val) {
        this.schema = val.schema
      },
      deep: true,
      immediate: true
    },
    model: {
      handler(val) {
        this.formData = val
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
  },
  methods: {
    ...mapActions("task", ["setTaskInfo", "setStepInfo"]),
    handleFormMounted() {
      console.log('表单加载完成------', this.formData)
    },
    hanlderTaskChange () {
      const currentIndex = this.task.selectTemplateIndex
      const taskInfo = _.clone(this.task.taskInfo)
      const taskTemplateDetail = taskInfo.taskTemplateDetail
      const operations = taskTemplateDetail.operations
      operations[currentIndex].model = this.formData
      this.setTaskInfo(taskInfo)
      console.log('表单数据改变------', this.formData)
    },
    handlerFlowChange () {
      const stepInfo = _.cloneDeep(this.task.stepInfo)
      stepInfo.taskTemplateDetail.stepInfo.properties.model = this.formData
      this.setStepInfo(stepInfo);
      this.$EventBus.$emit('updateStepsProperties', stepInfo.taskTemplateDetail.stepInfo.properties)
      this.$EventBus.$emit('updateStepsNameByStepId', stepInfo.taskTemplateDetail.stepInfo.properties.name)
    },
    handleDataChange() {
      if (this.designerType === 'flow') {
        this.handlerFlowChange()
      } else {
        this.hanlderTaskChange()
      }
      console.log('表单数据改变------', this.formData, this.designerType)
    },
    handleCancel() {
      console.log('表单提交取消------', this.formData)
    },
    handleSubmit() {
      console.log('表单确认提交------', this.formData)
    }
  },
};
</script>

<style lang="less" scoped>
</style>
