/*
 * @Author: zhang·xiao
 * @Date: 2022-12-17 21:26:54
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2023-01-11 22:48:51
 * @Description: 描述文件功能
 */


import baseMulti from './index';
export default {
  viewSchema: {
    ...baseMulti.viewSchema,
    title: '多选(Checkbox)',
    'ui:widget': 'CheckboxesWidget'
  },
  propsSchema: baseMulti.propsSchema
};
