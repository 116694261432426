<template>
  <div class="app-popover-list">
    <div class="title">更多已添加的应用</div>
    <div class="flex-warp">
      <div v-for="(item, index) in appList" :key="index" class="app-item" @click="toPath(item)">
        <div class="img-warp">
            <img src="/awx/target/icon/douying.png" alt="">
        </div>
        <div class="ell app-name" :title="item.name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    moreAppList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    menuNum: {
      type: Number,
      default: 8
    }
  },
  data() {
    return {};
  },
  computed: {
    appList () {
      let arr = []
      if (this.moreAppList.length > this.menuNum) {
        arr = this.moreAppList.slice(this.menuNum, this.moreAppList.length)
      }
      return arr
    }
  },
  components: {},
  mounted() {},
  methods: {
    toPath (menu) {
      this.$emit('toPath', menu)
    }
  },
};
</script>
<style scoped lang='less'>
.app-popover-list {
  .title {
    font-size: 12px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 400;
  }
  .flex-warp {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-height: 120px;
    overflow-y: auto;
    .app-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      cursor: pointer;
      width: calc(33% - 10px);
      margin: 10px 6px 0 0;
      &:nth-child(3n) {
        margin: 10px 0 0 0;
      }
      .img-warp {
        display: flex;
        align-items: center;
        // justify-content: center;
        width: 26px;
        background: #41516b;
        height: 26px;
        border-radius: 8px;
        margin-bottom: 4px;
        img {
          width: 100%;
          border-radius: 8px;
        }
      }
      .app-name {
        max-width: 100%;
        font-size: 12px;
        color: #FFFFFF;
        letter-spacing: 0;
        font-weight: 400;
      }
    }
  }
}
/deep/ .siteScroll::-webkit-scrollbar-track,
.siteHeader_currencyList::-webkit-scrollbar-track,
*::-webkit-scrollbar-track {
  background: rgb(41, 55, 84);
  background-color: rgb(41, 55, 84);
}
::-webkit-scrollbar {
  background-color: rgb(41, 55, 84);
}
</style>