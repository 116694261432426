<template>
  <div class="custom-form-engine-container" v-loading="loading">
    <div class="container">
      <div :class="{
                  ['contentWrap']: true,
                  ['closeToolbar']: closeToolbar
              }">
        <div :class="'toolBarWrap'">
          <div :class="'toolsBar'">
            <EditorToolBar :drag-group="dragOptions.group" :config-tools="configTools" @getComponents="getComponents"></EditorToolBar>
          </div>
          <span :class="'leftCaret'" @click="closeToolbar = !closeToolbar">
            <i class="el-icon-caret-right"></i>
          </span>
        </div>

        <div class="contentBox" style="padding:16px 24px">
          <div v-if="componentList.length === 0" class="tipBox">
            <p>拖拽左侧栏的组件进行</p>
          </div>
          <div v-else>
            <component v-if="isComponentLoaded" :is="dynamicComponent" source="components" @getData="getData"></component>
          </div>
        </div>
        <div :class="'rightForm'">
          <el-tabs v-model="activeName">
            <el-tab-pane label="页面model属性" name="formConfig">
               <json-viewer v-model="model" :collapsed="false"></json-viewer>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditorToolBar from "./EditorToolBar.vue";
import { deepFreeze } from "./common/utils";
import {configTools, loadComponents} from "@/utils/customComponents";
import { formatFormLabelWidth } from "./common/editorData";
deepFreeze(configTools);

export default {
  name: "CustomEditor",
  components: {
    EditorToolBar,
  },
  provide() {
    return {
      genFormProvide: { fallbackLabel: true },
    };
  },
  data() {
    return {
      closeToolbar: false,
      loading: false,
      configTools,
      isComponentLoaded: false,
      componentName: "",
      dynamicComponent: null,
      componentList: [],
      model: {},
      formConfig: {
        formProps: {
          labelWidth: 40,
        },
      },
      activeName: "formConfig",
    };
  },
  computed: {
    formProps() {
      if (!this.formConfig.formProps) return {};
      return {
        ...this.formConfig.formProps,
        labelWidth: formatFormLabelWidth(this.formConfig.formProps.labelWidth),
      };
    },
    formFooter() {
      return this.formConfig.formFooter || {};
    },
    dragOptions() {
      return {
        animation: 300,
        group: "listComponentsGroup",
        disabled: false,
        ghostClass: "ghostItem",
        filter: ".disabled",
        draggable: ".draggableItem",
        tag: "div",
        swapThreshold: 0.3,
      };
    },
  },
  watch: {
    componentList: {
      immediate: false,
      deep: true,
      handler(v, o) {
        this.$emit("change", v);
      },
    },
  },
  mounted() {
    window.document.body.classList.add("page-decorate-design");
  },
  destroyed() {
    window.document.body.classList.remove("page-decorate-design");
  },
  created() {},
  methods: {
    getData(model) {
      console.log(model, "组件的数据");
      this.model = model
    },
    getComponents(item) {
      this.componentList = [{ ...item }];
      this.loadComponent(item);
    },
    echoComponents (data) {
      const componentItem = this.configTools[0].componentList.find(item => item.componentPack === data.form_component)
      if (componentItem?.componentPack) {
        this.getComponents(componentItem)
      }
    },
    async loadComponent(componentItem) {
      const component = loadComponents[`${componentItem.componentPack}`]
      component().then((item) => {
        this.isComponentLoaded = true;
        this.componentName = componentItem.componentPack
        this.dynamicComponent = item.default;
      })
    },

    handleSaveSchema() {},
  },
};
</script>

<style lang="less">
.layoutColumn-2 {
  .dragArea .formItem {
    display: inline-block;
    width: 50%;
    flex-basis: 50%;
  }
}
.layoutColumn-3 {
  .dragArea .formItem {
    display: inline-block;
    width: 33.333%;
    flex-basis: 33.333%;
  }
}
body.page-decorate-design {
  overflow: hidden;
}
.flip-list-move {
  transition: transform 0.3s;
}
.no-move {
  transition: transform 0s;
}
.save-dailog,
.import-dailog,
.export-dailog,
.preview-dailog {
  .el-dialog__body {
    max-height: 70vh;
    overflow-y: auto;
  }
}
.save-dailog .el-dialog__body .genFromComponent .formFooter_item {
  border-top: none;
}
</style>
<style lang="less" scoped>
.custom-form-engine-container {
  height: 100%;
  min-width: 70vw;
  min-height: 50vh;
 /deep/ .jv-container {
  .jv-code {
      padding: 0px 10px 10px 0;
    }
  }
}
.container {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  transition: 0.2s ease;
}
.hasTools {
  padding-left: 260px;
  /deep/.el-icon-caret-right {
    transform: rotate(180deg);
  }
}
.toolBarWrap,
.rightForm {
  position: absolute;
  top: 0;
  bottom: 0;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(171 171 171, 0.3);
  z-index: 2;
}

.rightForm,
.toolsBar {
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.contentBox {
  position: relative;
  padding: 0;
  height: 100%;
  .genFromComponent {
    height: calc(100% - 46px);
  }
  .dragArea {
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: var(--background-color-base);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: var(--color-text-placeholder);
    }
  }
}
.toolBarWrap {
  padding-top: 10px;
  width: 260px;
  left: 0;
  overflow: visible;
}
.toolsBar {
  height: 100%;
}
.leftCaret {
  cursor: pointer;
  position: absolute;
  display: flex;
  width: 18px;
  height: 38px;
  align-items: center;
  justify-content: center;
  top: 2px;
  right: 0;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px 0 0 2px;
  /deep/.el-icon-caret-right {
    transition: all 0.3s ease;
    transform: rotate(180deg);
  }
  &:hover {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
    opacity: 1;
  }
}
.rightForm {
  box-sizing: border-box;
  padding: 10px;
  right: 0;
  width: 380px;
  .genFromComponent {
    border: none;
    background: #fff !important;
  }
}
.configForm {
  padding: 10px;
  & > h3 {
    font-size: 15px;
    font-weight: bold;
  }
}

/*content area*/
.contentWrap {
  position: relative;
  overflow: auto;
  height: 100%;
  padding-left: 260px;
  padding-right: 380px;
}
.closeToolbar {
  padding-left: 0;
  .toolBarWrap {
    left: -260px;
    .leftCaret {
      right: -18px;
    }
    /deep/.el-icon-caret-right {
      transform: rotate(0);
    }
  }
}
.tipBox {
  pointer-events: none;
  top: 20px;
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 30vh 0;
  p {
    margin: 20px 0;
    font-size: 14px;
  }
}
</style>
