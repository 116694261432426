/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-03-12 12:41:35
 * @LastEditTime: 2024-05-22 16:59:54
 * @FilePath: \awx-ui\src\api\httpApi\fileManage.js
 */
// const path = 'http://172.30.88.161:8867'
// const path = '/uploadFile'
import paths from '@/api/baseUrl' // 后台服务地址

let path = paths.path().filePath
export default {
  getProxyFileDir: path + '/filesystems',
  getProxyFile: path + '/filesystem/directories/root',
  downloadProxyFile: path + `/filesystem/files/root`, // 文件下载
  addFileByHost: path + '/filesystems_by_host',
  addFilesByHost: path + '/filesystems_by_hosts',
  downloadFile: path + `/filesystem/files/root`, // 文件下载
  directories: path + `/filesystem/directories/root`, // 文件上传
  getFsPath: path + `/filesystem/directories/root/`, // 文件目录
  getScriptList: path + '/filesystem/directories/root/scripts', // 获取快速脚本列表
  uploadScript: path + '/filesystem/directories/root', // 更新脚本内容
  installMonitor: path + '/monitor/install', //主机安装依赖
  getMonitroJobs: path + '/jobs/', //主机安装依赖
}