/*
 * @Author: mjzhu
 * @Date: 2022-05-24 10:22:10
 * @LastEditTime: 2024-05-31 14:17:39
 * @FilePath: \awx-ui\src\api\httpApi\host.js
 */
import paths from '@/api/baseUrl'// 后台服务地址

let path = paths.path().path
let sidePath = paths.path().path
export default {
  getHostGroups: sidePath + '/groups', // 查询分组列表
  getHostList: sidePath + '/hosts', // 查询所有主机
  saveHostByGRoup: sidePath + '/groups/', // 创建下分组下主机
  reStartDispatcherHostAgent: sidePath + '/host/install/reStartDispatcherHostAgent', // 主机agent分发重试
  dispatcherHostAgentList: sidePath + '/host/install/dispatcherHostAgentList', // 主机agent分发进度列表
  rehostCheck: sidePath+ '/host/install/rehostCheck', // 重试主机环境校验
  analysisHostList: sidePath + '/host/install/analysisHostList', // 解析主机列表
  hostCheckCompleted: sidePath + '/host/install/hostCheckCompleted', // 查询主机环境校验是否完成
  dispatcherHostAgentCompleted: sidePath + '/host/install/dispatcherHostAgentCompleted', // 查询主机agent分发是否完成
  getRack: path + '/api/cluster/host/getRack', // 查询机架
  updateRack: path + '/api/cluster/host/update', // 分配机架
  deleteRack: path + '/api/cluster/host/delete', // 分配机架
  getRoleListByHostname: path + '/api/cluster/host/getRoleListByHostname', // 根据主机查询角色列表
  hostsImport: sidePath + '/hosts_import',
  hostsImportTemplate: sidePath + '/hosts_import_template',
  getOrderList: path + '/quickcommands', // 获取快速命令列表
  getMarkList: path + '/targets', // 查询目标列表
  syncNow: path + '/quick-execute-cmd', // 立即同步
  getHostServiceList: path + '/dict/host_var', // 主机服务
  getMonitorByIds: (ids) =>  sidePath + '/hosts/' + ids + '/monitor',
  getCrondList: path + '/crond',
  getHostMertics: (id, group) =>  sidePath + `/hosts/${id}/metrics/${group}/`
}
