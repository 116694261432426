/*
 * @Author: mjzhu
 * @Date: 2022-05-24 10:22:10
 * @LastEditTime: 2022-09-02 10:18:31
 * @FilePath: \awx-ui\src\api\httpApi\user.js
 */
import paths from '@/api/baseUrl'// 后台服务地址

let path = paths.path().path

export default {
    getMasterInfo: path + '/masters',//查询master,
    creatMaster: path + '/install/master',//查询master,
    geMasterShell: path + '/install/master/scripts',//查询master,

}
