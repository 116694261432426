/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-05-24 10:22:10
 * @LastEditTime: 2023-07-10 10:05:47
 * @FilePath: \awx-ui\src\router\index.js
 */
import Vue from 'vue'
import Router from 'vue-router'
import {formatRoutes} from '@/utils/routerUtil'
let originPush =  Router.prototype.push;  //备份原push方法
import routerOptions from '@/router/config'
import dynamicComponents from '@/utils/dynamic-components'
 
Router.prototype.push = function (location, resolve, reject){
    if (resolve && reject) {    //如果传了回调函数，直接使用
        originPush.call(this, location, resolve, reject);
    }else {                     //如果没有传回调函数，手动添加
        originPush.call(this, location, ()=>{}, ()=>{}); 
    }
}
Vue.use(Router)

// 不需要登录拦截的路由配置
const loginIgnore = {
  names: ['404', '403'],      //根据路由名称匹配
  paths: ['/login'],   //根据路由fullPath匹配
  /**
   * 判断路由是否包含在该配置中
   * @param route vue-router 的 route 对象
   * @returns {boolean}
   */
  includes(route) {
    return this.names.includes(route.name) || this.paths.includes(route.path)
  }
}

/**
 * 初始化路由实例
 * @param isAsync 是否异步路由模式
 * @returns {VueRouter}
 */
function initRouter(isAsync) {
  const routerData = JSON.parse(localStorage.getItem('routerData'))
  let options = routerOptions
  if (routerData && !!routerData.length) {
    const routes = filterASyncRoutes(routerData)
    const index = options.routes.findIndex(
      (item) => item.path === "/"
    );
    options.routes[index].children = routes[0]?.children || [];
  }

  formatRoutes(options.routes)
  return new Router(options)
}
// 路由过滤   遍历路由 转换为组件对象和路径
function filterASyncRoutes(data) {
  const routes = data.filter((item) => {
    const getComp = dynamicComponents[`${item.component}`]
    item["component"] = getComp;
    // 路由递归，转换组件对象和路径
    if (item["children"] && item["children"].length > 0) {
      item["children"] = filterASyncRoutes(item.children);
    }
    return true;
  });
  // 排序
  // routes.sort((a, b) => a['id'] - b['id'])
  return routes;
}
export {loginIgnore, initRouter}
