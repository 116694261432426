/*
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-06-20 16:28:22
 * @LastEditTime: 2023-05-26 00:27:09
 * @FilePath: \awx-ui\src\api\httpApi\system.js
 */

import paths from '@/api/baseUrl'// 后台服务地址

let path = paths.path().path

export default {
  getServiceListByCluster: path + '/cluster/service/instance/list', // 选择服务的列表
  instanceList: path + '/cluster/service/role/instance/list', // 选择服务的列表
  getConfigInfo: path + '/cluster/service/instance/config/info', // 查询服务配置 
  getConfigVersion: path + '/cluster/service/instance/config/getConfigVersion', // 查询服务版本 
  getHostListByPage: path + '/api/cluster/host/list', // 分页查询集群主机  
  generateServiceCommand: path + '/api/cluster/service/command/generateServiceCommand', // 生成服务操作指令 
  generateServiceRoleCommand: path + '/api/cluster/service/command/generateServiceRoleCommand', // 生成服务角色操作指令 
  getAlertList: path + '/cluster/alert/history/getAlertList', // 查询服务告警列表 
  deleteExample: path + '/cluster/service/role/instance/delete', // 删除角色实例 
  getWebUis: path + '/cluster/webuis/getWebUis', // 查询webuis 
  // 告警模块
  getAlarmGroupList: path + '/alert/group/list', // 告警组列表  
  getAlarmMerticList: path + '/cluster/alert/quota/list', // 告警指标列表
  getAlarmCate: path + '/api/frame/service/list', // 查询服务列表 告警组类别
  getAlarmRole: path + '/api/frame/service/role/getServiceRoleByServiceName', // 查询服务列表 告警组类别
  saveGroup: path + '/alert/group/save', // 查询服务列表 告警组类别
  saveMetric: path + '/cluster/alert/quota/save', // 告警指标保存
  updateMetric: path + '/cluster/alert/quota/update', // 查询服务列表 告警组类别
  deleteGroup: path + '/alert/group/delete', // 告警组删除 
  deleteMetric: path + '/cluster/alert/quota/delete', // 告警指标删除
}
