/*
 * @Author: mjzhu
 * @Date: 2022-05-24 10:22:10
 * @LastEditTime: 2022-08-26 11:37:53
 * @FilePath: \awx-ui\src\api\httpApi\task.js
 */
import paths from '@/api/baseUrl'// 后台服务地址

let path = paths.path().path

export default {
  curdTasks: path+ '/tasks/', // curd操作任务
  getStepsTemplate: path+ '/templates/step-template/', // 获取部署步骤模板清单
  curdTaskTemplate: path + '/templates/task-template/',
  taskHistory: path + '/task_histories/',
  quickaccess: path + '/quickaccess',
  getSystemParams: path + '/params/system'
}
