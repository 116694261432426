<!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2024-04-23 11:35:34
 * @FilePath: \awx-ui\src\components\runTaskHistory\index.vue
-->
<template>
  <div class="account-setting">
    <div class="header">
      <span class="title">账号设置</span>
      <span class="risk-icon">
        <!-- <svg-icon style="font-size: 100px" :icon-class="hanlderIcon()"></svg-icon> -->
        <img :src="hanlderIcon()" alt="">
      </span>
    </div>
    <div class="content">
      <div class="content-item">
        <div class="content-left">
          <div> <svg-icon icon-class="account-user"></svg-icon></div>
          <div class="mgl12">用户名</div>
        </div>
        <div v-if="user.username" class="content-right">
          <div class="mgr42">{{ user.username }}</div>
          <!-- <div class="mgl12 btn-link" @click="updateUser('editNmae')">修改</div> -->
        </div>
        <!-- <div v-if="!userInfo.nick_name" class="content-right">
          尚未设置
          <div class="mgl12 btn-link" @click="updateUser('newName')">设置</div>
        </div> -->
      </div>
      <div class="content-item">
        <div class="content-left">
          <div> <svg-icon icon-class="account-password"></svg-icon></div>
          <div class="mgl12">账户密码</div>
        </div>
        <div class="content-right">
          <div v-if="!userInfo.no_password">*******</div>
          <div v-if="userInfo.no_password">尚未设置</div>
          <div v-if="!userInfo.no_password" class="mgl12 btn-link" @click="editPassword">修改</div>
          <div v-if="userInfo.no_password" class="mgl12 btn-link" @click="editPassword">设置</div>
        </div>
      </div>
      <div class="content-item">
        <div class="content-left">
          <div> <svg-icon icon-class="account-phone"></svg-icon></div>
          <div class="mgl12">手机</div>
        </div>
        <div v-if="userInfo.phone" class="content-right">
          {{ formatMobile(userInfo.phone) }}
          <div class="mgl12 btn-link" @click="updatePhone('editPhone')">修改</div>
        </div>
        <div v-if="!userInfo.phone" class="content-right">
          尚未绑定
          <div class="mgl12 btn-link" @click="updatePhone('newPhone')">绑定</div>
        </div>
      </div>
      <div class="content-item">
        <div class="content-left">
          <div> <svg-icon icon-class="account-mail"></svg-icon></div>
          <div class="mgl12">邮箱</div>
        </div>
        <div v-if="userInfo.email" class="content-right">
          <div class="ell" style="max-width: 220px" :title=userInfo.email>{{ userInfo.email }}</div>
          <div class="mgl12 btn-link" @click="updateUser('editEmail')">修改</div>
        </div>
        <div v-if="!userInfo.email" class="content-right">
          <div>尚未绑定</div>
          <div class="mgl12 btn-link" @click="updateUser('newEmail')">绑定</div>
        </div>
      </div>
      <div class="content-item">
        <div class="content-left">
          <div> <svg-icon icon-class="account-logoff"></svg-icon></div>
          <div class="mgl12">退出登录</div>
        </div>
        <div class="content-right">
          <div class="mgl12 btn-link" @click="logout">退出</div>
        </div>
      </div>
    </div>

  </div>
</template>
  <script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import moment from "moment";
import editPasswordVue from '@/components/accountSetting/editPassword.vue';
import updateUserVue from '@/components/accountSetting/editUser.vue' 
import editPhoneVue from '@/components/accountSetting/updatePhone.vue' 
import axios from "axios";
import LowRisk from '@/assets/img/user/low-risk.jpg'
import CenterRisk from '@/assets/img/user/centre-risk.jpg'


export default {

  data () {
    return {
      userInfo: {},
      LowRisk,
      CenterRisk,
    };
  },
  watch: {

  },
  computed: {
    user () {
      const user = localStorage.getItem(process.env.VUE_APP_USER_KEY)
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.userInfo = JSON.parse(user)
      return JSON.parse(user)
    }
  },

  methods: {
    ...mapActions("runHistory", ["getHistoryList", "changeShowHistory"]),
    ...mapMutations("account", ["setAccountSetting"]),
    ...mapMutations("account", ["setUser"]),
    formatMobile(mobile) {
      if (mobile && mobile.length === 11) {
        return mobile.slice(0, 3) + '****' + mobile.slice(7);
      }
      return mobile;
    },
    hanlderIcon () {
      let icon = this.LowRisk
      if (this.uer?.no_password || !this.user?.phone || !this.user?.email) return this.CenterRisk
      return icon
    },  
    hide () {
      this.setAccountSetting(false)
      this.changeShowHistory(false)
    },
    getUserInfo () {
      const self = this;
      this.$axiosGet(global.API.getUserInfo, {}).then((res) => {
        if ([200, 201, 202, 204].includes(res.status)) { 
          const username = res.data.username
          const isSuperuser = res.data.is_superuser
          this.userInfo = {...res.data, username, isSuperuser }
          self.setUser({...res.data, username, isSuperuser });
        }
      })
    },
    logout() {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const self = this;
      axios.post(global.API.loginOut, {}, config).then(function (res) {
        if ([200, 201, 204, 202].includes(res.status)) {
          self.loginOutFlag = true
          self.setUser({ username: "", isSuperuser: false });
          localStorage.clear()
          self.hide()
          self.$store.commit("setting/setCurrentTab", '1');
          self.$router.push("/login");
        }
      });
    },
    // get
    updateUser(type) {
      const self = this;
      let width = 520;
      let title = type.includes('new') ? '绑定' : '修改'
      let content = (
        <updateUserVue
          detail={this.userInfo}
          editType={type}
          callBack={() => self.getUserInfo()}
        />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        onCancel: () => {
        },
        icon: () => {
          return <div />;
        },
      });
    },
    updatePhone (type) {
      const self = this;
      let width = 520;
      let title = type.includes('new') ? '绑定' : '修改'
      let content = (
        <editPhoneVue
          detail={this.userInfo}
          editType={type}
          callBack={() => self.getUserInfo()}
        />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        onCancel: () => {
        },
        icon: () => {
          return <div />;
        },
      });
    },
    // 修改密码
    editPassword () {
      const self = this;
      let width = 520;
      let title = '修改密码';
      let content = (
        <editPasswordVue detail={this.userInfo}  callBack={() => self.getUserInfo()} />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        onCancel: () => {},
        icon: () => {
          return <div />;
        },
      });
    }
  },
};
</script>
<style lang="less" scoped>
.account-setting {
  z-index: 1050;
  position: fixed;
  top: 48px;
  right: 0px;
  width: 389px;
  height: 302px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  .header {
    border-bottom: 1px solid #e6e9eb;
    padding: 0 24px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 17px;
      color: #000000;
      letter-spacing: 0;
      font-weight: 600;
    }
    .risk {
      width: 90px;
      height: 30px;
      background: rgba(253, 137, 4, 0.06);
      border: 1px solid rgba(253, 217, 177, 1);
      border-radius: 19px;
      text-align: center;
      line-height: 30px;
      color: #fd8904;
      letter-spacing: 0;
      font-weight: 600;
      &-icon {
        width: 94px;
        img {
          width: 100%;
        }
      }
    }
  }
  .content {
    // padding: 0 24px; 
    .content-item {
      display: flex;
      padding: 0 24px; 
      height: 50px;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(230, 233, 235, 0.6);
      color: #1d202d;
      &:last-child {
        border-bottom: none;
      }
      .content-left {
        font-size: 14px;
        display: flex;
      }
      .content-right {
        display: flex;
        .btn-link {
          color: #126EF1;
          cursor: pointer;
        }
      }
      &:hover {
        background: #e6e8ed;
      }
    }
  }
}
</style>