/*
 * @Author: zhang·xiao
 * @Date: 2022-11-29 13:33:57
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2022-11-29 20:40:58
 * @Description: 描述文件功能
 */

import {
  getWidgetConfig, optionsList
} from '@formEngine/utils/formUtils';
import retrieveSchema from '@formEngine/utils/schema/retriev';
import vueProps from '../../props';

import Widget from '../../../components/Widget';

export default {
  name: 'ArrayFieldMultiSelect',
  functional: true,
  props: {
    ...vueProps
  },
  render(h, context) {
    const {
      schema, rootSchema, uiSchema, curNodePath, rootFormData, globalOptions
    } = context.props;

    // 这里需要索引当前节点，通过到schemaField组件的会统一处理
    const itemsSchema = retrieveSchema(schema.items, rootSchema);

    const enumOptions = optionsList(itemsSchema, uiSchema, curNodePath, rootFormData);

    const widgetConfig = getWidgetConfig({
      schema,
      uiSchema,
      curNodePath,
      rootFormData
    }, () => ({
      widget: globalOptions.WIDGET_MAP.common.checkboxGroup
    }));

    // 存在枚举数据列表 传入 enumOptions
    widgetConfig.uiProps.multiple = true;

    if (enumOptions && !widgetConfig.uiProps.enumOptions) {
      widgetConfig.uiProps.enumOptions = enumOptions;
    }

    return h(
      Widget,
      {
        ...context.data,
        props: {
          ...context.props,
          ...widgetConfig
        }
      }
    );
  }
};
