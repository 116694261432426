var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"contextmenu-submenu-fade"}},[(_vm.visible)?_c('div',{ref:"menu",class:[_vm.CLASS_MENU, 'menu', _vm.customClass],style:({ left: _vm.style.left + 'px', top: _vm.style.top + 'px', minWidth: _vm.style.minWidth + 'px', zIndex: _vm.style.zIndex }),on:{"contextmenu":(e) => e.preventDefault()}},[_c('div',{staticClass:"menu_body"},[_vm._l((_vm.items),function(item,index){return [(!item.hidden)?[(item.disabled)?_c('div',{key:index + '_disabled',class:[
            _vm.CLASS_MENU_ITEM, _vm.CLASS_MENU_ITEM_UNCLICKABLE,
            'menu_item', 'menu_item__disabled',
            item.divided ? 'menu_item__divided' : null
          ]},[(_vm.hasIcon)?_c('div',{staticClass:"menu_item_icon"},[(item.icon)?_c('svg-icon',{attrs:{"icon-class":item.icon}}):_vm._e()],1):_vm._e(),_c('span',{staticClass:"menu_item_label"},[_vm._v(_vm._s(item.label))]),_c('div',{staticClass:"menu_item_expand_icon"})]):(item.children)?_c('div',{key:index + '_children',class:[
            _vm.CLASS_MENU_ITEM, _vm.CLASS_MENU_ITEM_UNCLICKABLE,
            'menu_item', 'menu_item__available',
            _vm.activeSubmenu.index === index ? 'menu_item_expand' : null,
            item.divided ? 'menu_item__divided' : null
          ],on:{"mouseenter":($event) => _vm.enterItem($event, item, index)}},[(_vm.hasIcon)?_c('div',{staticClass:"menu_item_icon"},[(item.icon)?_c('svg-icon',{attrs:{"icon-class":item.icon}}):_vm._e()],1):_vm._e(),_c('span',{staticClass:"menu_item_label"},[_vm._v(_vm._s(item.label))]),_c('div',{staticClass:"menu_item_expand_icon"},[_vm._v("▶")])]):_c('div',{key:index,class:[
            _vm.CLASS_MENU_ITEM, _vm.CLASS_MENU_ITEM_CLICKABLE,
            'menu_item', 'menu_item__available',
            item.divided ? 'menu_item__divided' : null
          ],on:{"mouseenter":($event) => _vm.enterItem($event, item, index),"click":function($event){return _vm.itemClick(item)}}},[(_vm.hasIcon)?_c('div',{staticClass:"menu_item_icon"},[(item.icon)?_c('svg-icon',{attrs:{"icon-class":item.icon}}):_vm._e()],1):_vm._e(),_c('span',{staticClass:"menu_item_label"},[_vm._v(_vm._s(item.label))]),_c('div',{staticClass:"menu_item_expand_icon"})])]:_vm._e()]})],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }