<template>
  <a-layout :class="['admin-layout', 'beauty-scroll']">
    <admin-header :class="[{'fixed-tabs': fixedTabs, 'fixed-header': fixedHeader, 'multi-page': multiPage}]" :style="headerStyle" :menuData="headMenuData" :collapsed="collapsed" @toggleCollapse="toggleCollapse"/>
    <a-layout-header :class="['virtual-header', {'fixed-tabs' : fixedTabs, 'fixed-header': fixedHeader, 'multi-page': multiPage}]" v-show="fixedHeader"></a-layout-header>
    <a-layout class="admin-layout-main beauty-scroll">
      <!-- <drawer v-if="isMobile" v-model="drawerOpen">
          <side-menu :theme="theme.mode" :menuData="menuDataOptions" :collapsed="false" :collapsible="false" @menuSelect="onMenuSelect"/>
      </drawer>
      <side-menu :class="[fixedSideBar ? 'fixed-side' : '']" :theme="theme.mode" v-else-if="layout === 'side' || layout === 'mix'" :menuData="sideMenuData" :collapsed="collapsed" :collapsible="true" @toggleCollapse="toggleCollapse"/> -->
      <side-menu :class="[fixedSideBar ? 'fixed-side' : '']" :theme="theme.mode" :menuData="sideMenuData" :collapsed="collapsed" :collapsible="true" @toggleCollapse="toggleCollapse"/>
      <div v-if="fixedSideBar && !isMobile" :style="`width: ${sideMenuWidth}; min-width: ${sideMenuWidth};max-width: ${sideMenuWidth};`" class="virtual-side"></div>
      <drawer v-if="!hideSetting" v-model="showSetting" placement="right">
        <div class="setting" slot="handler">
          <a-icon :type="showSetting ? 'close' : 'setting'"/>
        </div>
        <setting />
      </drawer>
      <a-layout-content class="admin-layout-content" :style="`min-height: ${minHeight}px;`">
        <!-- <div class="current-route">
          <div v-for="(item, index) in (childMenu.filter(e => !e.meta.invisible))" :key="index" :class="['child-menu', $route.fullPath === item.fullPath ? 'child-menu-selected' : '']" @click="toPath(item)">
            <div :class="['route-tab', childMenu.length === 1 ? 'one-route-tab' : '', childMenu.length === 2 ? index === 0 ? 'tow-one-route-tab' : 'two-two-route-tab' : '']">
              {{ item.name }}
            </div>
          </div>
        </div> -->
        <div style="position: relative" @click="hideSet">
          <slot></slot>
        </div>
      </a-layout-content>
      <runTaskHistory v-if="runHistory.showHistory" />
      <accountSetting v-if="account.accountSetting" />
      <FileProgress v-if="fileManage.showFileProgress" />
    </a-layout>
  </a-layout>
</template>

<script>
import AdminHeader from './header/firstLevelHeader'
// import PageFooter from './footer/PageFooter'
import Drawer from '../components/tool/Drawer'
import SideMenu from '../components/menu/SideMenu'
import Setting from '../components/setting/Setting'
import {mapState, mapMutations, mapGetters, mapActions} from 'vuex'
import {getI18nKey} from '@/utils/routerUtil'
import menu from '../components/menu/menu'
import runTaskHistory from "@/components/runTaskHistory";
import FileProgress from "@/components/fileProgress";
import accountSetting from "@/layouts/header/accountSetting.vue";

// const minHeight = window.innerHeight - 64 - 122

export default {
  name: 'AdminLayout',
  components: {Setting, SideMenu, Drawer, AdminHeader, accountSetting, runTaskHistory, FileProgress},
  data () {
    return {
      minHeight: window.innerHeight - 64 - 122,
      collapsed: true,
      showSetting: false,
      drawerOpen: false,
      menuDataOptions: [],
      // childMenu: []
    }
  },
  provide() {
    return {
      adminLayout: this
    }
  },
  watch: {
    $route(val) {
      this.setActivated(val)
    },
    layout() {
      this.setActivated(this.$route)
    },
    isMobile(val) {
      if(!val) {
        this.drawerOpen = false
      }
    },
    // menuData
    menuData: {
      handler(val) {
        this.menuDataOptions = this.menuData
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapState('setting', ['isMobile', 'theme', 'layout', 'footerLinks', 'copyright', 'fixedHeader', 'fixedSideBar',
      'fixedTabs', 'hideSetting', 'multiPage']),
    ...mapGetters('setting', ['firstMenu', 'subMenu', 'menuData']),
    ...mapState({
      account: (state) => state.account, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
      drawer: (state) => state.drawer,
      fileManage: (state) => state.fileManage, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
      runHistory: (state) => state.runHistory, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
    sideMenuWidth() {
      return this.collapsed ? '64px' : '64px'
    },
    headerStyle() {
      let width = (this.fixedHeader && this.layout !== 'head' && !this.isMobile) ? `calc(100% - ${this.sideMenuWidth})` : '100%'
      let position = this.fixedHeader ? 'fixed' : 'static'
      return `width: ${width}; position: ${position};`
    },
    headMenuData() {
      const {layout, menuDataOptions, firstMenu} = this
      return layout === 'mix' ? firstMenu : menuDataOptions
    },
    sideMenuData() {
      const {layout, menuDataOptions, subMenu} = this
      return layout === 'mix' ? subMenu : menuDataOptions
    },
    breadcrumb() {
      let page = this.page
      let breadcrumb = page && page.breadcrumb
      if (breadcrumb) {
        let i18nBreadcrumb = []
        breadcrumb.forEach(item => {
          i18nBreadcrumb.push(this.$t(item))
        })
        return i18nBreadcrumb
      } else {
        return this.getRouteBreadcrumb()
      }
    },
    childMenu() {
      const currentMenu  = this.menuData.filter(item => this.$route.fullPath.includes(item.fullPath))
      return currentMenu[0].children &&  currentMenu[0].children.length ?  currentMenu[0].children : [...currentMenu]
    },
  },
  methods: {
    ...mapMutations("account", ["setAccountSetting"]),
    ...mapMutations('setting', ['correctPageMinHeight', 'setActivatedFirst']),
    ...mapActions('schedule', ['setScheduleDict']),
    ...mapActions("runHistory", ["getHistoryList", "changeShowHistory"]),
    toggleCollapse () {
      this.collapsed = !this.collapsed
    },
    onMenuSelect () {
      this.toggleCollapse()
    },
    hideSet () {
      if (this.account.accountSetting)  this.setAccountSetting(false)
      this.changeShowHistory(false)
    },
    toPath (menu) {
      this.$router.push({
        path: menu.fullPath,
      });
    },
    getRouteBreadcrumb() {
      const currentMenu  = this.menuData.filter(item => this.$route.fullPath.includes(item.fullPath))
      this.childMenu = currentMenu[0].children
      const currentObj = {
        menuName: currentMenu[0].name,
        icon: this.$route.meta.icon 
      }
      return currentObj
    },
    setActivated(route) {
      if (this.layout === 'mix') {
        let matched = route.matched
        matched = matched.slice(0, matched.length - 1)
        const {firstMenu} = this
        for (let menu of firstMenu) {
          if (matched.findIndex(item => item.path === menu.fullPath) !== -1) {
            this.setActivatedFirst(menu.fullPath)
            break
          }
        }
      }
    },
    // 获取调度图标字典
    getScheduleDict () {
      const params = {
        keyword: "",
        application_id: 'schedule'
      };
      this.$axiosGet(global.API.getStepsTemplate, params).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          const arr = res.data?.results || []
          this.setScheduleDict(arr)
        }
      });
    }
  },
  created() {
    this.correctPageMinHeight(this.minHeight - 24)
    this.setActivated(this.$route)
    this.getScheduleDict()
  },
  beforeDestroy() {
    this.correctPageMinHeight(-this.minHeight + 24)
  }
}
</script>

<style lang="less" scoped>
  .admin-layout{
    background: linear-gradient(to bottom right, #38496A, #25314D);
    .side-menu{
      &.fixed-side{
        position: fixed;
        height: calc(100vh - 48px);
        left: 0;
        top: 48px;
        background: rgb(35, 43, 58);
      }
    }
    .virtual-side{
      transition: all 0.2s;
    }
    .virtual-header{
      transition: all 0.2s;
      opacity: 0;
      &.fixed-tabs.multi-page:not(.fixed-header){
        height: 0;
      }
    }
    .admin-layout-main{
      background: transparent;
      .admin-header{
        top: 0;
        right: 0;
        overflow: hidden;
        transition: all 0.2s;
        &.fixed-tabs.multi-page:not(.fixed-header){
          height: 0;
        }
      }
    }
    .admin-layout-content{
      overflow-y: auto;
      max-height: calc(100vh - 48px);
      height: calc(100vh - 48px);
      // background: #f7f8fa;
      background: #fff;
      border-top-left-radius: 12px;
      // border-bottom-left-radius: 12px;
      margin: 0;
      padding: 0 0px 0 0;
    }
    .setting{
      background-color: @primary-color;
      color: @base-bg-color;
      border-radius: 5px 0 0 5px;
      line-height: 40px;
      font-size: 22px;
      width: 40px;
      height: 40px;
      box-shadow: -2px 0 8px @shadow-color;
    }
    // 面包屑
    .breadcrumb {
      margin-top: -16px;
      margin-bottom: 4px;
      /deep/ .ant-breadcrumb-link {
        font-size: 12px;
      }
    }
    .current-route {
      font-size: 16px;
      color: #1D202D;
      letter-spacing: 0;
      font-weight: 600;
      padding:9px 0 9px;
      display: flex;
      align-items: center;
      .child-menu {
        .route-tab {
          cursor: pointer;
          width: 100px;
          height: 30px;
          background: #FFFFFF;
          font-size: 14px;
          color: #4D5A77;
          letter-spacing: 0;
          font-weight: 400;
          line-height: 28px;
          text-align: center;
          &:hover {
            background: #639aff;
            color: #fff;
            // font-weight: 600;
            border: 1px solid #639aff;
          }
        }
        .one-route-tab {
          border-radius: 4px;
          border: 1px solid rgba(207,213,222,1);
        }
        .tow-one-route-tab {
          border-radius: 4px 0px 0px 4px;
          border: 1px solid rgba(207,213,222,1);
          border-right: none;
        }
        .two-two-route-tab {
          border-radius: 0px 4px 4px 0px;
          border: 1px solid rgba(207,213,222,1);
          border-left: none;
        }
        &-selected {
          .route-tab {
            background: #3974F4;
            color: #fff;
            // font-weight: 600;
            border: 1px solid #3974F4;
            &:hover {
              background: #3974F4;
              color: #fff;
              // font-weight: 600;
              border: 1px solid #3974F4;
            }
          }
        }
      }
    }
  }
</style>
