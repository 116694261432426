/*
 * @Author: zhang·xiao
 * @Date: 2022-11-29 13:37:16
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2022-12-21 21:13:41
 * @Description: 表单引擎渲染器入口
 */
import * as vueUtils from '@formEngine/utils/vueUtils';
import * as formUtils from '@formEngine/utils/formUtils';
import * as schemaValidate from '@formEngine/utils/schema/validate';
import WIDGET_MAP from '@formEngine/config/widgets/WIDGET_MAP.js';
import getDefaultFormState from '@formEngine/utils/schema/getDefaultFormState';
import createVue2Core, { fieldProps, SchemaField } from '@formEngine/formRender';
import './style.less';

const globalOptions = Object.freeze({
  WIDGET_MAP: Object.freeze(WIDGET_MAP),
  COMPONENT_MAP: Object.freeze({
    form: 'el-form',
    formItem: 'el-form-item',
    button: 'el-button',
    popover: 'el-popover'
  }),
  HELPERS: {
    // 是否mini显示 description
    isMiniDes(formProps) {
      return formProps && ['left', 'right'].includes(formProps.labelPosition) || formProps?.isMiniDes;
    }
  }
});

export default createVue2Core(globalOptions);

export {
  globalOptions,
  SchemaField,
  getDefaultFormState,
  fieldProps,
  vueUtils,
  formUtils,
  schemaValidate
};
