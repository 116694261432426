/*
 * @Author: zhang·xiao
 * @Date: 2022-11-29 13:37:16
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2022-12-21 21:18:31
 * @Description: 表单引擎渲染器入口
 */
// 组件依赖ElementUI 表单相关的所有组件
// 因此需提前加载ElementUI（此处可优化按需加载）
import Vue from 'vue';
import Element from './ElementUi/index';
import SchemaForm from './config/index'
import Editor from './formEdit/index';
import CustomEditor from './customFormEdit/index';
import './ElementUi/theme/index.css';
Vue.use(Element)
SchemaForm.install = (Vue) => {
  Vue.component(SchemaForm.name, SchemaForm)
}
Editor.install = (Vue) => {
  Vue.component(Editor.name, Editor)
}
CustomEditor.install = (Vue) => {
  Vue.component(CustomEditor.name, CustomEditor)
}
export default SchemaForm
// 导出表单引擎对外提供的工具类
export * from './config/index'
export { SchemaForm, Editor, CustomEditor };
