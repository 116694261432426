/*
 * @Author: zhang·xiao
 * @Date: 2022-11-29 13:33:57
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2022-11-30 01:05:24
 * @Description: 描述文件功能
 */
import vueProps from '../props';
import StringField from '../StringField';

export default {
  name: 'NumberField',
  props: vueProps,
  functional: true,
  render(h, context) {
    return h(StringField, context.data);
  }
};
