<!--
 * @Author: mjzhu
 * @Date: 2022-06-08 11:38:30
 * @LastEditTime: 2024-01-19 14:57:02
 * @FilePath: \awx-ui\src\pages\operationConsole\components\bottomOpe\addCmd.vue
-->
<template>
  <a-spin :spinning="loading">
    <div class="quota-apply">
      <div class="tab">
        <div v-for="(item, index) in quotaTab" :key="item.id"  :class="[catagoryId === item.catagoryId && 'tab-selected']" @click="selectCatatory(item)">{{ item.name }}</div>
      </div>
      <div class="flex-warp">
        <div
          v-for="(item,index) in dataSource"
          :key="index"
          class="quoto-item"
        >
          <div
            class="quoto-header"
            :style="{background: `url(${contactCard}) no-repeat`}"
          >
            <div class="title">
              {{ item.name }}
            </div>
            <div class="description">{{ item.description }}</div>
          </div>
          <div class="quoto-body">
            <div class="body-warp">
              <div class="left">时长（月）</div>
              <div class="right">{{item.month}}</div>
            </div>
            <div class="body-warp">
              <div class="left">主机配额（个）</div>
              <div class="right">{{item.max_hosts}}</div>
            </div>
            <div class="body-warp">
              <div class="left">任务配额（个）</div>
              <div class="right">{{item.max_tasks}}</div>
            </div>
            <div class="body-warp">
              <div class="left">调度配额（个）</div>
              <div class="right">{{item.max_schedules}}</div>
            </div>
          </div>
          <div class="quoto-footer">
            <a-button
              class="btn-apply"
              type="primary"
              @click="openApplyModal(item)"
            >立即申请</a-button>
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>
  <script>
import moment from "moment";
export default {
  components: {
  },
  props: {
    detail: {
      type: Object,
      default: function () {
        return {};
      },
    },
    currentTab: String,
    callBack: Function,
  },
  data () {
    return {
      contactCard: require("@/assets/img/bg-quoto.png"),
      dataSource: [],
      catagory: '',
      quotaTab: [],
      catagoryId : '',
      loading: false
    };
  },
  watch: {},
  methods: {
    selectCatatory (item) {
      if (this.catagory === item.catagory) return false
      this.catagory = item.catagory
      this.catagoryId = item.catagoryId
      this.getList( )
    },
    formCancel () {
      this.$destroyAll();
    },
    openApplyModal (item) {
      // getQuotaCatagories
      this.$emit('toOrder', item)
    },
    getList () {
      this.loading = true;
      const params = {
        page_size: 100,
        page: 1,
        catagory: this.catagory
      };
      this.$axiosGet(global.API.getManagementList, params).then((res) => {
        this.loading = false;
        if ([200, 201, 204].includes(res.status)) {
          this.dataSource = res.data.results;
        }
      });
    },
    getQuotaCatagories () {
      this.$axiosGet(global.API.getQuotaCatagories, {}).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
         this.quotaTab =  res.data.results.map(item => {
          return {
            name: item.name,
            catagory: item.catagory,
            catagoryId: item.id
          }
         })
         this.catagory = this.quotaTab[0].catagory
         this.catagoryId = this.quotaTab[0].catagoryId
         this.getList()
        }
      });
    }
  },
  mounted () {
    this.getQuotaCatagories()
  },
};
</script>
    <style lang="less" scoped>
.quota-apply {
  padding: 16px 0 36px 30px;
  height: 510px;
  .tab {
    height: 32px;
    margin: 0 auto 16px;
    width: 350px;
    background: #EBF0F7;
    border: 1px solid rgba(201,207,222,1);
    border-radius: 7px;
    display: flex;
    padding: 0 4px;
    align-items: center;
    justify-content: space-around;
    div {
      width: 112px;
      height: 26px;
      text-align: center;
      line-height: 26px;
      box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.06);
      border-radius: 6px;
      margin-right: 4px;
      cursor: pointer;
      &:hover {
        background: #FFFFFF;
      }
    }
    &-selected {
      background: #FFFFFF;
    }
   
  }
  .flex-warp {
    flex: 1;
    overflow-y: auto;
    max-height: calc(100% - 30px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .quoto-item {
      height: 410px;
      width: 31%;
      background-image: linear-gradient(0deg, #ffffff 70%, #f3f6f7 92%);
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 24px;
      border-width: 1px;
      margin-right: 2%;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .quoto-header {
        height: 104px;
        width: 100%;
        background-size: 100% 100% !important;
        padding: 24px 20px 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .title {
          font-size: 19px;
          color: #1d202d;
          letter-spacing: 0;
          font-weight: 600;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //用省略号显示
          white-space: nowrap; //不换行
        }
        .description {
          font-size: 14px;
          color: #798ca6;
          letter-spacing: 0;
          font-weight: 400;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //用省略号显示
          white-space: nowrap; //不换行
        }
      }
      .quoto-body {
        flex: 1;
        padding: 12px 20px 0;
        .body-warp {
          display: flex;
          align-items: center;
          border-bottom: 1px solid rgba(223, 225, 227, 1);
          padding: 10px 0 10px 0;
          // justify-content: space-between;
          .left {
            font-size: 14px;
            color: #374567;
            letter-spacing: 0;
            font-weight: 400;
            width: 80%;
          }
          .right {
            font-size: 16px;
            color: #374567;
            letter-spacing: 0;
            font-weight: 500;
          }
        }
      }
      .quoto-footer {
        height: 80px;
        border-top: 1px solid #dfe1e3;
        text-align: center;
        line-height: 80px;
        .btn-apply {
          width: 200px;
          height: 32px;
          background: #3974f4;
          border-radius: 3px;
          font-size: 14px;
          color: #ffffff;
          letter-spacing: 0;
          font-weight: 500;
        }
        .btn-flex {
          display: flex;
          justify-content: space-around;
          .btn-edit {
            width: 120px;
          }
          .btn-del {
            width: 120px;
          }
        }
      }
      &:hover {
        background-image: linear-gradient(0deg, #ffffff 70%, #f3f6f7 92%);
        border: 1px solid rgba(57, 116, 244, 0.8);
        box-shadow: 0px 2px 10px 0px rgba(57, 116, 244, 0.37);
        border-radius: 4px;
      }
    }
  }
}
</style>
    