<!--
 * @Author: mjzhu
 * @Date: 2022-05-24 10:28:22
 * @LastEditTime: 2023-09-28 15:58:28
 * @FilePath: \awx-ui\src\layouts\header\levelHeader.vue
-->
<template>
    <div class="trigger-menu-components">
      <div class="trigger-menu" v-for="item in flattenMenuData" :key="item.fullPath">
        <div :class="['menu-name', item.menuParent || $route.path.includes(item.path)? 'child-menu' : '']" @click="onSelect(item)">
          <span>{{ item.name }}</span>
          <div v-if="$route.fullPath.includes(item.fullPath)" class="selcted-line"></div>
        </div>
      </div>
    </div>
  </template>
      
  <script>
  import { mapState, mapMutations, mapGetters } from "vuex";
  import { loadRoutes } from "@/utils/routerUtil";
import { formatRoutes } from "@/utils/routerUtil";
import dynamicComponents from "@/utils/dynamic-components";
  export default {
    name: "triggerheader",
    props: ["menuData"],
    data() {
      return {
        flattenMenuData: [],
        langList: [
          { key: "CN", name: "简体中文", alias: "简体" },
          { key: "US", name: "English", alias: "English" },
        ],
        searchActive: false,
        selectedKeys: [],
      };
    },
    watch: {
      '$route': {
        handler(val) {
          this.selectedKeys = [val.fullPath]
        },
        immediate: true
      }
    },
    computed: {},
    methods: {
      initMenuData() {
        this.flattenMenuData  = this.menuData
      },
      flatten(tree = [], nodes = [], flag = true) {
        for (let item of tree) {
          nodes.push({ ...item, flag: flag });
          if (item.children) {
            item.children.forEach((child, index) => {
              child["menuParent"] = item.fullPath;
              child['childIndex'] = `${item.fullPath}${index}`;
            });
            this.flatten(item.children, nodes, false);
          }
        }
        return nodes;
      },
      onSelect(obj) {
        if (!this.selectedKeys[0].includes('/dashboard') && obj.fullPath === '/dashboard') {
          this.getRouter()
        }
        this.selectedKeys = [obj.fullPath];
        this.$router.push({
          path: obj.fullPath,
        });
      },
      getRouter() {
      const self = this;
      this.$axiosGet(global.API.getMemus + "/dashboard", {}).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          const menuData = JSON.parse(localStorage.getItem('routerData'))
          menuData[0].children.forEach(item => {
            if (item.path === 'dashboard') {
              item.children = res.data
            }
          })
          // menuData[0].children[0] = {
          //   "id": "4f2f3a9e-3823-4f29-a80d-3d394026a937",
          //   "description": "",
          //   "create_time": "2023-07-06T14:15:59.476+08:00",
          //   "update_time": "2023-07-06T14:15:59.476+08:00",
          //   "owner": "",
          //   "path": "dashboard",
          //   "name": "仪表盘",
          //   "redirect": "/dashboard/index",
          //   "component": "@/layouts/PageView",
          //   "parent_id": "c2c7d922-24b5-419e-8e9c-b793ac6f1aae",
          //   "children": [{
          //     "id": "d7f50049-561c-4bc5-a738-86ac54dbd8f6",
          //     "description": "",
          //     "create_time": "2023-07-06T14:15:59.476+08:00",
          //     "update_time": "2023-07-06T14:15:59.476+08:00",
          //     "owner": "limix",
          //     "path": "/dashboard/index",
          //     "name": "仪表盘",
          //     "redirect": "",
          //     "component": "@/pages/overview/index",
          //     "parent_id": "4f2f3a9e-3823-4f29-a80d-3d394026a937",
          //     "children": [],
          //     "menu_order": "008",
          //     "meta": {
          //       "notAlive": true,
          //       "icon": "third"
          //     }
          //   },{
          //     "id": "d7f50049-561c-4bc5-a738-86ac54dbd8f6",
          //     "description": "",
          //     "create_time": "2023-07-06T14:15:59.476+08:00",
          //     "update_time": "2023-07-06T14:15:59.476+08:00",
          //     "owner": "limix",
          //     "path": "/dashboard/demo1",
          //     "name": "主机监控",
          //     "redirect": "",
          //     "component": "@/layouts/ThirdPartyPage/index",
          //     "parent_id": "4f2f3a9e-3823-4f29-a80d-3d394026a937",
          //     "children": [],
          //     "menu_order": "008",
          //     "meta": {
          //       "externalUrl": "http://47.92.78.18:3000/d/rYdddlPWk/node-exporter?orgId=1\u0026from=1695545833198\u0026to=1695632233198",
          //       "notAlive": true,
          //       "icon": "third"
          //     }
          //   }, {
          //     "id": "d7f50049-561c-4bc5-a738-86ac54dbd8f7",
          //     "description": "",
          //     "create_time": "2023-07-06T14:15:59.476+08:00",
          //     "update_time": "2023-07-06T14:15:59.476+08:00",
          //     "owner": "limix",
          //     "path": "/dashboard/demo2",
          //     "name": "动态监控",
          //     "redirect": "",
          //     "component": "@/layouts/ThirdPartyPage/index",
          //     "parent_id": "4f2f3a9e-3823-4f29-a80d-3d394026a937",
          //     "children": [],
          //     "menu_order": "008",
          //     "meta": {
          //       "externalUrl": "http://47.92.78.18:3000/d/rYdddlPWk/node-exporter?orgId=1\u0026from=1695545833198\u0026to=1695632233198",
          //       "notAlive": true,
          //       "icon": "third"
          //     }
          //   }],
          //   "menu_order": "001",
          //   "meta": {
          //     "notAlive": true,
          //     "icon": "menu-dashboard"
          //   }
          // }
          localStorage.setItem("routerData", JSON.stringify(menuData));
          const routes = self.filterASyncRoutes(menuData);
          const index = self.$router.options.routes.findIndex(
            (item) => item.path === "/"
          );
          self.$router.options.routes[index].children =
            routes[0]?.children || [];
          formatRoutes(self.$router.options.routes);
          self.$router.addRoutes(self.$router.options.routes);
          loadRoutes();
        }
      });
    },
    // 路由过滤  遍历路由 转换为组件对象和路径
    filterASyncRoutes(data) {
      const routes = data.filter((item) => {
        const getComp = dynamicComponents[`${item.component}`];
        item["component"] = getComp;
        // 路由递归，转换组件对象和路径
        if (item["children"] && item["children"].length > 0) {
          item["children"] = this.filterASyncRoutes(item.children);
        }
        return true;
      });
      // 排序
      // routes.sort((a, b) => a['id'] - b['id'])
      return routes;
    },
    },
    mounted() {
      this.initMenuData();
    },
  };
  </script>
      
  <style lang="less" scoped>
  @import "index";
  .trigger-menu-components {
    display: flex;
    justify-content: center;
    align-items: center;
    .trigger-menu {
      width: 88px;
      .menu-name {
        position: relative;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #ffffff;
        opacity: 0.7;
        span  {
          padding: 0 16px;
        }
        &:hover{
          background-color: transparent ;
          -webkit-background-clip: text;
          opacity: 1;
          span {
            box-shadow: 0px 2px 8px 0px rgba(255, 255, 255, 0.04);
            font-weight: 600;
            // background: linear-gradient(#FFFFFF, #9bc3ff 90%);
            -webkit-background-clip: text;
            color: #fff;
          }
        }
      }
      .child-menu {
        background-color: transparent ;
        border-radius: 4px;
        opacity: 1;
        font-weight:500;
        span {
          padding: 0 9px;
          font-weight: 600;
          box-shadow: 0px 2px 8px 0px rgba(255, 255, 255, 0.04);
          color: #ffffff;
        }
      }
      .selcted-line {
        position: absolute;
        width: 60%;
        height: 3px;
        bottom: 0px;
        background: #447EFF;
      }
    }
  }
  </style>
      