<!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2023-12-22 14:32:06
 * @FilePath: \awx-ui\src\components\createTask\components\commonForm.vue
-->
<template>
  <a-spin :spinning="spinning">
    <div class="common-template steps">
      <div
        id="create-task"
        style="max-height: 580px; overflow-y: auto;padding-right: 44px"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item
            ref="name"
            prop="name"
          >
          <span slot="label">
            <span class="required">*</span>
            <span class="title">授权名称</span>
          </span>
            <a-input
              v-model="form.name"
              placeholder="请输入"
            />
          </a-form-model-item>
          <a-form-model-item
            ref="description"
            prop="description"
          >
          <span slot="label">
            <span class="required"></span>
            <span class="title">描述</span>
          </span>
            <a-input
              type="textarea"
              v-model="form.description"
              placeholder="请输入"
            />
          </a-form-model-item>
        </a-form-model>
        <FormDesign
          v-if="selectedTemplateObj.form_type !== '1'"
          :selectedTemplateObj="selectedTemplateObj.schema"
          :model="model"
          @getFormData="getFormData"
        />
        <div v-else>
          <component
            v-if="isComponentLoaded"
            :is="dynamicComponent"
            source="task"
          ></component>
        </div>
      </div>
    </div>
  </a-spin>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import FormDesign from "./formRender";
import { configTools, loadComponents } from "@/utils/customComponents";

export default {
  name: "CommonTemplate",
  props: { authModel: Object, authSelectedTemplateObj: Object, authDetail: Object, isQuick: { type: Boolean, default: false } },
  components: { FormDesign },
  data () {
    const self = this;
    return {
      spinning: false,
      changeFlag: false,
      testData: [],
      hostGroupList: [],
      hostList: [],
      controllerList: [],
      initFormFiledFlag: false,
      isComponentLoaded: false,
      dynamicComponent: null,
      rules: {
        name: [
          { required: true, message: '授权名称为必填字段', trigger: 'blur' },
        ],
      },
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
      },
      model: {},
      selectedTemplateObj: { name: "", form_type: '0' },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 19, offset: 5 },
        },
      },
      form: {
        name: '',
        description: ''
      }
    };
  },
  watch: {
    authModel: {
      handler (val) {
        this.model = val
        this.selectedTemplateObj = this.authSelectedTemplateObj
        if (this.selectedTemplateObj.form_type === '0') {
          this.initFormFiled()
        } else {
          this.echoComponents()
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
  },
  methods: {
    ...mapActions("task", ["setTaskInfo"]),
    // 回显自定义组件
    getComponents (item) {
      this.componentList = [{ ...item }];
      this.loadComponent(item);
    },
    echoComponents () {
      const data = this.selectedTemplateObj
      const componentItem = configTools[0].componentList.find(item => item.componentPack === data.form_component)
      if (componentItem?.componentPack) {
        this.getComponents(componentItem)
      }
    },
    async loadComponent (componentItem) {
      const component = loadComponents[`${componentItem.componentPack}`]
      component().then((item) => {
        this.isComponentLoaded = true;
        this.componentName = componentItem.componentPack
        this.dynamicComponent = item.default;
      })
    },
    initFormFiled () {
      this.initFormFiledFlag = true
      this.formData = {...this.authModel}
    },
    getFormData (formData) {
      this.$emit('getFormData', formData)
    },
    syncFormdata (fileds) {
    },
    // 获取主机组
    getGroupHost () {
      const params = {
        page_size: 10000,
        page: 1,
        keyword: "",
      };
      this.$axiosGet(global.API.getHostGroups, params).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.hostGroupList = res.data.results;
        }
      });
    },
    // 获取主机
    getHosts () {
      const params = {
        page_size: 1000,
        page: 1,
        keyword: "",
      };
      this.$axiosGet(global.API.getHostList, params).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.hostList = res.data.results;
        }
      });
    },
  },
  created () {
    // this.getGroupHost();
    // this.getHosts();
    if (this.authDetail && this.authDetail.id) {
      this.form = {
        name: this.authDetail.name,
        description: this.authDetail.description
      }
    }
  },
  beforeDestroy () { },
};
</script>
<style lang="less" scoped>
.common-template {
  margin-top: 16px;
  /deep/ .el-select__tags {
    max-width: 483px !important;
  }
  /deep/ .el-form-item__content {
    padding: 0;
  }
  /deep/ .formItemErrorBox {
    display: none;
  }
  /deep/ .ant-form-item-label > label {
    font-size: 14px;
    color: #606266;
    .required {
      color: #f5222d;
      font-size: 17px;
      display: inline-block;
      width: 10px;
    }
    .title {

    }
    &::after, &::before {
      content: "";
      margin-right: 0;
    }
  }
  // 头部
  .drag-template-item {
    margin-top: 16px;
    margin-bottom: 30px;
    display: flex;
    position: relative;
    align-items: center;
    z-index: 1;
    border: 1px solid transparent;
    padding: 0 0 0 14px;
    img {
      height: 42px;
      margin: 0px 20px 0 0;
      width: 42px;
    }
    .action-content {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
    }
    .action-text-wrap {
      width: 100%;
      height: 24px;
      line-height: 24px;
      margin-bottom: 4px;
      white-space: nowrap;
      .action-label {
        display: inline-block;
        font-weight: 700;
        max-width: 65%;
        font-size: 16px;
        color: #1d202d;
        letter-spacing: 0;
        font-weight: 600;
        margin-right: 10px;
        min-width: 10px;
      }
    }
    .action-desc {
      font-size: 14px;
      color: #6f737d;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
  .mgh160 {
    // margin: 0 110px;
  }
  .form-content {
    max-height: 506px;
    overflow-y: auto;
    // padding: 0 22px 0 48px;
    .reduce-icon {
      cursor: pointer;
      font-size: 18px;
      color: @primary-color;
      margin-left: 6px;
    }
    .form-multiple-item:last-child {
      margin-bottom: 12px;
    }
    .form-item-container {
      position: relative;
      // margin-bottom: 6px;
      .filed-name-tips {
        display: flex;
        align-items: center;
        position: absolute;
        font-size: 12px;
        top: 32px;
        left: 12px;
        width: 20%;
        &-word {
          display: inline-block;
          max-width: calc(100% - 0px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .filed-name-tips-icon {
        cursor: pointer;
      }
      .slider-icon {
        position: relative;
        top: -28px;
      }
    }
  }
  .qiick-content {
    max-height: 660px;
  }
  .ant-collapse-borderless {
    background: #fff;
    .ant-collapse-item {
      border-bottom: none;
    }
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0px;
  }
}
</style>
<style lang="less">
#create-task {
  .genFromComponent {
    border: none;
    padding: 0px;
    .el-form-item__label {
      width: 20.8% !important;
    }
    .el-form-item__content {
      margin-left: 20.8% !important;
    }
  }
}
</style>