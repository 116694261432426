/*
 * @Author: zhang·xiao
 * @Date: 2022-11-29 13:33:57
 * @LastEditors: zhang·xiao
 * @LastEditTime: 2022-11-29 14:14:22
 * @Description: 描述文件功能
 */

import { getWidgetConfig, isSelect, optionsList } from '@formEngine/utils/formUtils';
import vueProps from '../props';
import Widget from '../../components/Widget';

export default {
  name: 'StringField',
  props: vueProps,
  functional: true,
  render(h, context) {
    const {
      schema, uiSchema, curNodePath, rootFormData, globalOptions: { WIDGET_MAP }
    } = context.props;

    // 可能是枚举数据使用select组件，否则使用 input
    const enumOptions = isSelect(schema) && optionsList(schema, uiSchema, curNodePath, rootFormData);

    const widgetConfig = getWidgetConfig({
      schema,
      uiSchema,
      curNodePath,
      rootFormData
    }, () => {
      const isNumber = schema.type === 'number' || schema.type === 'integer';

      return {
        widget: enumOptions
          ? WIDGET_MAP.common.select
          : WIDGET_MAP.formats[schema.format]
                    || (isNumber ? WIDGET_MAP.types.number : WIDGET_MAP.types.string)
      };
    });

    // 存在枚举数据列表 传入 enumOptions
    if (enumOptions && !widgetConfig.uiProps.enumOptions) {
      widgetConfig.uiProps.enumOptions = enumOptions;
    }

    return h(
      Widget,
      {
        ...context.data,
        props: {
          ...context.props,
          ...widgetConfig
        }
      }
    );
  }
};
